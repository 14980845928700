import Bugsnag from '@bugsnag/js';

// Knit's Bugsnag: https://app.bugsnag.com/stitch-fix-1/knit/overview
export const KNIT_BUGSNAG_API_KEY = 'a4cd435c01289bb3ecb858dbc7f468d9';

// Because Bugsnag is a global package and each applications initiate Bugsnag with its own apiKey;
// if we want to send knit errors to the knit Bugsnag project, we need to set/overide the API key here.
// We also want to send Knits package version.
export const sendBugsnagEventToKnit = ({
  error,
  metadata = {},
}: {
  error: Error;
  sendToKnit?: boolean;
  metadata?: Record<string, unknown>;
}) => {
  Bugsnag.notify(error, event => {
    /* eslint-disable no-param-reassign */
    event.apiKey = KNIT_BUGSNAG_API_KEY;
    /* eslint-enable no-param-reassign */

    if (metadata) {
      event.addMetadata('metadata', metadata);
    }
  });
};

import { Event, reportEvent } from '@stitch-fix/event-reporter/legacy';
import { getWindow } from '@stitch-fix/mode-react';

const validCanonicalPaths = [
  {
    canonicalName: 'shop_index',
    path: /^\/shop$/,
  },
  {
    canonicalName: 'pdp',
    path: /^\/my-items\/shop\/items\/(\d+)$/,
  },
  {
    canonicalName: 'search_results',
    path: /^\/search$/,
  },
];

/**
 * Returns a valid canonical name used in search events.
 *
 * Since the `activePageCanonical` value passed might be null,
 * this matches the current window location to a valid canonical name string.
 *
 * See `getActionableLinks.js#getActivePageCanonical` for list of existing canonical names.
 */
export const getSearchEventCanonical = (
  activePageCanonical?: string | null,
) => {
  if (activePageCanonical) return activePageCanonical;

  const pathname = getWindow()?.location?.pathname ?? '';
  // Trim trailing slash
  const trimmedPath = pathname.replace(/\/$/, '');
  const matchedPath = validCanonicalPaths.find(({ path }) =>
    trimmedPath.match(path),
  );

  return matchedPath?.canonicalName ?? 'other';
};

type BaseEvent = Pick<
  Event,
  'action_name' | 'action_type' | 'canonical_name' | 'event_name'
>;

export interface SearchIconClick extends BaseEvent {
  action_name: 'search_icon_selected';
  action_type: 'click';
  canonical_name: string;
  event_name: 'search_results';
}

export interface SearchSubmitClick extends BaseEvent {
  action_name: 'search_submit';
  action_type: 'click';
  canonical_name: string;
  event_name: 'search_results';
}

const eventDefaults = {
  source_app: 'knit',
};

export const trackSearchIconClick = (activePageCanonical?: string | null) => {
  const canonicalName = getSearchEventCanonical(activePageCanonical);
  const event: SearchIconClick = {
    action_name: 'search_icon_selected',
    action_type: 'click',
    canonical_name: canonicalName,
    event_name: 'search_results',
  };

  reportEvent({
    event: { ...event, ...eventDefaults },
  });
};

export const trackSearchSubmit = (activePageCanonical?: string | null) => {
  const canonicalName = getSearchEventCanonical(activePageCanonical);
  const event: SearchSubmitClick = {
    action_name: 'search_submit',
    action_type: 'click',
    canonical_name: canonicalName,
    event_name: 'search_results',
  };

  reportEvent({
    event: { ...event, ...eventDefaults },
  });
};

import React from 'react';
import { ModeLink } from '../../KnitLink';
import styles from './style.module.scss';

/*
 * California Consumer Privacy Act
 * - https://oag.ca.gov/privacy/ccpa
 * - https://en.wikipedia.org/wiki/California_Consumer_Privacy_Act
 */
export const CcpaLinks = () => (
  <span className="For-CA-Users" data-testid="ccpa-links">
    {' - '}
    <ModeLink to="/privacy#ca-notice">CA Notice at Collection</ModeLink>
    {' - '}
    <button
      type="button"
      className={`${styles['privacy-choices']} ot-sdk-show-settings`}
    >
      Your Privacy Choices
    </button>
  </span>
);

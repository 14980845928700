import React, { useCallback, ReactNode } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
  BodyScrollOptions,
} from 'body-scroll-lock';
import Text from '../Text';
import Box from '../Box';
import { getDocument } from '../_internal/getBrowserGlobals';
import { ResponsiveValue, getResponsiveValue } from '../_internal/styling';
import useScreenSize from '../_internal/useScreenSize';
import InteractableContainer from '../InteractableContainer';

import styles from './modal.module.scss';
import { eventHandlers as stopAllPropagationHandlers } from './StopPropagation';

type BodyLock = 'always' | 'allow-touch' | 'never';
export type ModalSize = 'small' | 'medium' | 'large';
export type ModalBackgroundColor = 'white' | 'mint' | 'navy' | 'citrus';

const useBodyLock = (bodyLock: BodyLock) => {
  const bodyRef = useCallback(
    (node: Element | null) => {
      if (node && bodyLock !== 'never' && getDocument()) {
        // Checks if an element or its ancestors have a data attribute that indicates
        // it should ignore the body scroll lock.
        // https://github.com/willmcpo/body-scroll-lock#allowtouchmove
        const allowTouchMoveWithDataAttr: BodyScrollOptions['allowTouchMove'] =
          el => {
            let currentEl: typeof el | null = el;

            while (
              currentEl &&
              currentEl !== node &&
              currentEl !== getDocument()?.body
            ) {
              if (currentEl.getAttribute('data-allow-touch-scroll') !== null) {
                return true;
              }

              currentEl = currentEl.parentElement;
            }

            return false;
          };

        // Require an explicit prop to enable allowing touch events because
        // it adds extra processing on each touch that is not needed in most
        // modal use cases.
        const disableBodyScrollOpts =
          bodyLock === 'allow-touch'
            ? { allowTouchMove: allowTouchMoveWithDataAttr }
            : {};

        disableBodyScroll(node, disableBodyScrollOpts);
      } else {
        clearAllBodyScrollLocks();
      }
    },
    [bodyLock],
  );

  return bodyRef;
};

export type ModalProps = {
  /**
   * The content of the modal
   */
  children?: ReactNode;
  /**
   * The variation/stye of the modal.
   * @default 'default'
   */
  variant?: 'default' | 'inset-small' | 'slide-up-small';
  /**
   * The size of the modal.
   * @default 'medium'
   */
  size?: ModalSize;
  /**
   * Whether the modal is shown open or hidden.
   */
  isOpen: boolean;
  /**
   * Callback function for when the close button is clicked.
   * The close button is omitted when this handler is not specified,
   * and neither the Escape key nor clicking outside the modal will
   * close it.
   * This callback should update the parent's state to cause 'isOpen'
   * to be set to false.
   */
  onClose?: () => void;
  /**
   * The horizontal alignment of the modal header & content
   *
   * @default 'left'
   */
  hAlign?: ResponsiveValue<'left' | 'center'>;
  /**
   * The modal heading
   */
  heading?: ReactNode;
  /**
   * The full-width hero section at the top of the modal.
   * This will most likely be a [hero image](https://en.wikipedia.org/wiki/Hero_image)
   * but can be any content at the top without padding.
   */
  hero?: ReactNode;
  /**
   * The modal footer. The `ModalFooter` can be
   * imported and used here for consistent CTAs.
   */
  footer?: ReactNode;

  /**
   * Determines if the body will be locked to prevent scrolling while the modal
   * is open.
   *
   * - `always`: The body is always locked and does not scroll when the modal is
   *   open. This also prevents scrolling on other elements with the exception
   *   of the modal body. This is the default and recommended for most use
   *   cases.
   * - `allow-touch`: Allow scrolling via touch events on elements within the
   *   modal that have the `data-allow-touch-scroll` attribute on the element
   *   or one of its ancestors.
   * - `never`: Never lock scrolling functionality while the modal is open.
   * @default 'always'
   */
  bodyLock?: BodyLock;
  /**
   * The background color or image URL of the modal.
   * Can be either a predefined color or an image URL, but not both.
   */
  background?: ModalBackgroundColor | { imageUrl: string };
};

/**
 * For accessibility with screen readers, the modal's app element should always be set.
 * It should be set on a top level container that includes all of your app components,
 * but importantly not including the modal (which is mounted at the end of the document
 * body). This can be called outside of a component render cycle, somewhere in your app
 * bootstrap.
 *
 * For example:
 *   setAppElement('#root');
 *
 * See http://reactcommunity.org/react-modal/accessibility/#app-element for more information.
 */
export const setAppElement = (appElement: string | HTMLElement) => {
  // NOTE: We're wrapping the `setAppElement` in our own defined function
  // so that the import of `react-modal` isn't included in the TS
  // definition files. This way we don't have to include `@types/react-modal`
  // in the main dependencies. It's an implementation detail that shouldn't
  // be exposed in the public API of the component.
  // See: https://github.com/stitchfix/mode-react/pull/151
  ReactModal.setAppElement(appElement);
};

/**
 * Modals are highly interruptive components that can be used to convey a range of
 * information. Because they demand user interaction, modals should be triggered
 * (predictably) by user interaction.
 *
 * Rare exceptions may be made for system-wide events that require blocking user action.
 * For instance, if a user has been signed out due to inactivity, you might block them
 * from taking actions on the current screen that won't be saved.
 *
 * Modals should be:
 *
 * - Used only one at a time
 * - Focused
 * - Direct
 * - Helpful
 *
 * Provided you pass an `onClose` callback, a modal can be closed by clicking on the X close
 * button, by hitting the Escape key, or by clicking anywhere on the background overlay. If
 * you don't pass `onClose`, you should be sure that there is another way the modal gets closed!
 *
 * For accessibility with screen readers, the modal's app element should always be set.
 * It should be set on a top level container that includes all of your app components,
 * but importantly not including the modal (which is mounted at the end of the document
 * body). This can be called outside of a component render cycle, somewhere in your app
 * bootstrap. For example:
 *
 * ```
 * import { Modal, setAppElement } from '@stitch-fix/mode-react';
 * setAppElement('#root');
 * ```
 *
 * See http://reactcommunity.org/react-modal/accessibility/#app-element for more information.
 */
const Modal = ({
  children,
  variant = 'default',
  size = 'medium',
  isOpen,
  onClose,
  hAlign: respHAlign = 'left',
  heading,
  hero,
  footer,
  bodyLock = 'always',
  background = 'white',
}: ModalProps) => {
  const screenSize = useScreenSize();
  const bodyRef = useBodyLock(bodyLock);

  const hAlign = getResponsiveValue(screenSize, respHAlign, 'left');
  const className = classNames(
    styles.container,
    styles[`size-${size}`],
    background &&
      typeof background === 'string' &&
      styles[`background-color-${background}`],
    {
      [styles['is-inset']]: variant === 'inset-small',
      [styles['is-slide-up']]: variant === 'slide-up-small',
      [styles['h-center']]: hAlign === 'center',
      [styles['has-close']]: onClose,
      [styles['no-hero']]: !hero,
      [styles['no-heading']]: !heading,
    },
  );

  const hasTransition = screenSize === 'sm' && variant === 'slide-up-small';

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={{
        base: className,
        afterOpen: styles['container--after-open'],
        beforeClose: styles['container--before-close'],
      }}
      // See modal.module.scss for details on this global class
      overlayClassName={styles.overlay}
      closeTimeoutMS={hasTransition ? 400 : undefined}
    >
      <InteractableContainer
        iconLabel="close modal"
        iconTitle="Close modal"
        onClickIcon={onClose}
        width="100%"
        iconVariant={hero ? 'overlay' : 'default'}
        {...stopAllPropagationHandlers}
      >
        <Box
          className={classNames(styles.scrollableContent)}
          style={
            background && typeof background === 'object'
              ? {
                  backgroundImage: `url(${background.imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }
              : undefined
          }
        >
          {hero && <Box className={styles.hero}>{hero}</Box>}

          {heading && (
            <Box as="header" className={styles.header}>
              <Text as="h1" setting="title-small" className={styles.heading}>
                {heading}
              </Text>
            </Box>
          )}

          <Box className={styles.body} ref={bodyRef}>
            {children}
          </Box>

          {footer && (
            <Box as="footer" className={styles.footer}>
              {footer}
            </Box>
          )}
        </Box>
      </InteractableContainer>
    </ReactModal>
  );
};

export default Modal;

import React, { ReactNode } from 'react';
import { Modal } from '@stitch-fix/mode-react';

type SearchModalProps = {
  children: ReactNode;
  isOpen: boolean;
  isSmallScreen: boolean;
};

/**
 * `SearchModal` is used to conditionally render its children inside a Modal;
 * uses Modal when `isSmallScreen` prop is true – otherwise it simply renders its children.
 *
 * The Modal's open state is specified by the `isOpen` prop;
 * toggling between open and closed states must be handled by a parent component.
 *
 * We need to set `bodyLock="allow-touch"` to allow scrolling the recent searches list in mobile devices,
 * see more: https://mode-react.daylight.stitchfix.com/?path=/docs/components-modal--body-lock#body-lock
 */
const SearchModal = ({ children, isOpen, isSmallScreen }: SearchModalProps) => {
  if (isSmallScreen) {
    return (
      <Modal isOpen={isOpen} bodyLock="allow-touch">
        {children}
      </Modal>
    );
  }

  return <>{children}</>;
};

export default SearchModal;

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSearch24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M14.5078 2.41714C10.6004 2.41714 7.43282 5.58472 7.43282 9.49214C7.43282 13.3996 10.6004 16.5671 14.5078 16.5671C18.4152 16.5671 21.5828 13.3996 21.5828 9.49214C21.5828 5.58472 18.4152 2.41714 14.5078 2.41714ZM5.83282 9.49214C5.83282 4.70107 9.71675 0.817139 14.5078 0.817139C19.2989 0.817139 23.1828 4.70107 23.1828 9.49214C23.1828 14.2832 19.2989 18.1671 14.5078 18.1671C12.4004 18.1671 10.4684 17.4156 8.96534 16.166L1.94851 23.1828L0.817139 22.0515L7.83397 15.0346C6.58433 13.5316 5.83282 11.5996 5.83282 9.49214Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconSearch24(props, ref);
});
export default ForwardRef;
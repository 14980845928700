import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';
import { KnitLink, type KnitLinkProps } from '../../KnitLink';

type PrimaryLinkProps = KnitLinkProps & {
  active?: boolean;
  children: React.ReactNode;
};

const PrimaryLink = ({
  active = false,
  children,
  onClick,
  ...linkProps
}: PrimaryLinkProps) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick?.(e);
    setClicked(true);
  };

  return (
    <div
      className={classNames(styles.link, {
        [styles.active]: active,
        [styles.clicked]: clicked,
      })}
    >
      <KnitLink
        {...linkProps}
        onClick={handleClick}
        aria-current={active ? 'page' : undefined}
      >
        {children}
      </KnitLink>
    </div>
  );
};

export default PrimaryLink;

import React from 'react';
import {
  IconSocialFacebookCircle24,
  IconSocialInstagram24,
  IconSocialPinterest24,
  IconSocialTiktok24,
  IconSocialTwitter24,
  IconSocialYoutube24,
} from '@stitch-fix/mode-react';

export interface BusinessLine {
  name: 'Men' | 'Women';
  description: string;
}

interface BaseSocial {
  title: string;
  icon: React.ReactNode;
}

type SingleBusinessLineBrand = 'tiktok' | 'youtube';

export interface SingleBusinessLineSocial extends BaseSocial {
  brand: SingleBusinessLineBrand;
}

export type MultiBusinessLineBrand =
  | 'facebook'
  | 'instagram'
  | 'pinterest'
  | 'twitter';

export type SocialBrand = SingleBusinessLineBrand | MultiBusinessLineBrand;

export interface MultiBusinessLineSocial extends BaseSocial {
  brand: MultiBusinessLineBrand;
  businessLines: BusinessLine[];
}

export const multiBusinessLineSocials = (): MultiBusinessLineSocial[] => {
  return [
    {
      brand: 'facebook',
      title: 'The Stitch Fix Facebook Page',
      icon: <IconSocialFacebookCircle24 title="Facebook" />,
      businessLines: [
        {
          name: 'Women',
          description: '/stitchfix',
        },
        {
          name: 'Men',
          description: '/stitchfixmen',
        },
      ],
    },
    {
      brand: 'instagram',
      title: 'Stitch Fix On Instagram',
      icon: <IconSocialInstagram24 title="Instagram" />,
      businessLines: [
        {
          name: 'Women',
          description: '@stitchfix',
        },
        {
          name: 'Men',
          description: '@stitchfixmen',
        },
      ],
    },
    {
      brand: 'pinterest',
      title: 'The Stitch Fix Pinterest Page',
      icon: <IconSocialPinterest24 title="Pinterest" />,
      businessLines: [
        {
          name: 'Women',
          description: '/stitchfix',
        },
        {
          name: 'Men',
          description: '/stitchfixmen',
        },
      ],
    },
    {
      brand: 'twitter',
      title: 'Stitch Fix on Twitter - @stitchfix',
      icon: <IconSocialTwitter24 title="Twitter" />,
      businessLines: [
        {
          name: 'Women',
          description: '@stitchfix',
        },
        {
          name: 'Men',
          description: '@stitchfixmen',
        },
      ],
    },
  ];
};

export const singleBusinessLineSocials = (): SingleBusinessLineSocial[] => {
  return [
    {
      brand: 'tiktok',
      title: 'tiktok',
      icon: <IconSocialTiktok24 title="TikTok" />,
    },
    {
      brand: 'youtube',
      title: 'youtube',
      icon: <IconSocialYoutube24 title="YouTube" />,
    },
  ];
};

// eslint-disable-next-line import/no-extraneous-dependencies -- 'i18next' swallows the types as currently utilized
import { Resource } from 'i18next';

export const translations: Resource = {
  en: {
    FooterContent: {
      help_center: 'Help',
      faq: 'FAQ',
      visitFaq: 'Visit our FAQ',
      returns: 'Returns',
    },
  },
  'en-GB': {
    FooterContent: {
      help_center: 'Help',
      faq: 'FAQs',
      returns: 'Returns',
      visitFaq: 'Visit our FAQs',
    },
  },
} as const;

import React from 'react';
import { Link, LinkProps } from '@stitch-fix/mode-react';
import { useAbsoluteUrl } from './useAbsoluteUrl';

type ValidLinkProps = Pick<
  LinkProps,
  'children' | 'onClick' | 'size' | 'underline' | 'endIcon'
>;

interface ModeLinkProps extends ValidLinkProps {
  /**
   * An absolute, or relative, URL.
   */
  to: string;
  /**
   * Data string used for google analytics.
   */
  'data-ga'?: string;
}

/**
 * The `ModeLink` component handles parsing the absolute, or relative, `to` URL prop.
 * It wraps mode-react's `Link` component and can be used along with the `Text` component to inherit styling.
 *
 * By default, the `ModeLink` does not display an underline in links;
 * pass `underline="initial"` display a link with an initial underline.
 */
export const ModeLink = ({
  to,
  children,
  underline = 'inverse',
  ...optionalProps
}: ModeLinkProps) => {
  const absoluteUrl = useAbsoluteUrl();

  return (
    <Link
      href={absoluteUrl(to)}
      variant="inherit"
      underline={underline}
      {...optionalProps}
    >
      {children}
    </Link>
  );
};

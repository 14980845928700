import React, { useState } from 'react';
import { G } from '@mobily/ts-belt';
import classNames from 'classnames';
import { MultiBusinessLineButton } from './MultiBusinessLineButton';
import {
  type MultiBusinessLineBrand,
  multiBusinessLineSocials,
  singleBusinessLineSocials,
} from './social-brand-data';
import { SingleBusinessLineLink } from './SingleBusinessLineLink';
import { BusinessLineLinks } from './BusinessLineLinks';
import styles from '../style.module.scss';

export interface MultiBusinessLineBrandLink {
  women: string;
  men: string;
}

export interface SingleBusinessLineSocialLink {
  linkHref: string;
}

export interface SocialLinks {
  instagram: MultiBusinessLineBrandLink;
  facebook: MultiBusinessLineBrandLink;
  twitter: MultiBusinessLineBrandLink;
  pinterest: MultiBusinessLineBrandLink;
  tiktok: SingleBusinessLineSocialLink;
  youtube: SingleBusinessLineSocialLink;
}

interface SocialButtonsProps {
  socialLinks: SocialLinks;
}

const SocialUS = ({ socialLinks }: SocialButtonsProps) => {
  const [activeBrand, setActiveBrand] = useState<MultiBusinessLineBrand | null>(
    null,
  );
  const itemsClassnames = classNames(styles.items, {
    [styles['items--active']]: G.isString(activeBrand),
  });
  const activeMultiBusinessLineSocial = multiBusinessLineSocials().find(
    ({ brand }) => brand === activeBrand,
  );

  const multiBusinessLineSocialsData = multiBusinessLineSocials();
  const singleBusinessLineSocialsData = singleBusinessLineSocials();

  return (
    <div className={styles['social-container']}>
      <ul className={itemsClassnames}>
        {multiBusinessLineSocialsData.map(social => (
          <MultiBusinessLineButton
            key={social.brand}
            multiBusinessLineSocial={social}
            isActive={activeBrand === social.brand}
            onClick={() => setActiveBrand(social.brand)}
          />
        ))}

        {singleBusinessLineSocialsData.map(social => (
          <SingleBusinessLineLink
            key={social.brand}
            social={social}
            socialLink={socialLinks[social.brand]}
          />
        ))}
      </ul>

      {G.isString(activeBrand) && G.isObject(activeMultiBusinessLineSocial) && (
        <BusinessLineLinks
          multiBusinessLineLink={socialLinks[activeBrand]}
          multiBusinessLineSocial={activeMultiBusinessLineSocial}
          onClickAway={() => setActiveBrand(null)}
        />
      )}
    </div>
  );
};

export default SocialUS;

import { useLocalStorageValue } from '@react-hookz/web';
import { G } from '@mobily/ts-belt';

interface UngatedShoppingBag {
  totalQuantity: number;
}

export const isUngatedShoppingBag = (
  potential: unknown,
): potential is UngatedShoppingBag =>
  G.isObject(potential) && G.isNumber(potential.totalQuantity);

export const useUngatedShoppingBag = (): UngatedShoppingBag => {
  const [ungatedShoppingBag] = useLocalStorageValue('ShoppingBag');

  if (isUngatedShoppingBag(ungatedShoppingBag)) return ungatedShoppingBag;

  return {
    totalQuantity: 0,
  };
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { G } from '@mobily/ts-belt';
import { useIsomorphicLayoutEffect, usePrevious } from '@react-hookz/web';
import { getDocument, getWindow } from '../../../../helpers/getBrowserGlobals';

interface UseHeaderLogicParams {
  stickyId?: string;
  stickyOffset: number;
}

export const useHeaderLogic = ({
  stickyId,
  stickyOffset,
}: UseHeaderLogicParams) => {
  const PRIMARY_NAV_HIDE_THRESHOLD = 18 * 16; // i.e 18rem
  const headerShellRef = useRef<HTMLDivElement>(null);

  const useStickySection = () => {
    const [yScrollPos, setYScrollPos] = useState(0);
    const stickyElRef = useRef<HTMLElement | null | undefined>(null);

    // ref needed to get the height of the header
    const headerElRef = useRef<HTMLElement>(null);

    const setStickiness = useCallback(() => {
      if (!stickyId) {
        return;
      }

      stickyElRef.current = getDocument()?.getElementById(stickyId);

      if (!stickyElRef.current) {
        return;
      }

      const stickyEl = stickyElRef.current;
      const headerRect = headerElRef.current?.getBoundingClientRect();
      const stickyPos = (headerRect?.bottom || 0) + stickyOffset;

      // Set the element to be sticky at the correct position.
      // The browser knows to stick it when it reaches that point and unstick
      // when it's below
      stickyEl.style.position = 'sticky';
      stickyEl.style.top = `${stickyPos}px`;

      // NOTE: It's important that we retrieve the position of the stickyEl
      // *after* setting it to its sticky position above, because that
      // may reposition it downwards, if we've already scrolled too far.
      const stickElYPos = stickyEl.getBoundingClientRect().top;

      // In order to know if the stickyEl has been stuck, we check to see if
      // its position is at the stickyPos (or higher in the screen). If so,
      // we know it's been stuck. Otherwise, it's still below the header
      // and hasn't been stuck yet. Setting the `.is-stuck` class enables
      // parent apps the ability to style the stickEl differently when it
      // sticks.
      stickyEl.classList.toggle('is-stuck', stickElYPos <= stickyPos);
    }, []);

    // Using `useLayoutEffect` here instead of `useEffect` because we need
    // to read layout from the DOM (getBoundingClientRect) in order to
    // determine when to stick and we need to ensure everything has been
    // rendered
    useIsomorphicLayoutEffect(() => {
      const win = getWindow();
      const handleScroll = () => {
        setStickiness();

        if (
          G.isObject(win) &&
          G.isNumber(win.pageYOffset) &&
          win.pageYOffset >= 0
        ) {
          setYScrollPos(win?.pageYOffset);
        }
      };

      win?.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
        win?.removeEventListener('scroll', handleScroll);

        // reset stickiness
        if (stickyElRef.current) {
          stickyElRef.current.style.position = '';
          stickyElRef.current.style.top = '';
          stickyElRef.current.classList.remove('is-stuck');
        }
      };
    }, [setStickiness]);

    const prevYScrollPos = usePrevious(yScrollPos);

    const isPrimaryNavVisible =
      yScrollPos < PRIMARY_NAV_HIDE_THRESHOLD ||
      yScrollPos < (prevYScrollPos || 0);

    return { isPrimaryNavVisible, headerElRef, setStickiness };
  };

  const { isPrimaryNavVisible, headerElRef, setStickiness } =
    useStickySection();

  useEffect(() => {
    const doc = getDocument();

    doc?.body.classList.add('new-meganav-loaded');

    return () => {
      doc?.body.classList.remove('new-meganav-loaded');
    };
  }, []);

  return {
    headerElRef,
    setStickiness,
    isPrimaryNavVisible,
    headerShellRef,
  };
};

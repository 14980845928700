import React from 'react';
import PropTypes from 'prop-types';
import { setRegion } from '../../i18n';
import FooterContent from './FooterContent';
import ErrorBoundary from '../shared/ErrorBoundary';
import NavContextWrapper, { useNavContext } from '../NavContextWrapper';
import { Region } from '../../constants';
import { regionEnum } from '../shared/shapes';

interface FooterContentWrapperProps {
  region: Region;
  legalContentOnly?: boolean;
}

const FooterContentWrapper = ({
  legalContentOnly = false,
  region,
}: FooterContentWrapperProps) => {
  const { client } = useNavContext();

  const shouldShowOnboardingFooter = client?.capabilities?.needsOnboarding;

  // We want to override the footer content for the UK region and onboarding flow so it only displays legal content
  const shouldDisplayLegalContentOnly =
    legalContentOnly || region === 'UK' || !!shouldShowOnboardingFooter;

  return <FooterContent legalContentOnly={shouldDisplayLegalContentOnly} />;
};

const Footer = ({
  region,
  rootUrl = '',
  legalContentOnly = false,
}: FooterProps) => {
  setRegion(region);

  return (
    <ErrorBoundary>
      <NavContextWrapper rootUrl={rootUrl} mobileConfigKey="showFooter">
        <FooterContentWrapper
          region={region}
          legalContentOnly={legalContentOnly}
        />
      </NavContextWrapper>
    </ErrorBoundary>
  );
};

/**
 * please keep these in sync
 */

export interface FooterProps {
  region: Region;
  rootUrl?: string;
  legalContentOnly?: boolean;
}

Footer.propTypes = {
  region: regionEnum,
  rootUrl: PropTypes.string,
  legalContentOnly: PropTypes.bool,
};

export default Footer;

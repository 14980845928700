import React from 'react';
import classNames from 'classnames';
import { IconButton } from '@stitch-fix/mode-react';
import { MultiBusinessLineSocial } from './social-brand-data';
import { SocialItem } from './SocialItem';
import styles from '../style.module.scss';

interface SocialButtonProps {
  multiBusinessLineSocial: MultiBusinessLineSocial;
  isActive: boolean;
  onClick: () => void;
}

export const MultiBusinessLineButton = ({
  multiBusinessLineSocial: { brand, icon },
  isActive,
  onClick,
}: SocialButtonProps) => {
  const clickTargetClassNames = classNames({
    [styles['click-target--active']]: isActive,
  });

  return (
    <SocialItem brand={brand} className={clickTargetClassNames}>
      <IconButton
        aria-label={`open ${brand} social link menu`}
        onClick={onClick}
      >
        <>{icon}</>
      </IconButton>
    </SocialItem>
  );
};

import React from 'react';
import classNames from 'classnames/bind';
import { StickerLabel, Text } from '@stitch-fix/mode-react';
import { formatMoneyForLocale } from '@stitch-fix/i18n';
import { KnitLink } from '../../../../KnitLink';
import {
  featureIsAvailable,
  getRegion,
  useSharedTranslation,
} from '../../../../../i18n';
import DropdownControl from './DropdownControl';
import DropdownPortal from '../../../../shared/DropdownPortal';
import LogoutButton from './LogoutButton';
import { Client } from '../../../../NavContextWrapper/types';
import styles from './style.module.scss';
import { getAllocation } from '../../../../../helpers/configParams';
import { regionalUrls } from '../../../../../i18n/regionalUrls';
import { useDropdownOpen } from '../../helpers/useDropdownOpen';
import HouseholdMembers from './HouseholdMembers';
import { useHousehold } from '../../helpers/useHousehold';
import { isInFeatureFlag } from '../../../../../helpers/isInFeatureFlag';

interface DropdownProps {
  client: Client;
  isPrimaryNavVisible: boolean;
  suppressKeptItemAndOrderLinks?: boolean;
  isReferralShown?: boolean;
}

const cx = classNames.bind(styles);

const Dropdown = ({
  client,
  isPrimaryNavVisible,
  suppressKeptItemAndOrderLinks = false,
  isReferralShown = false,
}: DropdownProps) => {
  const { t, locale } = useSharedTranslation();
  const {
    isOpen,
    dropdownControlRef,
    dropdownMenuRef,
    toggleIsOpen,
    closeDropdown,
  } = useDropdownOpen(isPrimaryNavVisible);
  const { getCircleColorForUser } = useHousehold(client);

  const hasCompletedOnboarding = !client.capabilities.needsOnboarding;
  const canViewKeptItems =
    client.businessLine !== 'KIDS' ||
    isInFeatureFlag('client.global.kids_categories', client?.featureFlags);
  const referralBonusAmount = client.referrals.incentive?.senderCredit;
  const region = locale === 'en-US' ? 'US' : 'UK';
  const siteRegion = getRegion();

  // Clients are able to purchase items before on-boarding
  // Kids clients who are not in the categories ff should not see the Wardrobe
  const showKeptItemsLink =
    hasCompletedOnboarding &&
    canViewKeptItems &&
    !suppressKeptItemAndOrderLinks;

  const showOrdersLink = hasCompletedOnboarding || canViewKeptItems;

  const showStyleFileLink =
    client.stylefileStatus && client.stylefileStatus !== 'INELIGIBLE';

  /** We are running a Loyalty Pilot of US clients */
  const loyaltyPilotAllocation = getAllocation(
    client.configParams,
    'eng.kufak.incentives.loyalty_pilot',
  );

  const clientCanOrderFix = client.capabilities.canScheduleFix;

  const isInLoyaltyPilot =
    region === 'US' &&
    loyaltyPilotAllocation &&
    loyaltyPilotAllocation !== 'control';

  const styleLinks = (
    <>
      {showKeptItemsLink && (
        <KnitLink
          data-ga="meganav-primary-dropdown-style-kept-items"
          to="/wardrobe/bought-items"
          className={cx('section-row', 'section-row-link')}
        >
          Kept Items
        </KnitLink>
      )}
      <KnitLink
        data-ga="meganav-primary-dropdown-style-profile"
        to="/style/profile"
        className={cx('section-row', 'section-row-link')}
      >
        Style Profile
      </KnitLink>
      {showStyleFileLink && (
        <KnitLink
          data-ga="meganav-primary-dropdown-stylefile"
          to="/client/stylefile"
          className={cx('section-row', 'section-row-link')}
        >
          StyleFile{' '}
          <StickerLabel ml={0.5} variant="citrus">
            New
          </StickerLabel>
        </KnitLink>
      )}
    </>
  );

  const accountLinks = (
    <>
      {isInLoyaltyPilot && (
        <KnitLink
          data-ga="meganav-primary-dropdown-rewards"
          to="/rewards"
          className={cx('section-row', 'section-row-link')}
        >
          Stitch Fix Rewards
        </KnitLink>
      )}
      {showOrdersLink && (
        <KnitLink
          data-testid="dropdown-menu-orders-link"
          data-ga="meganav-primary-dropdown-order-history"
          to="/orders"
          className={cx('section-row', 'section-row-link')}
        >
          Orders
        </KnitLink>
      )}

      <KnitLink
        data-ga="meganav-primary-dropdown-account-settings"
        to="/settings"
        className={cx('section-row', 'section-row-link')}
      >
        Account Settings
      </KnitLink>
      {clientCanOrderFix && (
        <KnitLink
          data-ga="meganav-primary-dropdown-fix-frequency"
          to="/fix-frequency"
          className={cx('section-row', 'section-row-link')}
        >
          Fix Frequency
        </KnitLink>
      )}
    </>
  );

  const miscLinks = (
    <>
      {featureIsAvailable('returns') && (
        <KnitLink
          data-ga="meganav-primary-dropdown-return-options"
          to="/fixcheckout/return_options"
          className={cx('section-row', 'section-row-link')}
        >
          Return Options
        </KnitLink>
      )}
      <KnitLink
        data-ga="meganav-primary-dropdown-client-referrals"
        to="/client/referrals"
        className={cx('section-row', 'section-row-link', 'referral-link', {
          hide: !isReferralShown,
        })}
        data-testid="meganav-dropdown-referral-link"
      >
        {t('referLink.refer')}{' '}
        {referralBonusAmount && referralBonusAmount.value > 0 && (
          <>& get {formatMoneyForLocale(locale, referralBonusAmount)}</>
        )}
      </KnitLink>

      <KnitLink
        data-ga="meganav-primary-dropdown-help"
        className={cx('section-row', 'section-row-link')}
        to={regionalUrls[siteRegion].help}
      >
        Help
      </KnitLink>

      <LogoutButton client={client} onModalOpen={closeDropdown} />
    </>
  );

  // In the rebrand, the links are organized into a single section:
  const linkSections = [
    {
      id: 'account',
      heading: 'Your Account',
      links: (
        <>
          {styleLinks}
          {accountLinks}
          {miscLinks}
        </>
      ),
    },
  ];

  return (
    <div
      className={styles['dropdown-control-wrapper']}
      ref={dropdownControlRef}
    >
      <DropdownControl
        firstName={client.firstName || ''}
        circleColor={getCircleColorForUser(client)}
        onClick={() => toggleIsOpen()}
      />
      <DropdownPortal>
        <div className={styles['dropdown-wrapper']}>
          <div
            className={cx('dropdown-menu', isOpen ? 'dropdown-menu--open' : '')}
            tabIndex={-1}
            ref={dropdownMenuRef}
            data-testid="dropdown"
          >
            <div className={styles['name-wrapper']}>
              <DropdownControl
                firstName={client.firstName || ''}
                theme="light"
                circleColor={getCircleColorForUser(client)}
                onClick={() => toggleIsOpen()}
              />
            </div>
            <div
              className={styles.section}
              data-testid="dropdown-menu-household-section"
            >
              <HouseholdMembers client={client} />
            </div>

            {linkSections.map(
              section =>
                section && (
                  <div key={section.id}>
                    <div
                      className={styles.section}
                      data-testid={`dropdown-menu-${section.id}-section`}
                    >
                      {section.heading && (
                        <Text setting="eyebrow-small" ml={0.375} my={0.5}>
                          {section.heading}
                        </Text>
                      )}
                      {section.links}
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </DropdownPortal>
    </div>
  );
};

export default Dropdown;

import React from 'react';
import { Text } from '@stitch-fix/mode-react';
import { featureIsAvailable } from '../../../i18n';
import { ModeLink } from '../../KnitLink';
import { CcpaLinks } from './CcpaLinks';
import styles from './style.module.scss';

export const LegalLinks = () => {
  return (
    <div className={styles['legal-links-container']} data-testid="legal-links">
      <LegalFooterLinks />
      <TrademarkText />
    </div>
  );
};

export const LegalFooterLinks = () => {
  return (
    <Text
      color="blue-45"
      setting="body-small-fixed"
      className={styles['policy-links']}
      as="div"
      data-testid="legal-footer-links"
    >
      <ModeLink to="/terms">Terms of Use</ModeLink>
      {' - '}
      <ModeLink to="/accessibility">Accessibility</ModeLink>
      {' - '}
      <ModeLink to="/privacy">Privacy Policy</ModeLink>
      {' - '}
      <ModeLink to="https://www.stitchfix.com/supply-chain-information">
        Supply Chain Information
      </ModeLink>
      {' - '}
      <ModeLink to="/privacy#marketing-companies">Ad Choices</ModeLink>
      <CcpaLinks />
      {featureIsAvailable('cookie_info') && (
        <>
          {' - '}
          <ModeLink to="/cookie-info">Cookie Info</ModeLink>
        </>
      )}
      {' - '}
      <ModeLink to="/sitemap">Sitemap</ModeLink>
    </Text>
  );
};

export const TrademarkText = () => {
  return (
    <Text
      className={styles['trademark-text']}
      color="blue-45"
      setting="body-small-fixed"
    >
      Stitch Fix and Fix are trademarks of Stitch Fix, Inc.
    </Text>
  );
};

export default LegalLinks;

import React, { useEffect } from 'react';
import { SlimBanner } from '@stitch-fix/mode-react';
import RegionIcon from '../../../shared/RegionIcon';
import { getDocument } from '../../../../helpers/getBrowserGlobals';
import { getRegion } from '../../../../i18n';
import { getRedirectToRegionURL } from '../../../../helpers/region';

interface RegionBannerProps {
  onHide: () => void;
}

const RegionBanner = ({ onHide }: RegionBannerProps) => {
  const region = getRegion();
  const otherRegion = region === 'US' ? 'UK' : 'US';

  const hide = () => {
    getDocument()?.body.classList.remove('region-banner-loaded');
    onHide();
  };

  useEffect(() => {
    // This is used to hide the persistentCTA component in the dynamic-shock repo
    // when regionBanner is present
    getDocument()?.body.classList.add('region-banner-loaded');
  }, []);

  return (
    <SlimBanner
      text={{
        sm: `You’re on the ${region} site. Switch to ${otherRegion}`,
        md: `You’re on the ${region} site.`,
      }}
      icon={<RegionIcon region={region} />}
      cta={{
        url: getRedirectToRegionURL(otherRegion),
        text: `Switch to ${otherRegion}`,
      }}
      onClose={hide}
    />
  );
};

export default RegionBanner;

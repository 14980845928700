import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialFacebook16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" color="#25282D" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M11.5078 9L11.9522 6.10437H9.17375V4.22531C9.17375 3.43313 9.56188 2.66094 10.8063 2.66094H12.0694V0.195625C12.0694 0.195625 10.9231 0 9.82719 0C7.53906 0 6.04344 1.38688 6.04344 3.8975V6.10437H3.5V9H6.04344V16H9.17375V9H11.5078Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconSocialFacebook16(props, ref);
});
export default ForwardRef;
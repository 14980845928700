import removeTrailingSlash from 'remove-trailing-slash';

const isMatchingPath = (pathOne: string, pathTwo: string) => {
  return removeTrailingSlash(pathOne) === removeTrailingSlash(pathTwo);
};

const isOnGatewayPath = (path: string) => {
  return isMatchingPath('', removeTrailingSlash(path));
};

const isOnWomensPath = (path: string) => {
  return removeTrailingSlash(path).includes('/women');
};

const isOnMensPath = (path: string) => {
  return removeTrailingSlash(path).includes('/men');
};

const isOnKidsPath = (path: string) => {
  return removeTrailingSlash(path).includes('/kids');
};

const isOnSupportPath = (path: string) => {
  return removeTrailingSlash(path).includes('support.stitchfix.com');
};

const isOnGiftsPath = (path: string) => {
  return removeTrailingSlash(path).includes('/gifts');
};

const isOnShoppingBagPath = (path: string) => {
  return removeTrailingSlash(path).includes('/shopping_bag');
};

const isOnReferralPath = (path: string) => {
  return removeTrailingSlash(path).includes('/referrals');
};

const isOnFreestylePath = (path: string) => {
  return (
    /^\/shop*/.test(path) ||
    /^\/my-items*/.test(path) ||
    /^\/gated-product*/.test(path)
  );
};

export {
  isMatchingPath,
  isOnGatewayPath,
  isOnWomensPath,
  isOnMensPath,
  isOnKidsPath,
  isOnGiftsPath,
  isOnShoppingBagPath,
  isOnFreestylePath,
  isOnSupportPath,
  isOnReferralPath,
};

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSearch16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.64744 1.80253C7.13454 1.80253 5.09744 3.83963 5.09744 6.35253C5.09744 8.86542 7.13454 10.9025 9.64744 10.9025C12.1603 10.9025 14.1974 8.86542 14.1974 6.35253C14.1974 3.83963 12.1603 1.80253 9.64744 1.80253ZM3.69744 6.35253C3.69744 3.06643 6.36135 0.402527 9.64744 0.402527C12.9335 0.402527 15.5974 3.06643 15.5974 6.35253C15.5974 9.63862 12.9335 12.3025 9.64744 12.3025C8.25663 12.3025 6.97728 11.8253 5.96417 11.0257L1.39242 15.5975L0.402466 14.6076L4.97422 10.0358C4.17463 9.02269 3.69744 7.74334 3.69744 6.35253Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconSearch16(props, ref);
});
export default ForwardRef;
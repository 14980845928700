import bugsnagNotify from '../bugsnagNotify';

// Background:
// Keepalive requests have a 64 kibibyte limit (65,536b) per fetch group. A
// fetch group contains all in-flight requests per JavaScript execution context
// (i.e., per web page). According to the spec, only the size of the `body` is
// counted towards this limit. If a keepalive request would cause the limit to
// be exceeded, the browser discards the request. Reference:
// https://fetch.spec.whatwg.org/#http-network-or-cache-fetch

// Purpose of the eventQueue:
// This queue helps prevent event loss caused by exceeding the in-flight limit.
// It keeps track of the body size of all keepalives being sent in the
// JavaScript execution context, and queues events if they would exceed the in
// flight bytes limit. Non-event fetches are not enqueued, to avoid breaking the
// expectations of any other code.

interface AddToQueueArgs {
  body: string;
  requestId: string;
  url: string;
  keepalive: boolean;
}

interface QueueEntry {
  body: string;
  requestId: string;
  url: string;
  bytes: number;
}

type Fetch = typeof window.fetch;

// Set our internal in-flight limit below the 64 * 1024 browser in-flight limit
// to allow some room for unexpected edge cases
const BYTES_IN_FLIGHT_LIMIT = 50 * 1024;
const QUEUE_ALERT_LENGTH = 500;
const queue: QueueEntry[] = [];
let bytesInFlightCounter = 0;

// For testing only; clears the singleton's stored data between tests
const resetSingleton = () => {
  queue.length = 0;
  bytesInFlightCounter = 0;
};

// There is no API to determine the in-flight content length for FormData, but
// we can estimate it. This function has been tested and shown to be relatively
// accurate. Adapted from: https://stackoverflow.com/a/63495529/530653
const estimateFormDataSize = (formData: FormData): number => {
  // 44b in WebKit browsers (e.g. Chrome, Safari, etc.), but different in
  // Firefox. Buffer bytes added to cover variability.
  const estimatedBaseSize = 50;
  // 87b in WebKit browsers (e.g. Chrome, Safari, etc.), but different in
  // Firefox. Buffer bytes added to cover variability.
  const estimatedSeparatorSize = 115;
  let size = estimatedBaseSize;

  for (const [key, value] of [...formData.entries()]) {
    // FormData keys are either Strings or Numbers
    const keySize = String(key).length;

    // FormData values are either Blobs or Strings
    const valueSize = value instanceof Blob ? value.size : value.length;

    size += keySize + estimatedSeparatorSize + valueSize;
  }

  return size;
};

const getBodySize = (body: BodyInit) => {
  if (typeof body === 'string') {
    return body.length;
  }
  if (body instanceof Blob) {
    return body.size;
  }
  if (body instanceof FormData) {
    return estimateFormDataSize(body);
  }
  if (body instanceof URLSearchParams) {
    return body.toString().length;
  }
  // Keepalives can't be ReadableStreams, but handle this case to keep
  // typescript happy
  if (body instanceof ReadableStream) {
    return 0;
  }

  // ArrayBuffers, ArrayBufferViews and Typed Arrays
  return body.byteLength;
};

// Wraps fetch with a function that keeps track of all in-flight keepalive
// request bytes. After each keepalive request resolves, attempts to send a
// queued event.
const setupQueue = () => {
  const origFetch: Fetch = window.fetch;

  window.fetch = (input, init) => {
    const isKeepalive = init?.keepalive;
    const body = init?.body;

    // If not a keepalive request, or if the body would not add to the bytes in
    // flight limit, call fetch with no added behavior
    if (!isKeepalive || !body) return origFetch(input, init);

    const bytes = getBodySize(body);

    // Keep track of bytes in flight
    bytesInFlightCounter += bytes;

    // Remove a keepalive fetch's bytes from the bytes in flight counter when it
    // errors or resolves
    return origFetch(input, init).finally(() => {
      bytesInFlightCounter -= bytes;

      processQueue();
    });
  };
};

// Interface for sending internal events without exceeding the keepalive bytes
// in flight limit
const addToQueue = ({ body, requestId, url, keepalive }: AddToQueueArgs) => {
  if (!keepalive) {
    // Non-keepalive requests don't need to be queued to manage keepalive limits,
    // we can send immediately
    sendEvent({ body, requestId, url, keepalive });

    return;
  }

  const bytes = getBodySize(body);

  // Discard events that are too large to send
  if (bytes > BYTES_IN_FLIGHT_LIMIT) {
    bugsnagNotify({
      error: new Error(
        `Event is larger than ${BYTES_IN_FLIGHT_LIMIT} byte limit, and has been discarded`,
      ),
      metadata: { body, bytes, requestId },
    });

    return;
  }

  queue.push({ bytes, body, requestId, url });

  if (queue.length > QUEUE_ALERT_LENGTH) {
    bugsnagNotify({
      error: new Error('Event queue is above a safe size'),
      metadata: { length: queue.length },
    });
  }

  processQueue();
};

const processQueue = () => {
  if (!queue.length) return;

  // Get the fetch at the front of the queue
  const { bytes, body, requestId, url } = queue[0];

  // Check if sending this fetch would exceed the bytes in flight limit. If so,
  // exit and wait for returning requests to reduce the bytes in flight counter
  // and call this function again.
  if (bytes + bytesInFlightCounter > BYTES_IN_FLIGHT_LIMIT) return;

  // Remove the fetch from the queue
  queue.shift();

  sendEvent({ body, requestId, url, keepalive: true });
};

const sendEvent = ({ body, requestId, url, keepalive }: AddToQueueArgs) => {
  window
    .fetch(url, {
      body,
      credentials: 'same-origin',
      headers: {
        'X-Request-Id': requestId,
      },
      keepalive,
      method: 'POST',
      mode: 'same-origin',
    })
    .catch((error: Error) => {
      const isKeepaliveError = error.message.includes('keepalive');
      const errorName = isKeepaliveError
        ? 'EventReporterKeepaliveError'
        : 'EventReporterNetworkError';

      // Reassigning `name` allows us to maintain the original stack and message
      // without complex error cloning. This gives developers immediate signal
      // of what this error is within bugsnag.
      // eslint-disable-next-line no-param-reassign
      error.name = errorName;

      bugsnagNotify({
        // We group these into single errors irrespective of which specific event
        // or component resulted in an error, since the cause (network errors) is
        // not dependent on the event or component, and developers can snooze
        // these behind a single threshold in bugsnag.
        group: errorName,
        error,
        metadata: {
          body,
          bytes: getBodySize(body),
          requestId,
          bytesInFlight: bytesInFlightCounter,
        },
      });
    });
};

export { addToQueue, resetSingleton, setupQueue };

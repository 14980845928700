export const regionalUrls = {
  US: {
    help: 'https://support.stitchfix.com/support/home',
    support: 'https://support.stitchfix.com',
    faq: 'https://support.stitchfix.com/hc/en-us/sections/360006899113',
    returns:
      'https://support.stitchfix.com/support/solutions/articles/153000173841-returns',
  },
  UK: {
    help: 'https://support.stitchfix.co.uk/support/home',
    support: 'https://support.stitchfix.co.uk/hc/en-gb',
    faq: 'https://support.stitchfix.co.uk/hc/en-gb',
    returns:
      'https://support.stitchfix.com/support/solutions/articles/153000173841-returns',
  },
} as const;

import { type FeatureFlags } from '../components/NavContextWrapper/types';

export const isInFeatureFlag = (
  flag: string,
  featureFlags: FeatureFlags,
): boolean => {
  const featureFlagIndex = featureFlags.findIndex(item => item.name === flag);

  if (featureFlags[featureFlagIndex]?.enabled === true) {
    return true;
  }

  return false;
};

/**
 * The purpose of this file is to keep multiple browser tabs in sync
 * when there are account changes, such as a client logs in, logs out
 * or switches family accounts.
 *
 * The strategy is to use the BroadcastChannel api to publish navData
 * each time a page loads, then other tabs will be listening on the same
 * channel for these events, and if there are any relevant changes
 * to the navData those other tabs will refresh the page.
 *
 * @module services/navDataChangeHandler
 */

import { BroadcastChannel } from 'broadcast-channel';
import { A, D } from '@mobily/ts-belt';
import deepEqual from 'fast-deep-equal';
import { getWindow } from '../helpers/getBrowserGlobals';
import { GetNavDataQuery } from '../components/NavContextWrapper/getNavData';

const CHANNEL_NAME = 'sf_knit_v2';

type OnUpdateCallback = (navData: GetNavDataQuery) => void;

interface Store {
  /**
   * Used to prevent sending the same message twice
   */
  lastPublishedMessage: string | null;
  /**
   * Used to compare against when new client data is received from another tab
   */
  latestNavData: GetNavDataQuery | null;
  /**
   * stores functions to call when navData is updated from another tab
   */
  channel: BroadcastChannel | null;
  /**
   * gets lazy loaded by the `channel()` function
   */
  onUpdateCallbacks: OnUpdateCallback[];
  /**
   * Should only be true in test environment
   */
  useTestChannel: boolean;
  /**
   * Set to true once the channel listener is registered
   */
  listenerRegistered: boolean;
}

const store: Store = {
  lastPublishedMessage: null,
  latestNavData: null,
  onUpdateCallbacks: [],
  channel: null,
  useTestChannel: false,
  listenerRegistered: false,
};

/**
 * Encapsulates logic used to compare old and new navData
 * @constructor
 * @private
 * @param {Object} oldNavData - The navData currently being used on the page
 * @param {Object} newNavData - New navData that was published by a different tab
 */

class NavDataChange {
  oldNavData: GetNavDataQuery | null;

  newNavData: GetNavDataQuery;

  constructor(oldNavData: GetNavDataQuery | null, newNavData: GetNavDataQuery) {
    this.oldNavData = oldNavData;
    this.newNavData = newNavData;
  }

  /**
   * @returns {boolean}
   */
  clientHasLoggedInOrOut() {
    return (
      this.oldNavData && !!this.oldNavData.client !== !!this.newNavData.client
    );
  }

  clientIdChanged() {
    return this.oldNavData?.client?.id !== this.newNavData.client?.id;
  }

  dataChanged() {
    return !deepEqual(this.oldNavData, this.newNavData);
  }

  /**
   * @returns {boolean}
   */
  processable() {
    return this.oldNavData && this.newNavData;
  }
}

type HandlerName = 'navDataUpdate';

const handlers: Record<HandlerName, (n: GetNavDataQuery) => void> = {
  navDataUpdate: (newNavData: GetNavDataQuery) => {
    const change = new NavDataChange(store.latestNavData, newNavData);

    if (!change.processable()) {
      return;
    }

    const shouldReload =
      change.clientHasLoggedInOrOut() || change.clientIdChanged();

    if (shouldReload) {
      getWindow()?.location.reload();
    } else if (change.dataChanged()) {
      store.latestNavData = newNavData;
      store.lastPublishedMessage = null;
      for (const callback of store.onUpdateCallbacks) {
        callback(newNavData);
      }
    }
  },
};

const isHandlerName = (str: string): str is HandlerName =>
  A.includes(D.keys(handlers), str);

// Lazy load the channel.
const channel = () => {
  if (store.channel) {
    return store.channel;
  }

  store.channel = new BroadcastChannel<string>(CHANNEL_NAME);

  return store.channel;
};

// Listen for messages, and pass data to handler if a matching handler is defined.
// `message` is a JSON formatted string, containing both handlerName and data attributes.
const registerListener = () => {
  if (store.listenerRegistered) {
    return;
  }

  channel().onmessage = message => {
    const { handlerName, data } = JSON.parse(message);

    if (isHandlerName(handlerName)) handlers[handlerName](data);
  };

  store.listenerRegistered = true;
};

/**
 * @private
 * @param {string} handlerName - Used to identify which handler defined above should process the data
 * @param {Object} data - Whatever data you want other tabs to know about
 */
const postMessage = (handlerName: HandlerName, data: GetNavDataQuery) => {
  const message = JSON.stringify({ handlerName, data });

  if (store.lastPublishedMessage === message) {
    // Keep us from publishing the same message multiple times. This could happen
    // because this code gets called twice on page load, once in header and once
    // in footer.
    return;
  }

  store.lastPublishedMessage = message;

  if (store.useTestChannel) {
    // In tests, we need a way to simulate some other browser tab publishing a message,
    // since channels don't receive messages published by themselves.
    // We do this by publishing on a different channel.
    const tempChannel = new BroadcastChannel(CHANNEL_NAME);

    tempChannel.postMessage(message);
    tempChannel.close();
  } else {
    channel().postMessage(message);
  }
};

/**
 * Call this to register the current navData. It will publish this navData for other
 * tabs to receive and check if anything has changed.
 * @param {Object} newNavData
 * @param {Object} options
 * @param {boolean} options.updateSelf - default to false. Use this if you want the tab that calls `registerNavData` to also update
 *                                       it's own navData. This can be useful in some single page web apps where we want to manually
 *                                       refresh navData on the page without doing a full page refresh, while also letting other browser
 *                                       tabs know about the change.
 */
const registerNavData = (
  newNavData: GetNavDataQuery,
  { updateSelf = false } = {},
) => {
  store.latestNavData = newNavData;
  postMessage('navDataUpdate', newNavData);

  if (updateSelf) {
    for (const callback of store.onUpdateCallbacks) {
      callback(newNavData);
    }
  }

  registerListener();
};

/**
 * This callback will be called when new navData is received from another tab AND the navData
 * has changed in a notable way.
 * @param {function} callback
 *   The first argument passed to the function is the new navData received from a
 *   broadcast-channel message.
 */
const registerOnUpdateCallback = (callback: OnUpdateCallback) => {
  store.onUpdateCallbacks.push(callback);
};

/**
 * USE FOR TESTS ONLY!
 * `channel.onmessage` will not fire unless you call this method first in your tests.
 * This is because channels don't receive messages published by themselves.
 * When `useTestChannel` is true, it will instead publish on a separate temporary channel.
 * @param {boolean} value
 * @example
 *   useTestChannel(true);
 * @internal
 */
const useTestChannel = (value: boolean) => {
  store.useTestChannel = value;
};

/**
 * USE FOR TESTS ONLY!
 * In tests, if we register before publishing, the navData we get from
 * the published message will always match what we have in our register,
 * and therefore it will be skipped and we can't assert anything about
 * it. So this skips the registering.
 *
 * In order for this to work in tests you should call things in this order:
 *   1. registerNavData
 *   2. registerOnUpdateCallback
 *   3. publishNavDataWithoutRegistering
 *
 * @param {Object} newNavData
 * @internal
 */
const publishNavDataWithoutRegistering = (newNavData: GetNavDataQuery) => {
  postMessage('navDataUpdate', newNavData);
};

const TEST_ONLY = {
  useTestChannel,
  publishNavDataWithoutRegistering,
};

export { registerNavData, registerOnUpdateCallback, TEST_ONLY };

import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@stitch-fix/mode-react';
import { KnitLink } from '../../../../KnitLink';
import DropdownPortal from '../../../../shared/DropdownPortal';
import { getRegion } from '../../../../../i18n';
import DropdownControl from '../../MyAccount/Dropdown/DropdownControl';
import LogoutButton from '../../MyAccount/Dropdown/LogoutButton';
import styles from './style.module.scss';
import { Client } from '../../../../NavContextWrapper/types';
import { regionalUrls } from '../../../../../i18n/regionalUrls';
import { useDropdownOpen } from '../../helpers/useDropdownOpen';
import HouseholdMembers from '../../MyAccount/Dropdown/HouseholdMembers';
import { useHousehold } from '../../helpers/useHousehold';

interface SimplifiedDropdownProps {
  client: Client;
  isPrimaryNavVisible: boolean;
  isNameVisible?: boolean;
}

const cx = classNames.bind(styles);

/**
 * This is a simplified version of the Dropdown menu that contains the Help
 * and Logout links, as well as the Household Members section (any kids accounts
 * that may already exist and a link to add a new kids profile). The Account Settings
 * and Style Profile links have recently been added to this dropdown as well.
 */
const SimplifiedDropdown = ({
  client,
  isPrimaryNavVisible,
  isNameVisible,
}: SimplifiedDropdownProps) => {
  const siteRegion = getRegion();
  const {
    isOpen,
    dropdownControlRef,
    dropdownMenuRef,
    toggleIsOpen,
    closeDropdown,
  } = useDropdownOpen(isPrimaryNavVisible);
  const { getCircleColorForUser } = useHousehold(client);

  const styleLinks = (
    <KnitLink
      data-ga="meganav-primary-dropdown-style-profile"
      to="/style/profile"
      className={cx('section-row', 'section-row-link')}
    >
      Style Profile
    </KnitLink>
  );

  const accountLinks = (
    <KnitLink
      data-ga="meganav-primary-dropdown-account-settings"
      to="/settings"
      className={cx('section-row', 'section-row-link')}
    >
      Account Settings
    </KnitLink>
  );

  const miscLinks = (
    <>
      <KnitLink
        data-ga="meganav-primary-dropdown-help"
        className={cx('section-row', 'section-row-link')}
        to={regionalUrls[siteRegion].support}
        target="_blank"
        rel="noopener noreferrer"
      >
        Help
      </KnitLink>
      <LogoutButton client={client} onModalOpen={closeDropdown} />
    </>
  );

  const linkSections = [
    {
      id: 'account',
      heading: 'Your Account',
      links: (
        <>
          {styleLinks}
          {accountLinks}
          {miscLinks}
        </>
      ),
    },
  ];

  return (
    <div
      className={styles['dropdown-control-wrapper']}
      ref={dropdownControlRef}
    >
      <DropdownControl
        firstName={client.firstName || ''}
        firstNameColor="dark"
        onClick={() => toggleIsOpen()}
        circleColor={getCircleColorForUser(client)}
        isNameVisible={isNameVisible}
      />
      <DropdownPortal>
        <div className={styles['dropdown-wrapper']}>
          <div
            className={cx('dropdown-menu', isOpen ? 'dropdown-menu--open' : '')}
            tabIndex={-1}
            ref={dropdownMenuRef}
            data-testid="simplified-dropdown"
          >
            <div className={styles['name-wrapper']}>
              <DropdownControl
                firstName={client.firstName || ''}
                theme="light"
                onClick={() => toggleIsOpen()}
                circleColor={getCircleColorForUser(client)}
              />
            </div>

            <div
              className={styles.section}
              data-testid="dropdown-menu-household-section"
            >
              <HouseholdMembers client={client} />
            </div>

            {linkSections.map(
              section =>
                section && (
                  <div key={section.id}>
                    <div
                      className={styles.section}
                      data-testid={`dropdown-menu-${section.id}-section`}
                    >
                      {section.heading && (
                        <Text setting="eyebrow-small" ml={0.375} my={0.5}>
                          {section.heading}
                        </Text>
                      )}
                      {section.links}
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </DropdownPortal>
    </div>
  );
};

export default SimplifiedDropdown;

import React from 'react';
import { IconFlagUk20, IconFlagUsa20 } from '@stitch-fix/mode-react';
import { A, D, pipe } from '@mobily/ts-belt';
import { getWindow } from './getBrowserGlobals';
import { Region, REGION } from '../constants';

const approvedRegions = D.values(REGION);

export const isApprovedRegion = (region: string): region is Region =>
  A.includes(approvedRegions, region);

interface RegionDetails {
  hostname: string;
  icon: React.ReactElement;
  label: string;
  tld: string;
}

export const REGION_DATA: Record<Region, RegionDetails> = {
  US: {
    hostname: 'https://www.stitchfix.com',
    icon: <IconFlagUsa20 purpose="decorative" />,
    tld: '.com',
    label: 'United States',
  },
  UK: {
    hostname: 'https://www.stitchfix.co.uk',
    icon: <IconFlagUk20 purpose="decorative" />,
    tld: '.co.uk',
    label: 'United Kingdom',
  },
} as const;

const validTLDs = pipe(
  REGION_DATA,
  D.map(value => value.tld),
  D.values,
);

const validTLDRegExp = new RegExp(
  `(${validTLDs.join('|').replace('.', '\\.')})$`,
);

// getRedirectToRegionURL() will build a URL that can be used to
// switch to the current page, in a different region.  This will
// take into account the current subdomain, path, and query params.
export const getRedirectToRegionURL = (region: Region) => {
  const win = getWindow();
  const path = win ? `${win.location.pathname}${win.location.search}` : '';
  const origin = win ? win.location.origin : REGION_DATA[region].hostname;

  const newOrigin = origin.replace(validTLDRegExp, REGION_DATA[region].tld);

  return `${newOrigin}${path}`;
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconShoppingBag24 } from '@stitch-fix/mode-react';
import classNames from 'classnames/bind';
import { getWindow } from '../../../helpers/getBrowserGlobals';
import { isOnShoppingBagPath } from '../../../helpers/pathHelper';
import styles from './style.module.scss';
import PrimaryLink from '../PrimaryLink';
import useCurrentPath from '../../../helpers/useCurrentPath';

const cx = classNames.bind(styles);

interface GlobalShoppingBagProps {
  activePageCanonical?: string;
  count: number;
  gated?: boolean;
  hideIf?: (count: number) => boolean;
}

const GlobalShoppingBag = ({
  activePageCanonical,
  count,
  gated = false,
  hideIf = () => false,
}: GlobalShoppingBagProps) => {
  const bagCounterRef = useRef<HTMLSpanElement>(null);
  const [bagCount, setBagCount] = useState(count);
  const [bagCountAnimationStyle, setBagCountAnimationStyle] = useState('');
  const queryParams = activePageCanonical
    ? `?ic=${activePageCanonical}`
    : '?ic=other';
  const toLinkUngated = `/product/shopping_bag${queryParams}`;
  const toLinkGated = `/my-items/shopping_bag${queryParams}`;
  const currentPath = useCurrentPath();

  const onAnimationEnd = useCallback(() => {
    if (bagCountAnimationStyle === 'bag-count-animate-in') {
      setBagCountAnimationStyle('bag-count-animate-reset');

      setTimeout(() => {
        setBagCount(bagCount);
        setBagCountAnimationStyle('bag-count-animate-out');
      }, 10);
    } else if (bagCountAnimationStyle === 'bag-count-animate-out') {
      setBagCountAnimationStyle('');
    }
  }, [bagCount, bagCountAnimationStyle]);

  useEffect(() => {
    const win = getWindow();

    const incrementBagCount = () => {
      setBagCount(curBagCount => curBagCount + 1);
      setBagCountAnimationStyle('bag-count-animate-in');
    };

    const decrementBagCount = () => {
      setBagCount(curBagCount => Math.max(curBagCount - 1, 0));
      setBagCountAnimationStyle('bag-count-animate-in');
    };

    const initWindowStitchFix = () => {
      if (win) {
        win.stitchfix = win.stitchfix || {};
        win.stitchfix.incrementBagCount = incrementBagCount;
        win.stitchfix.decrementBagCount = decrementBagCount;
      }
    };

    initWindowStitchFix();
  }, []);

  useEffect(() => {
    const bagCounter = bagCounterRef.current;

    bagCounter?.addEventListener('webkitAnimationEnd', onAnimationEnd, false);
    bagCounter?.addEventListener('animationend', onAnimationEnd, false);

    return () => {
      bagCounter?.removeEventListener(
        'webkitAnimationEnd',
        onAnimationEnd,
        false,
      );
      bagCounter?.removeEventListener('animationend', onAnimationEnd, false);
    };
  }, [onAnimationEnd]);

  if (hideIf(bagCount)) {
    return null;
  }

  return (
    <div
      data-testid="shopping-bag"
      className={cx(
        'shopping-bag',
        gated ? 'shopping-bag-light' : 'shopping-bag-dark',
      )}
    >
      <PrimaryLink
        data-ga="nav-primary-shopping-bag"
        to={gated ? toLinkGated : toLinkUngated}
        active={isOnShoppingBagPath(currentPath)}
        aria-label="Shopping bag"
      >
        <span className={styles['bag-icon-container']}>
          <span
            className={cx('bag-count', bagCountAnimationStyle)}
            ref={bagCounterRef}
            onAnimationEnd={onAnimationEnd}
          >
            <span
              className={styles['bag-count-text']}
              data-testid="bag-count-text"
            >
              {(!!bagCount || !!bagCountAnimationStyle) && bagCount}
            </span>
          </span>
          <IconShoppingBag24 purpose="decorative" />
        </span>
        <span className={styles['bag-label']}>Bag</span>
      </PrimaryLink>
    </div>
  );
};

export default GlobalShoppingBag;

import React, { ReactNode, useContext } from 'react';
import logger from '../_internal/logger';
import useUniqueId from '../_internal/useUniqueId';
import IconContext, { IconContextState } from './IconContext';
import { Color, useColors } from '../_internal/colors';

type SvgIconPurpose = 'standalone' | 'decorative';

// Props passed to the svg processing.
export interface IconSvgProps extends Partial<React.SVGProps<SVGSVGElement>> {
  /**
   * Used to communicate the purpose of the icon via text for accessibility. This
   * value is important for accessibility purposes for `standalone` icons that do
   * not have text or other attributes to provide that information. This value
   * should describe the action or meaning the icon is trying to convey, not
   * just a visual description of what it looks like. Note that the title will
   * show up as a native browser tooltip if the user hovers over it.
   */
  title?: string;

  /**
   * Used to associate the title with the svg. If not set, a unique id will be
   * generated for you.
   */
  titleId?: string;
}

// Props used for non-base icons.
export interface IconProps extends Omit<IconSvgProps, 'color' | 'children'> {
  /**
   * The purpose the icon serves to the user. This is used to determine how
   * accessibility props and checks are applied to the icon.
   *
   * - `standalone`: The purpose of the icon needs to be communicated to the
   *   user because the icon is not purely decorative and there is no label or
   *   nearby text.
   * - `decorative`: The purpose of the icon does not need to be communicated to
   *   the user because a label or other nearby text is already doing the
   *   communication or the icon is purely decorative.
   *
   * @default 'standalone'
   */
  purpose?: SvgIconPurpose;

  /**
   * Sets the color of the icon.
   *
   * - `default`: Uses the default color configured on the icon.
   * - `inherit`: Inherit the text color from the element containing the icon.
   * - named color: Use a specific named color from the style system.
   * @default 'default'
   */
  color?: 'default' | 'inherit' | Color;
}

export interface IconBaseProps extends IconProps {
  /**
   * Used internally to wrap svgs when generating icon components. `children`
   * should not be used on icon components.
   */
  children: (props: IconSvgProps) => ReactNode;
}

const genIconTitleId = (id: string): string => `${id}_icon_title`;

/**
 * This component is used as a wrapper for all icons to provide a consistent
 * way to apply props, check for accessiblity, etc. This component should not
 * be used directly, but is listed in storybook to provide general
 * documentation for working with icons.
 *
 * See [iconography](https://mode-react.daylight.stitchfix.com/?path=/docs/iconography--page) for a full list of available icons.
 */
const IconBase = ({
  children,
  purpose,
  title,
  titleId,
  color,
  ...rootProps
}: IconBaseProps) => {
  const id = useUniqueId('icon');
  const iconContext = useContext<IconContextState | undefined>(IconContext);

  // Set purpose in order of precedence: prop, context, 'standalone'
  const iconPurpose = purpose || iconContext?.defaultPurpose || 'standalone';

  // Set color in order of precedence:
  // context override, prop, context default, 'default'
  // In most cases, we just want to set the defaultColor via context, but
  // there are some cases where we want to override colors completely (e.g.
  // disabled color icon in IconButton).
  const iconColor =
    iconContext?.overrideColor ||
    color ||
    iconContext?.defaultColor ||
    'default';

  const a11yProps: IconProps = {};

  if (iconPurpose === 'decorative') {
    // Hide decorative icons from screen readers because they serve no meaningful
    // purpose.
    a11yProps['aria-hidden'] = true;
  } else {
    // Communicates to the screen reader that the svg is an image. We only do
    // this in non-decorative cases because otherwise it can confuse a11y
    // testing tools that check for a title.
    a11yProps.role = 'img';
  }

  if (iconPurpose === 'standalone' && !title) {
    logger.warn(
      'Standalone icons must specify a `title` to satisfy accessibility needs',
    );
  }

  if (title) {
    // If no title id is specified, generate a unique one for them to ensure
    // that the title and svg are correlated with each other for all browsers.
    a11yProps.titleId = titleId || genIconTitleId(id);
  }

  const presentationProps: IconSvgProps = {};

  const colors = useColors();

  // The color attribute on svgs is set to the default color. Override this
  // value when we want to use a different color.
  if (iconColor && iconColor !== 'default') {
    presentationProps.color =
      iconColor === 'inherit' ? 'currentColor' : colors[iconColor];
  }

  return (
    <>
      {children({
        ...rootProps,
        ...a11yProps,
        ...presentationProps,
        title,
      })}
    </>
  );
};

export default IconBase;

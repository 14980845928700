import React from 'react';
import { featureIsAvailable } from '../../../../i18n';
import {
  isOnKidsPath,
  isOnMensPath,
  isOnWomensPath,
} from '../../../../helpers/pathHelper';
import useCurrentPath from '../../../../helpers/useCurrentPath';
import styles from './style.module.scss';
import PrimaryLink from '../../PrimaryLink';

const BusinessLineNav = () => {
  const currentPath = useCurrentPath();

  return (
    <ul className={styles.items}>
      {featureIsAvailable('women') && (
        <li>
          <PrimaryLink
            className={styles.link}
            data-ga="nav-primary-businessline-women"
            to="/women"
            active={isOnWomensPath(currentPath)}
          >
            Women
          </PrimaryLink>
        </li>
      )}
      {featureIsAvailable('men') && (
        <li>
          <PrimaryLink
            className={styles.link}
            data-ga="nav-primary-businessline-men"
            to="/men"
            active={isOnMensPath(currentPath)}
          >
            Men
          </PrimaryLink>
        </li>
      )}

      {featureIsAvailable('kids') && (
        <li>
          <PrimaryLink
            className={styles.link}
            data-ga="nav-primary-businessline-kids"
            to="/kids"
            active={isOnKidsPath(currentPath)}
          >
            Kids
          </PrimaryLink>
        </li>
      )}
    </ul>
  );
};

export default BusinessLineNav;

import React from 'react';
import { IconSearch24 } from '@stitch-fix/mode-react';
import styles from './style.module.scss';

interface SearchButtonProps {
  onClick: () => void;
}

const SearchButton = ({ onClick }: SearchButtonProps) => {
  return (
    <button
      aria-label="Toggle search input"
      type="button"
      onClick={onClick}
      className={styles['search-button']}
    >
      <IconSearch24 color="inherit" purpose="decorative" />
    </button>
  );
};

export default SearchButton;

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconAddInCircle32 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M16.0001 2.89998C8.76517 2.89998 2.9001 8.76505 2.9001 16C2.9001 23.2349 8.76517 29.1 16.0001 29.1C23.235 29.1 29.1001 23.2349 29.1001 16C29.1001 8.76505 23.235 2.89998 16.0001 2.89998ZM1.1001 16C1.1001 7.77093 7.77105 1.09998 16.0001 1.09998C24.2291 1.09998 30.9001 7.77093 30.9001 16C30.9001 24.229 24.2291 30.9 16.0001 30.9C7.77106 30.9 1.1001 24.229 1.1001 16ZM16.5601 9.09998V9.99998V14.92H22.0001H22.9001V16.72H22.0001H16.5601V22V22.9H14.7601V22V16.72H10.0001H9.1001V14.92H10.0001H14.7601V9.99998V9.09998H16.5601Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconAddInCircle32(props, ref);
});
export default ForwardRef;
import React from 'react';
import { Box, Link, Stack, Text } from '@stitch-fix/mode-react';
import { ComboboxList, ComboboxOption } from '@reach/combobox';
import { FreestyleRecentSearch } from './getRecentSearches';
import styles from './style.module.scss';

interface RecentSearchesListProps {
  isSmallScreen: boolean;
  onSelect: (value: string) => void;
  recentSearches: FreestyleRecentSearch[];
}

/**
 * Renders a given list of `recentSearches`.
 *
 * On small screens, below 560px, the recent searches are always displayed.
 * On non-small screens, `RecentSearchesList` implements `ComboboxList` and `ComboboxOption`
 * to display the list in a dropdown.
 *
 * To allow scrolling the recent searches on small screens,
 * we need to set `data-allow-touch-scroll` in the list container.
 * See more: https://mode-react.daylight.stitchfix.com/?path=/docs/components-modal--body-lock#body-lock
 */
const RecentSearchesList = ({
  isSmallScreen,
  recentSearches,
  onSelect,
}: RecentSearchesListProps) => {
  if (isSmallScreen) {
    return (
      <Box className={styles['recent-searches-list']} data-allow-touch-scroll>
        <Stack as="ul" spacing={1} mb={20.5}>
          {recentSearches.map(({ id, label }) => (
            <Text setting="body-small-fixed" my={0} key={id}>
              <Link
                variant="inherit"
                underline="inverse"
                as="button"
                onClick={() => onSelect(label)}
              >
                {label}
              </Link>
            </Text>
          ))}
        </Stack>
      </Box>
    );
  }

  // We don't need an `onClick` handler for `ComboboxOption`,
  // it is automatically handled by `Combobox`'s `onSelect` prop in `SearchInput`
  return (
    <ComboboxList className={styles['reach-combobox-list']}>
      <Stack spacing={1}>
        {recentSearches.map(({ id, label }) => (
          <Text setting="body-small-fixed" my={0} key={id}>
            <ComboboxOption
              className={styles['reach-combobox-option']}
              value={label}
            >
              {label}
            </ComboboxOption>
          </Text>
        ))}
      </Stack>
    </ComboboxList>
  );
};

export default RecentSearchesList;

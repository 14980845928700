import React from 'react';
import {
  IconSuccess16,
  IconError16,
  IconInformation16,
  IconFixDelivery16,
} from '@stitch-fix/mode-react';
import type { GraphqlBanner } from './BannerTypes';

type IconKeys = 'SUCCESS' | 'ERROR' | 'INFORMATION' | 'FIXDELIVERY';
type IconForward = typeof IconSuccess16;

const iconMap: { [key in IconKeys]: IconForward } = {
  SUCCESS: IconSuccess16,
  ERROR: IconError16,
  INFORMATION: IconInformation16,
  FIXDELIVERY: IconFixDelivery16,
};

interface BannerIconProps {
  icon: GraphqlBanner['icon'];
}

const toTitleCase = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const isValidIcon = (key: unknown): key is IconKeys =>
  Object.keys(iconMap).includes(String(key));

const BannerIcon = ({ icon }: BannerIconProps) => {
  if (!icon || !isValidIcon(icon)) return null;

  const RenderedIcon = iconMap[icon];

  return RenderedIcon ? (
    <RenderedIcon
      purpose="decorative"
      aria-label={`${toTitleCase(icon)} icon`}
    />
  ) : null;
};

export default BannerIcon;

import React, { useEffect, useState } from 'react';
import { getWindow, getDocument } from '@stitch-fix/mode-react';
import classNames from 'classnames/bind';
import { G } from '@mobily/ts-belt';
import { CollapsibleContent } from '../CollapsibleContent';
import { LegalLinks } from '../LegalLinks';
import styles from '../style.module.scss';

const cx = classNames.bind(styles);

interface FooterContentProps {
  legalContentOnly?: boolean;
}

const FooterContent = ({ legalContentOnly = false }: FooterContentProps) => {
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    const doc = getDocument();
    const win = getWindow();

    if (G.isObject(doc) && G.isObject(win)) {
      const docHeight = doc.body.clientHeight;
      const viewportHeight = win.innerHeight;
      const difference = docHeight - viewportHeight;

      setIsSmall(difference < 200);
    }
  }, []);

  return (
    <div
      id="sticky-footer"
      data-testid="footer-content"
      // Immediately hide footer included in build-time rendered pages from mobile app webviews through CSS
      data-hide-on-native-apps
      className={cx('hidden-ios-webview', {
        smallPage: isSmall,
      })}
    >
      <footer className={styles.footer} data-testid="footer">
        <div className={styles.footer__body}>
          {legalContentOnly ? <LegalLinks /> : <CollapsibleContent />}
        </div>
      </footer>
    </div>
  );
};

export default FooterContent;

import React from 'react';
import classNames from 'classnames/bind';
import { Box } from '@stitch-fix/mode-react';
import styles from './style.module.scss';
import AccountBadge from '../../AccountBadge';

interface DropdownControlProps {
  firstName: string;
  firstNameColor?: string;
  onClick: () => void;
  theme?: string;
  circleColor?: number;
  isNameVisible?: boolean;
}

const cx = classNames.bind(styles);

const DropdownControl = ({
  firstName,
  firstNameColor = 'light',
  theme = '',
  onClick,
  circleColor = 0,
  isNameVisible = true,
}: DropdownControlProps) => {
  const firstNameCharacter = firstName.charAt(0);
  const themeString = theme === 'light' ? 'light-theme' : '';

  return (
    <div className={cx('full-height', themeString)}>
      <button
        onClick={onClick}
        className={styles['account-dropdown-button']}
        type="button"
      >
        <AccountBadge circleColor={circleColor} text={firstNameCharacter} />
        <Box
          as="span"
          className={cx(
            firstNameColor === 'dark' ? 'dark-account-name' : 'account-name',
          )}
        >
          {isNameVisible && firstName}
        </Box>
      </button>
    </div>
  );
};

export default DropdownControl;

export const translations = {
  en: {
    KnitShared: {
      referLink: {
        refer: 'Refer',
        get: 'Get %{amount}',
        getRebrand: '& get %{amount}',
      },
      faq: {
        label: 'FAQ',
      },
    },
  },
  'en-GB': {
    KnitShared: {
      referLink: {
        refer: 'Invite',
        get: 'Get %{amount}',
        getRebrand: '& get %{amount}',
      },
      faq: {
        label: 'FAQs',
      },
    },
  },
} as const;

import { useContext } from 'react';
import isAbsoluteUrl from 'is-absolute-url';
import { NavContext } from '../NavContextWrapper';

/**
 * Converts a relative URL to an absolute URL. If the provided `path` is
 * already an absolute URL, it is returned as-is.
 */
const toAbsoluteUrl = (rootUrl: string, path: string) => {
  return isAbsoluteUrl(path) ? path : `${rootUrl}${path}`;
};

/**
 * React hook to build absolute URLs from relative URL. Returns a function
 * that accepts a relative URL and returns the absolute URL. If the provided
 * URL is already absolute, it is returned as-is.
 */
export const useAbsoluteUrl = () => {
  const { rootUrl } = useContext(NavContext);

  /**
   * Given a relative URL, returns an absolute URL. If the provided
   * URL is already absolute, it is returned as-is.
   */
  return (path: string) => toAbsoluteUrl(rootUrl, path);
};

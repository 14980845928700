// This is the only file that allows these restricted globals because it
// provides helper functions to use them in a safe way for projects using
// server-side-rendering.

interface KnitStitchfixGlobal extends StitchfixGlobal {
  decrementBagCount?: (() => void) | undefined;
  incrementBagCount?: () => void;
  refreshNavData?: () => void;
  onSearch?: (searchTerm: string) => void;
}

interface KnitWindow extends Window {
  dataLayer?: Record<string, unknown>[];

  sfixMobile?: {
    isiOS?: () => boolean;
    isAndroid?: () => boolean;
    isMobileApp?: () => boolean;
    showHeader?: () => boolean;
    showFooter?: () => boolean;
  };

  stitchfix?: KnitStitchfixGlobal;
}

/**
 * Returns the window object if it exists in the current
 * environment. Otherwise, `undefined`.
 *
 * This also allows us to properly extend the window object in
 * typescript without resorting to `declare global`
 */
export const getWindow = () =>
  // eslint-disable-next-line no-restricted-globals
  typeof window === 'object' ? (window as KnitWindow) : undefined;

/**
 * Returns the document object if it exists in the current
 * environment. Otherwise, `undefined`
 * @returns {Document | undefined}
 */
export const getDocument = () =>
  // eslint-disable-next-line no-restricted-globals
  typeof document === 'object' ? document : undefined;

import {
  SlimBannerProps as ModeSlimBannerProps,
  BannerProps as ModeBannerProps,
} from '@stitch-fix/mode-react';
import { GetNavDataQuery } from '../../NavContextWrapper/getNavData';

// Union type to hold the possible banner variants
export type SlimThemesType = NonNullable<ModeSlimBannerProps['variant']>;
export type SiteWideThemesType = NonNullable<ModeBannerProps['variant']>;

const SUPPORTED_BANNER_TYPES = [
  'ModeBannerSitewide',
  'ModeBannerSlim',
] as const;

type BaseGraphqlBanner = GetNavDataQuery['banners'][number];
type SupportedBannerType = (typeof SUPPORTED_BANNER_TYPES)[number];
export type GraphqlBanner = Extract<
  BaseGraphqlBanner,
  { __typename: SupportedBannerType }
>;

type NormalizedSiteWideBanner = {
  type: 'ModeBannerSitewide';
  banner: ModeBannerProps;
};
type NormalizedSlimBanner = {
  type: 'ModeBannerSlim';
  banner: ModeSlimBannerProps;
};
export type NormalizedBanner = {
  id: string;
  type: SupportedBannerType;
} & (NormalizedSiteWideBanner | NormalizedSlimBanner);

export interface LegacySiteWideBanner {
  /** Unique identifier for banner used to cache state. */
  id: string;
  cta?: {
    text: string;
    href: string;
  };
  headline?: string;
  message: React.ReactNode;
  onClose?: () => void;
  /**
   * only dark variants are used for site-wide banners
   */
  variant: 'error-dark' | 'info-dark' | 'promo-dark';
}

export interface LegacySlimBanner extends ModeSlimBannerProps {
  /** Unique identifier for banner used to cache state. */
  id: string;
}

export type ExtractedBanner = Extract<
  GetNavDataQuery['banners'][number],
  { __typename: 'ModeBannerSitewide' }
>;
export interface BannersProps {
  legacySiteWideBanner?: LegacySiteWideBanner;
  legacySlimBanner?: LegacySlimBanner;
  graphQLBanners?: GraphqlBanner[];
}

export const isSupportedBannerType = (
  banner: BaseGraphqlBanner,
): banner is GraphqlBanner =>
  // eslint-disable-next-line no-underscore-dangle
  SUPPORTED_BANNER_TYPES.some(type => type === banner.__typename);

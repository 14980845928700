import { Component, ReactNode } from 'react';
import { sendBugsnagEventToKnit } from '../sendBugsnagEventToKnit';

type Props = {
  /**
   * The module to be wrapped by the error boundary.
   */
  children: ReactNode;
};

interface State {
  hasError: boolean;
}

/**
 * A component that catches JavaScript errors anywhere in its child component tree,
 * renders null, and optionally logs the error.
 */
class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will hide the UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if ('ignoreIfHandled' in error) {
      return;
    }

    if (process.env.NODE_ENV === 'development') {
      console.error(error); // eslint-disable-line no-console
    } else {
      sendBugsnagEventToKnit({ error });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) return null;

    return children;
  }
}

export default ErrorBoundary;

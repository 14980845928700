import { getWindow } from '../getBrowserGlobals';

const getMobileObj = () => getWindow()?.sfixMobile;

const getMobileConfig = () => ({
  isiOS: getMobileObj()?.isiOS?.() ?? false,
  isAndroid: getMobileObj()?.isAndroid?.() ?? false,
  isMobileApp: getMobileObj()?.isMobileApp?.() ?? false,
  showHeader: getMobileObj()?.showHeader?.() ?? true,
  showFooter: getMobileObj()?.showFooter?.() ?? true,
});

export default getMobileConfig;

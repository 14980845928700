import React from 'react';
import { IconButton } from '@stitch-fix/mode-react';
import UKSocials from './social-brand-data';
import styles from '../style.module.scss';

const SocialButtonsUK = () => (
  <div className={styles['social-container']}>
    <ul className={styles.items}>
      {UKSocials.map(({ brand, linkHref, icon }) => (
        <li key={brand}>
          <IconButton aria-label={`visit ${brand}`} href={linkHref} as="a">
            <>{icon}</>
          </IconButton>
        </li>
      ))}
    </ul>
  </div>
);

export default SocialButtonsUK;

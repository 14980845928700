import React from 'react';
import { LogoBrand2024Navy, LogoMonogramNavy } from '@stitch-fix/mode-react';
import styles from './style.module.scss';
import PrimaryLink from '../../PrimaryLink';

interface BrandLinkProps {
  href: string;
  active?: boolean;
  disabled?: boolean;
}

const BrandLink = ({
  href,
  active = false,
  disabled = false,
}: BrandLinkProps) => {
  const smallLogo = (
    <LogoMonogramNavy title="Stitch Fix" data-testid="logo-mark" />
  );
  const largeLogo = <LogoBrand2024Navy title="Stitch Fix" data-testid="logo" />;

  return disabled ? (
    <div className={styles['home-disabled-link']}>
      <span className={styles['logo-circle']}>{smallLogo}</span>
      <span className={styles.wordmark}>{largeLogo}</span>
    </div>
  ) : (
    <PrimaryLink
      active={active}
      className={styles['home-link']}
      to={href}
      data-ga="meganav-primary-brand-link"
      data-testid="brand-link"
    >
      <span className={styles['logo-circle']}>{smallLogo}</span>
      <span className={styles.wordmark}>{largeLogo}</span>
    </PrimaryLink>
  );
};

export default BrandLink;

import { Toast, IconError16 } from '@stitch-fix/mode-react';
import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import logout from '../../../../../../api/logout';
import { KnitLink } from '../../../../../KnitLink';
import { getWindow } from '../../../../../../helpers/getBrowserGlobals';
import { NavContext } from '../../../../../NavContextWrapper';
import styles from '../style.module.scss';
import SignOutModal from '../../../SimplifiedHeader/SimplifiedDropdown/SignOutModal';
import type { Client } from '../../../../../NavContextWrapper/types';

const cx = classNames.bind(styles);

interface LogoutButtonProps {
  client: Client;
  onModalOpen: () => void;
}

const LogoutButton = ({ client, onModalOpen }: LogoutButtonProps) => {
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { rootUrl } = useContext(NavContext);

  const shouldShowSignOutModal = client?.capabilities?.needsOnboarding;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    logout(rootUrl)
      .then(() => {
        const params = new URLSearchParams(getWindow()?.location.search);
        let redirectUrl = rootUrl;

        // if the rootUrl is not set, the assign function will reload the
        // current page which could contain the email_validation token resulting
        // in the user being logged back in again.
        if (rootUrl === '' && params.has('email_validation')) {
          params.delete('email_validation');
          redirectUrl = `?${params.toString()}`;
        }

        getWindow()?.location.assign(redirectUrl);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const handleModalOpen = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setIsModalOpen(true);
    onModalOpen();
  };

  return (
    <div>
      <KnitLink
        data-ga="meganav-primary-dropdown-logout"
        onClick={shouldShowSignOutModal ? handleModalOpen : handleLogout}
        className={cx('section-row', 'section-row-link')}
        to={rootUrl} // Not used since default behavior is prevented on click
      >
        Sign Out
      </KnitLink>
      <SignOutModal
        client={client}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirmLogout={handleLogout}
      />
      <Toast
        isOpen={isError}
        onClose={() => setIsError(false)}
        startContent={<IconError16 purpose="decorative" />}
      >
        We couldn&apos;t sign you out. Please try again.
      </Toast>
    </div>
  );
};

export default LogoutButton;

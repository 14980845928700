import { Region } from '../../constants';
import { REGION_DATA } from '../../helpers/region';

interface RegionIconProps {
  region: Region;
}

const RegionIcon = ({ region }: RegionIconProps) => REGION_DATA[region].icon;

export default RegionIcon;

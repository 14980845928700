import React from 'react';
import PropTypes from 'prop-types';
import {
  regionEnum,
  bannerPropShape,
  slimBannerPropShape,
} from '../shared/shapes';
import ErrorBoundary from '../shared/ErrorBoundary';
import { setRegion } from '../../i18n';
import { LegacySiteWideBanner, LegacySlimBanner } from './Banners/BannerTypes';
import NavContextWrapper from '../NavContextWrapper';
import HeaderWrapper, { WrappedLoggedOutHeader } from './HeaderWrapper';
import { Region } from '../../constants';
import { InternalLoggedOutHeaderProps } from './InternalLoggedOutHeader';

const LoggedOutHeader = ({
  banner,
  slimBanner,
  loginRedirectUrl,
  region,
}: LoggedOutHeaderProps) => {
  setRegion(region);

  return (
    <ErrorBoundary>
      <NavContextWrapper rootUrl="">
        <WrappedLoggedOutHeader
          banner={banner}
          slimBanner={slimBanner}
          loginRedirectUrl={loginRedirectUrl}
        />
      </NavContextWrapper>
    </ErrorBoundary>
  );
};

const Header = ({
  activePage,
  banner,
  disableHomeLink = false,
  loginRedirectUrl,
  slimBanner,
  stickyId,
  rootUrl = '',
  region,
  stickyOffset = 0,
  suppressFreestyleFeatureNav = false,
  suppressNavLinks = false,
  suppressBanners = false,
  shouldReloadOnLogoClick = false,
}: HeaderProps) => {
  setRegion(region);

  return (
    <ErrorBoundary>
      <NavContextWrapper rootUrl={rootUrl} mobileConfigKey="showHeader">
        <HeaderWrapper
          activePage={activePage}
          banner={banner}
          loginRedirectUrl={loginRedirectUrl}
          slimBanner={slimBanner}
          stickyId={stickyId}
          stickyOffset={stickyOffset}
          suppressNavLinks={suppressNavLinks}
          disableHomeLink={disableHomeLink}
          suppressFreestyleFeatureNav={suppressFreestyleFeatureNav}
          suppressBanners={suppressBanners}
          shouldReloadOnLogoClick={shouldReloadOnLogoClick}
        />
      </NavContextWrapper>
    </ErrorBoundary>
  );
};

/**
 * please keep these in sync
 */

export interface HeaderProps {
  activePage?: string;
  banner?: LegacySiteWideBanner;
  /**
   * If included, this is the URL the user will
   * be redirected to after a successful login action.
   */
  loginRedirectUrl?: string;
  region: Region;
  rootUrl?: string;
  slimBanner?: LegacySlimBanner;
  /**
   * ID of the element on the page that will stick under
   * the header instead of scrolling under the page.
   * An `.is-stuck` class will be added to it when it sticks.
   */
  stickyId?: string;
  /**
   * Number of pixels from the bottom of the header to
   * stick the element.
   */
  stickyOffset?: number;
  suppressNavLinks?: boolean;
  disableHomeLink?: boolean;
  suppressFreestyleFeatureNav?: boolean;
  suppressBanners?: boolean;
  shouldReloadOnLogoClick?: boolean;
}

Header.propTypes = {
  activePage: PropTypes.string,
  banner: bannerPropShape,
  slimBanner: slimBannerPropShape,
  loginRedirectUrl: PropTypes.string,
  region: regionEnum,
  rootUrl: PropTypes.string,
  stickyId: PropTypes.string,
  stickyOffset: PropTypes.number,
  suppressNavLinks: PropTypes.bool,
  suppressBanners: PropTypes.bool,
  disableHomeLink: PropTypes.bool,
  suppressFreestyleFeatureNav: PropTypes.bool,
  shouldReloadOnLogoClick: PropTypes.bool,
};

export interface LoggedOutHeaderProps extends InternalLoggedOutHeaderProps {
  region: Region;
}

LoggedOutHeader.propTypes = {
  banner: bannerPropShape,
  slimBanner: slimBannerPropShape,
  loginRedirectUrl: PropTypes.string,
  region: regionEnum,
};

export { LoggedOutHeader };

export default Header;

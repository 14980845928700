import { gql } from '@apollo/client';

export const CLEAR_RECENT_SEARCHES = gql`
  fragment recentSearches on ShopContent {
    recentSearches {
      id
      label
    }
  }

  mutation clearRecentSearches {
    clearRecentSearches {
      result {
        ... on ClearRecentSearchesResultError {
          code
        }
        ... on ShopContent {
          id
          ...recentSearches
        }
      }
    }
  }
`;

export type ClearRecentSearchesMutation = {
  __typename?: 'Mutation';
  clearRecentSearches?: {
    __typename?: 'ClearRecentSearchesPayload';
    result:
      | {
          __typename?: 'ClearRecentSearchesResultError';
          code: 'UNEXPECTED_ERROR';
        }
      | {
          __typename?: 'ShopContent';
          id: string;
          recentSearches: {
            __typename?: 'FreestyleRecentSearch';
            id: string;
            label: string;
          }[];
        };
  } | null;
};

import React from 'react';
import { Text } from '@stitch-fix/mode-react';
import { BusinessLine, MultiBusinessLineBrand } from './social-brand-data';
import { MultiBusinessLineBrandLink } from '.';
import styles from '../style.module.scss';

interface BusinessLineProps {
  brand: MultiBusinessLineBrand;
  businessLine: BusinessLine;
  multiBusinessLineLink: MultiBusinessLineBrandLink;
}

export const BusinessLineLink = ({
  brand,
  businessLine: { name, description },
  multiBusinessLineLink,
}: BusinessLineProps) => {
  // yeah, we know https://stitchfix.atlassian.net/browse/VXN-174
  const lowercaseName: Lowercase<keyof MultiBusinessLineBrandLink> =
    name === 'Men' ? 'men' : 'women';

  return (
    <li key={name}>
      <a
        href={multiBusinessLineLink[lowercaseName]}
        className={styles['multi-business-line-link']}
        aria-label={`visit ${brand} for ${lowercaseName}`}
      >
        <Text height="standard" family="medium" scale="2">
          {name}
        </Text>

        <Text
          className={styles['account-name']}
          height="standard"
          family="regular"
          scale="1"
        >
          {description}
        </Text>
      </a>
    </li>
  );
};

import { A } from '@mobily/ts-belt';
import { REGION, Region } from '../../../../constants';
import type { Client } from '../../../NavContextWrapper/types';
import { isInFeatureFlag } from '../../../../helpers/isInFeatureFlag';

/** Secondary link names */
const FIX_INSPIRATION = 'fix-inspiration';
const KEPT_ITEMS = 'kept-items';
const NEW_COLORS = 'new-colors';
const PAST_FIXES = 'past-fixes';
const SAVED_ITEMS = 'saved-items';
const STYLE_PROFILE = 'style-profile';
const STYLE_QUIZZES = 'style-quizzes';
const STYLE_SHUFFLE_SHOP = 'style-shuffle-shop';
const STYLED_FIX = 'styled-fix';
const YOUR_FIX = 'your-fix';
const YOUR_LOOKS = 'your-looks';

const secondaryLinkNames = [
  FIX_INSPIRATION,
  KEPT_ITEMS,
  NEW_COLORS,
  PAST_FIXES,
  SAVED_ITEMS,
  STYLE_PROFILE,
  STYLE_QUIZZES,
  STYLE_SHUFFLE_SHOP,
  STYLED_FIX,
  YOUR_FIX,
  YOUR_LOOKS,
];

export const isSecondaryLinkPage = (page: string) =>
  secondaryLinkNames.includes(page);

const isClientEligibleForHome = (client: Client, region: Region) =>
  region === REGION.US && client.businessLine !== 'KIDS';

const isClientEligibleForSaveForLater = (client: Client, region: Region) => {
  const hasCompletedOnboarding = !client.capabilities.needsOnboarding;
  const canShop = client.capabilities.canShop;

  return (
    hasCompletedOnboarding &&
    canShop &&
    region === REGION.US &&
    (client.businessLine !== 'KIDS' ||
      isInFeatureFlag('client.global.kids_categories', client?.featureFlags))
  );
};

const isClientEligibleForCategories = (client: Client, region: Region) => {
  const hasCompletedOnboarding = !client.capabilities.needsOnboarding;
  const canShop = client.capabilities.canShop;

  return (
    hasCompletedOnboarding &&
    canShop &&
    region === REGION.US &&
    (client.businessLine !== 'KIDS' ||
      isInFeatureFlag('client.global.kids_categories', client?.featureFlags))
  );
};

interface LinkBase {
  name: string;
  text: string;
  location: string;
}

interface SecondaryLink extends LinkBase {
  canonical: string;
}

interface PossiblePrimaryLink extends Omit<LinkBase, 'location'> {
  location?: string;
  possibleSecondaryLinks: SecondaryLink[];
}

/**
 * Returns all possible links with configuration such as text and url.
 * This list gets filtered down by eligibility in a different function.
 */
const getPossibleLinks = (client: Client): PossiblePrimaryLink[] => {
  const isKidsBusinessLine = client.businessLine === 'KIDS';

  const shopYourLooksSecondaryLink = {
    name: YOUR_LOOKS,
    text: client.firstName ? `${client.firstName}'s Looks` : 'Your Looks',
    location: '/shop/looks',
    canonical: 'your_looks',
  };

  const categoriesSecondaryLink = {
    name: STYLE_SHUFFLE_SHOP,
    text: 'Categories',
    location: '/shop/categories',
    canonical: 'categories',
  };

  const buyItAgainSecondaryLink = {
    name: NEW_COLORS,
    text: 'Buy It Again',
    location: '/my-items/shop/new_colors',
    canonical: 'new_colors',
  };

  const shopPossibleSecondaryLinks = [
    shopYourLooksSecondaryLink,
    categoriesSecondaryLink,
    buyItAgainSecondaryLink,
  ];

  let yourFixCopy: string;

  if (isKidsBusinessLine) {
    yourFixCopy = 'Their Fix';
  } else {
    yourFixCopy = 'Current Fix';
  }

  return [
    {
      name: 'fixes',
      text: 'Fix',
      possibleSecondaryLinks: [
        {
          name: YOUR_FIX,
          text: yourFixCopy,
          location: '/fixes',
          canonical: 'fixes',
        },
        {
          name: PAST_FIXES,
          text: 'Past Fixes',
          location: '/fixes/past',
          canonical: 'past_fixes',
        },
        {
          name: FIX_INSPIRATION,
          text: 'Fix Inspiration',
          location: '/inspiration',
          canonical: 'fix_inspiration_board',
        },
      ],
    },
    {
      name: 'shop',
      text: 'Freestyle',
      location: '/shop',
      possibleSecondaryLinks: shopPossibleSecondaryLinks,
    },
    {
      name: 'style',
      text: 'Profile',
      possibleSecondaryLinks: [
        {
          name: KEPT_ITEMS,
          text: 'Your Items',
          location: '/my-items/gallery',
          canonical: 'kept_items',
        },
        {
          name: STYLE_PROFILE,
          text: 'Style Profile',
          location: '/style/profile',
          canonical: 'style_profile',
        },
        {
          name: STYLE_QUIZZES,
          text: 'Style Quizzes',
          location: '/profile/quizzes',
          canonical: 'style_quizzes',
        },
      ],
    },
  ];
};

const getActivePrimaryLinkName = (
  client: Client,
  activePage: string | undefined,
): string | null => {
  if (!activePage) return null;
  if (activePage === STYLED_FIX) return 'fixes';
  const possibleLinks = getPossibleLinks(client);

  const matchingPrimaryLink = possibleLinks.find(
    primaryLink =>
      primaryLink.name === activePage ||
      primaryLink.possibleSecondaryLinks.some(
        secondaryLink => secondaryLink.name === activePage,
      ),
  );

  return matchingPrimaryLink ? matchingPrimaryLink.name : null;
};

const getActivePageCanonical = (
  client: Client,
  activePageName: string | undefined,
): string | undefined => {
  if (!activePageName) return undefined;
  if (activePageName === 'home') return 'homepage';
  if (activePageName === SAVED_ITEMS) return 'saved_items';

  let matchingLinkCanonical;
  const possibleLinks = getPossibleLinks(client);

  for (const primaryLink of possibleLinks) {
    for (const secondaryLink of primaryLink.possibleSecondaryLinks) {
      if (secondaryLink.name === activePageName) {
        matchingLinkCanonical = secondaryLink.canonical;
      }
    }
  }

  return matchingLinkCanonical;
};

const isSecondaryLinkActionable = (
  client: Client,
  secondaryLinkName: string,
  region: Region,
) => {
  const showHomeInPrimaryNav = isClientEligibleForHome(client, region);
  const hasCompletedOnboarding = !client.capabilities.needsOnboarding;
  const canShop = client.capabilities.canShop;

  const canAccessFixInspirationBoard =
    hasCompletedOnboarding &&
    client.businessLine === 'WOMENS' &&
    region === REGION.US;

  const canAccessStyleShuffleShop = isClientEligibleForCategories(
    client,
    region,
  );

  const canAccessKeptItems =
    hasCompletedOnboarding &&
    (client.businessLine === 'WOMENS' || client.businessLine === 'MENS') &&
    !showHomeInPrimaryNav;

  const canAccessYourLooks =
    hasCompletedOnboarding &&
    canShop &&
    (client.businessLine === 'WOMENS' || client.businessLine === 'MENS');

  const canAccessStyleQuizzes =
    hasCompletedOnboarding &&
    region === REGION.US &&
    client.businessLine === 'WOMENS' &&
    !showHomeInPrimaryNav;

  switch (secondaryLinkName) {
    case YOUR_FIX:
      return hasCompletedOnboarding;
    case FIX_INSPIRATION:
      return canAccessFixInspirationBoard;
    case YOUR_LOOKS:
      return canAccessYourLooks;
    case NEW_COLORS:
      return hasCompletedOnboarding && canShop;
    case PAST_FIXES:
      return hasCompletedOnboarding;
    case KEPT_ITEMS:
      return canAccessKeptItems;
    case STYLE_SHUFFLE_SHOP:
      return canAccessStyleShuffleShop;
    case STYLE_QUIZZES:
      return canAccessStyleQuizzes;
    default:
      return false;
  }
};

interface ActionablePrimaryLink extends LinkBase {
  actionableSecondaryLinks: SecondaryLink[];
}

// returns only the primary and secondary nav links which are actionable to the client e.g.
// {
//   name: 'fixes',
//   text: 'Fixes',
//   location: '/',
//   actionableSecondaryLinks: [
//     { name: 'your-fix', text: 'Their Fix', location: '/your-fix' },
//     { name: 'inspiration', text: 'Inspiration', location: '/inspiration' },
//   ],
// },
// {
//   name: 'style',
//   text: 'Style',
//   location: '/style/profile',
//   actionableSecondaryLinks: [
//     { name: 'style-profile', text: 'Style Profile', location: '/style/profile' },
//   ],
// },
// Note that the location for the primary link location is set to the match the location of the first actionable secondary child link.
const getActionableLinksIgnoringActivePage = (
  client: Client,
  region: Region,
) => {
  const links: ActionablePrimaryLink[] = [];
  const showHomeInPrimaryNav = isClientEligibleForHome(client, region);

  for (const link of getPossibleLinks(client)) {
    const actionableSecondaryLinks = link.possibleSecondaryLinks.filter(
      secondaryLink =>
        isSecondaryLinkActionable(client, secondaryLink.name, region),
    );

    const showHomeLink = showHomeInPrimaryNav && link.name === 'home';

    if (showHomeLink || A.isNotEmpty(actionableSecondaryLinks))
      links.push({
        name: link.name,
        text: link.text,
        location: link.location || actionableSecondaryLinks[0].location,
        actionableSecondaryLinks,
      });
  }

  return links;
};

export interface ActionableLink extends LinkBase {
  active: boolean;
}

interface ActionableNavLinks {
  actionablePrimaryLinks: ActionableLink[];
  actionableSecondaryLinks: ActionableLink[];
}

/**
 * Returns the same as getActionableLinksIgnoringActivePage, but also marks
 * active pages as `active: true`
 */
const getActionableLinks = (
  client: Client,
  activePage: string | undefined,
  region: Region,
): ActionableNavLinks => {
  const actionableLinks = getActionableLinksIgnoringActivePage(client, region);
  const activePrimaryLinkName = getActivePrimaryLinkName(client, activePage);

  const actionablePrimaryLinks = actionableLinks.map(primaryLink => {
    const link = {
      name: primaryLink.name,
      text: primaryLink.text,
      location: primaryLink.location,
      active: primaryLink.name === activePrimaryLinkName,
    };

    return link;
  });

  const activePrimaryLink = actionableLinks.find(
    primaryLink => primaryLink.name === activePrimaryLinkName,
  );

  const actionableSecondaryLinks = activePrimaryLink
    ? activePrimaryLink.actionableSecondaryLinks.map(secondaryLink => {
        const link = {
          name: secondaryLink.name,
          text: secondaryLink.text,
          location: secondaryLink.location,
          active: activePage === secondaryLink.name,
        };

        return link;
      })
    : [];

  return {
    actionablePrimaryLinks,
    actionableSecondaryLinks,
  };
};

export {
  isClientEligibleForHome,
  isClientEligibleForSaveForLater,
  getActivePageCanonical,
};
export default getActionableLinks;

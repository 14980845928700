import React, { useEffect, useState } from 'react';
import { useBelowBreakpoint } from '@stitch-fix/mode-react';
import { useSelectEvent } from '@stitch-fix/event-reporter';
import SearchButton from './SearchButton';
import SearchInput from './SearchInput';
// Legacy V1 Product Tracking Events:
import { trackSearchIconClick, trackSearchSubmit } from './events';
import styles from './style.module.scss';

interface SearchProps {
  activePageCanonical?: string | null;
  isPrimaryNavVisible: boolean;
}

const Search = ({ activePageCanonical, isPrimaryNavVisible }: SearchProps) => {
  const [isInputOpen, setIsInputOpen] = useState(false);
  const isBelowDesktop = useBelowBreakpoint(768);

  /**
   * we don't want an old open state hanging around if the nav reenters or the
   * screen size changes
   */
  useEffect(() => setIsInputOpen(false), [isPrimaryNavVisible, isBelowDesktop]);

  const { reportSelectEvent } = useSelectEvent();

  const trackSubmit = () => {
    // Legacy V1 Event:
    trackSearchSubmit(activePageCanonical);
    // V2 Event:
    reportSelectEvent({
      schema: 'select',
      name: 'search',
      action_name: 'submit',
    });
  };

  return (
    <>
      {isBelowDesktop && (
        <div className={styles['search-icon-container']}>
          <SearchButton
            onClick={() => {
              if (!isInputOpen) {
                trackSearchIconClick(activePageCanonical);
              }
              setIsInputOpen(!isInputOpen);
            }}
          />
        </div>
      )}
      <SearchInput
        onClose={() => setIsInputOpen(false)}
        onSubmit={trackSubmit}
        isOpen={isInputOpen}
        isBelowDesktop={isBelowDesktop}
        hasAccessToRecentSearches
      />
    </>
  );
};

export default Search;

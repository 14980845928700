import { gql } from '@apollo/client';

export const GET_RECENT_SEARCHES = gql`
  query getRecentSearches {
    shopContent {
      id
      recentSearches {
        id
        label
      }
    }
  }
`;

export type FreestyleRecentSearch = {
  __typename?: 'FreestyleRecentSearch';
  id: string;
  label: string;
};

export type GetRecentSearchesQuery = {
  __typename?: 'Query';
  shopContent?: {
    __typename?: 'ShopContent';
    id: string;
    recentSearches: FreestyleRecentSearch[];
  } | null;
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  IconButton,
  IconCloseX16,
  IconSearch16,
  Link,
  Text,
  useScreenSize,
} from '@stitch-fix/mode-react';
import { Combobox, ComboboxInput } from '@reach/combobox';
import classNames from 'classnames/bind';
import SearchDropdown from '../SearchDropdown';
import SearchModal from './SearchModal';
import styles from './style.module.scss';
import { getWindow } from '../../../../../../helpers/getBrowserGlobals';

const cx = classNames.bind(styles);

interface SearchInputProps {
  hasAccessToRecentSearches?: boolean;
  isOpen: boolean;
  isBelowDesktop: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const SEARCH_ROUTE = 'search';

/**
 * this function handles redirection on search submission. if a client
 * is within the search results route it fires a callback to the search
 * results SPA to update the search query without a refresh. otherwise
 * it fires a plain 'ol redirect that initiates a full page refresh.
 */
const redirectToSearchResults = (searchTerm: string) => {
  const location = getWindow()?.location;
  const onSearch = getWindow()?.stitchfix?.onSearch;
  const isFunction = typeof onSearch === 'function';
  const isMatchingPathname = location?.pathname === `/${SEARCH_ROUTE}`;

  if (isFunction && isMatchingPathname) {
    onSearch(searchTerm);
  } else {
    location?.assign(`/${SEARCH_ROUTE}?q=${encodeURIComponent(searchTerm)}`);
  }
};

const getInitialSearchTermValue = () => {
  const params = new URLSearchParams(getWindow()?.location.search);

  return params.get('q');
};

/**
 * The `SearchInput` component renders a search input field
 * along with an accompanying dropdown menu.
 *
 * On small screens, < 560px, the input field and dropdown menu are
 * simply rendered in a mode-react `Modal` component.
 *
 * On non-small screens, `SearchInput` implements "@reach/combobox" to
 * facilitate making the dropdown menu accessible.
 * See more: https://reach.tech/combobox/
 */
const SearchInput = ({
  hasAccessToRecentSearches = false,
  isOpen,
  isBelowDesktop,
  onClose,
  onSubmit,
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  // Ideally, we'd like to use the `isExpanded` value provided by Combobox to trigger
  // the recent searches query, but we don't render ComboboxPopover on small screens,
  // so we need to track our own state to trigger the query once a client focuses the search input.
  const [shouldLoadRecentSearches, setShouldLoadRecentSearches] =
    useState(false);
  const [value, setValue] = useState(() => getInitialSearchTermValue() || '');
  const screenSize = useScreenSize();
  const [dropdownIsDisplaying, setDropdownIsDisplaying] = useState(false);
  const isSmallScreen = screenSize === 'sm';
  const isHidden = isBelowDesktop && !isOpen;

  useEffect(() => {
    if (inputRef.current && isBelowDesktop && isOpen) {
      inputRef.current.focus();
    }
  }, [isBelowDesktop, inputRef, isOpen]);

  const handleSubmit = useCallback(
    (v: string) => {
      onSubmit();
      redirectToSearchResults(v);
      setValue(v);
      setDropdownIsDisplaying(false);
      onClose();
    },
    [onSubmit, onClose],
  );

  const handleOnClearHistory = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const setOnFocus = () => {
    setShouldLoadRecentSearches(true);
    setDropdownIsDisplaying(true);
  };

  return (
    <SearchModal isOpen={isOpen} isSmallScreen={isSmallScreen}>
      <Combobox
        aria-label="search"
        className={styles['search-bar-container']}
        style={{ display: isHidden ? 'none' : '' }}
        onSelect={handleSubmit}
        openOnFocus
      >
        {/**
         * why the <form> element?
         *
         * along with the `action` property and `type="search"` on the input
         * it helps to enable the fancy "search" button on native keyboards
         *
         * source: https://www.paddingleft.com/2019/09/18/Show-Search-on-mobile-devices-keyboard/
         */}
        <form
          className={styles['search-input-group']}
          action=""
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(value);
          }}
        >
          <ComboboxInput
            className={cx('search-input', {
              'has-value': !!value,
            })}
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder="Search items, brands, or styles"
            ref={inputRef}
            type="search"
            autoFocus={isBelowDesktop && isOpen}
            onFocus={setOnFocus}
          />

          <span className={styles['search-icon-button']}>
            <IconButton
              data-testid="search-button"
              aria-label="execute search"
              onClick={() => {
                if (value) handleSubmit(value);
              }}
            >
              <IconSearch16 />
            </IconButton>
          </span>

          {value && (
            <span className={styles['search-clear-button']}>
              <IconButton
                aria-label="clear search input"
                onClick={() => {
                  setValue('');
                  inputRef?.current?.focus();
                }}
              >
                <IconCloseX16 />
              </IconButton>
            </span>
          )}
        </form>

        {isBelowDesktop && (
          <span className={styles['search-close-button']}>
            <Text height="standard" family="medium" scale="3">
              <Link
                as="button"
                type="button"
                aria-label="close search input"
                onClick={onClose}
                underline="inverse"
                variant="inherit"
              >
                Cancel
              </Link>
            </Text>
          </span>
        )}

        {hasAccessToRecentSearches && dropdownIsDisplaying && (
          <div className={styles['search-dropdown']}>
            <SearchDropdown
              isSmallScreen={isSmallScreen}
              onSelect={handleSubmit}
              onClearHistory={handleOnClearHistory}
              shouldLoadRecentSearches={shouldLoadRecentSearches}
            />
          </div>
        )}
      </Combobox>
    </SearchModal>
  );
};

export default SearchInput;

import React from 'react';
import {
  IconSocialFacebook16,
  IconSocialInstagram16,
  IconSocialPinterest16,
  IconSocialTiktok16,
  IconSocialTwitter16,
  IconSocialYoutube16,
} from '@stitch-fix/mode-react';

interface UKSocial {
  brand: string;
  title: string;
  icon: React.ReactNode;
  linkHref: string;
}

const UKSocials: UKSocial[] = [
  {
    brand: 'facebook',
    title: 'The Stitch Fix Facebook Page',
    icon: <IconSocialFacebook16 title="Facebook" />,
    linkHref: 'https://www.facebook.com/stitchfixuk',
  },
  {
    brand: 'instagram',
    title: 'Stitch Fix On Instagram',
    icon: <IconSocialInstagram16 title="Instagram" />,
    linkHref: 'https://www.instagram.com/stitchfixuk',
  },
  {
    brand: 'pinterest',
    title: 'The Stitch Fix Pinterest Page',
    icon: <IconSocialPinterest16 title="Pinterest" />,
    linkHref: ' https://www.pinterest.com/stitchfix',
  },
  {
    brand: 'twitter',
    title: 'Stitch Fix on Twitter - @stitchfix',
    icon: <IconSocialTwitter16 title="Twitter" />,
    linkHref: 'https://www.twitter.com/stitchfixuk',
  },
  {
    brand: 'tiktok',
    title: 'The Stitch Fix TikTok Page',
    icon: <IconSocialTiktok16 title="TikTok" />,
    linkHref: 'https://www.tiktok.com/@stitchfix',
  },
  {
    brand: 'youtube',
    title: 'The Stitch Fix YouTube Page',
    icon: <IconSocialYoutube16 title="YouTube" />,
    linkHref: 'https://www.youtube.com/stitchfix',
  },
];

export default UKSocials;

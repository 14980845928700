import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import {
  IconCloseX24,
  IconMenuStacks24,
  ResponsiveSection,
  Button,
  LogoBrand2024Navy,
} from '@stitch-fix/mode-react';
import cookies from 'browser-cookies';
import GlobalShoppingBag from '../GlobalShoppingBag';
import { KnitLink } from '../../KnitLink';
import BusinessLineNav from './BusinessLines';
import MobileDropDownMenu from './MobileDropDownMenu';
import OtherLinks from './OtherLinks';
import RegionBanner from './RegionBanner';
import HeaderLayout from '../HeaderLayout';
import { LegacySiteWideBanner, LegacySlimBanner } from '../Banners/BannerTypes';
import styles from './style.module.scss';
import { featureIsAvailable, getRegion } from '../../../i18n';
import { NavContext } from '../../NavContextWrapper';
import { useUngatedShoppingBag } from '../../../helpers/useUngatedShoppingBag';
import { Region } from '../../../constants';
import { useAbsoluteUrl } from '../../KnitLink/useAbsoluteUrl';

export interface InternalLoggedOutHeaderProps {
  banner?: LegacySiteWideBanner;
  slimBanner?: LegacySlimBanner;
  /**
   * If included, this is the URL the user will be redirected to after a successful login action.
   */
  loginRedirectUrl?: string;
}

const cx = classNames.bind(styles);

export const REGION_BANNER_COOKIE = {
  name: 'regionBanner',
  hideValue: 'true',
  expires: 365 * 24,
};

/**
 * Country codes for regions that can display a region banner
 */
const REGION_COUNTRY_CODES = {
  US: ['GB'],
  UK: ['US'],
};

const useRegionBannerState = (region: Region): [string, () => void] => {
  // states: loading, displayed, hidden
  const [displayState, setDisplayState] = useState('loading');
  const { visitor } = useContext(NavContext);
  const countryCode = visitor?.countryCode;

  useEffect(() => {
    // when we have a `countryCode` (from the gql query's `visitor.countryCode`), use it to
    // calculate whether or not the region banner should show. it shows when the user is in one
    // country, but the TLD they're on is for another. we also check to make sure they haven't
    // already hidden the region banner before
    if (countryCode) {
      const shouldRender =
        cookies.get(REGION_BANNER_COOKIE.name) !==
          REGION_BANNER_COOKIE.hideValue &&
        REGION_COUNTRY_CODES[region].includes(countryCode);

      setDisplayState(shouldRender ? 'displayed' : 'hidden');
    }
  }, [region, countryCode]);

  const onHide = () => {
    cookies.set(REGION_BANNER_COOKIE.name, REGION_BANNER_COOKIE.hideValue, {
      expires: REGION_BANNER_COOKIE.expires,
    });

    setDisplayState('hidden');
  };

  return [displayState, onHide];
};

const InternalLoggedOutHeader = ({
  banner: customBanner,
  slimBanner,
  loginRedirectUrl,
}: InternalLoggedOutHeaderProps) => {
  const region = getRegion();
  const absoluteUrl = useAbsoluteUrl();

  const [navOpen, setNavOpen] = useState(false);
  const ungatedShoppingBag = useUngatedShoppingBag();
  const [regionBannerState, onRegionBannerHide] = useRegionBannerState(region);

  const canRenderCustomBanner = regionBannerState === 'hidden';

  const toggleIcon = navOpen ? (
    <IconCloseX24 purpose="decorative" data-testid="close-icon" />
  ) : (
    <IconMenuStacks24 purpose="decorative" data-testid="menu-icon" />
  );

  return (
    <HeaderLayout
      banner={canRenderCustomBanner ? customBanner : undefined}
      slimBanner={slimBanner}
      suppressBanners={false}
    >
      <div
        className={cx('header-shell', { open: navOpen })}
        data-testid="logged-out-header"
      >
        {regionBannerState === 'displayed' && (
          <RegionBanner onHide={onRegionBannerHide} />
        )}
        <ResponsiveSection width="full-bleed" gutter="none" height="100%">
          <nav className={styles.nav}>
            <div className={styles['nav-links']}>
              {/* Small screen hamburger and logo */}
              <button
                onClick={() => {
                  setNavOpen(prevState => !prevState);
                }}
                className={cx('home-toggle')}
                data-testid="home-toggle"
                type="button"
                aria-label={navOpen ? 'close menu' : 'open menu'}
              >
                <div className={styles.icon}>{toggleIcon}</div>
              </button>

              {/* Large screen home link logo */}
              <KnitLink
                data-ga="nav-primary-gateway"
                className={cx('home-link')}
                to="/"
              >
                <div className={styles.logotype}>
                  <LogoBrand2024Navy title="Stitch Fix" data-testid="logo" />
                </div>
              </KnitLink>

              <div className={cx('business-line-container')}>
                <BusinessLineNav />
              </div>

              <div className={cx('bag-container')}>
                <GlobalShoppingBag
                  count={ungatedShoppingBag.totalQuantity}
                  hideIf={count =>
                    count === 0 || !featureIsAvailable('shopping_bag')
                  }
                />
              </div>

              <div className={cx('align-right')}>
                <div className={cx('other-links-container')}>
                  <OtherLinks flushRight={false} />
                </div>

                {featureIsAvailable('sign_in') && (
                  <div className={cx('signin-button-container')}>
                    <Button
                      variant="text"
                      data-ga="nav-primary-login"
                      data-testid="sign-in-button"
                      as="a"
                      href={absoluteUrl(
                        loginRedirectUrl
                          ? `/login?ret=${encodeURIComponent(loginRedirectUrl)}`
                          : '/login',
                      )}
                      width="fit"
                    >
                      Sign In
                    </Button>
                  </div>
                )}
                <div className={cx('signup-button-container')}>
                  <Button
                    variant="filled-accent-slim"
                    data-ga="nav-signup"
                    data-testid="sign-up-button"
                    as="a"
                    href={absoluteUrl('/signup')}
                    width="fit"
                  >
                    Take your style quiz
                  </Button>
                </div>
              </div>
            </div>

            {/* Small screen expanding site navigation */}
            <div
              className={cx(
                'site-nav-expanding',
                navOpen ? 'is-open' : 'is-closed',
              )}
              data-testid="site-nav-expanding"
            >
              <MobileDropDownMenu />
            </div>
          </nav>
        </ResponsiveSection>
      </div>
    </HeaderLayout>
  );
};

export default InternalLoggedOutHeader;

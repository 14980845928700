import React from 'react';
import classNames from 'classnames';
import { InheritableElementProps } from '../_internal/components';
import { MarginProps } from '../_internal/spacing';
import Box, { BoxProps } from '../Box';
import {
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';

import styles from './divider.module.scss';

interface BaseProps extends DeprecatedAndDangerousStylingProps {
  /**
   * The type of divider to display
   *
   * @default 'section'
   */
  variant?: 'section' | 'statement' | 'item' | 'subtle';
  /**
   * The color of divider to display
   *
   * @default 'gray'
   */
  color?: 'gray' | 'light-gray' | 'navy';
  /**
   * Markup (typically text) to include with the divider to make it a text divider
   */
  children?: React.ReactNode;
}

type Props = BaseProps & MarginProps;

export type DividerProps = InheritableElementProps<'hr', Props>;

/**
 * Dividers break up a layout by using decorative elements to visually separate areas of content. They are shown here in a hierarchy, but context should dictate how (or whether) to apply a divider.
 */
const Divider = ({
  variant = 'section',
  color = 'gray',
  children,
  ...rootProps
}: DividerProps) => {
  const as = children ? 'div' : 'hr';
  const role = as !== 'hr' ? 'separator' : undefined;
  const className = classNames(styles[variant], styles[color], {
    [styles['with-text']]: children,
  });

  const processedRootProps = processStylingProps(rootProps, 'Divider', {
    stylingProps: 'warn',
    dangerousStylingProps: 'rewrite',
  }) as BoxProps;

  return (
    <Box as={as} role={role} className={className} {...processedRootProps}>
      {children}
    </Box>
  );
};

export default Divider;

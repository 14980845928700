export const regionalFeatures = {
  US: [
    'affiliates',
    'big_tall',
    'business_casual',
    'gift_cards',
    'footer_faq',
    'jeans',
    'kids',
    'maternity',
    'men',
    'mens_blog',
    'petite',
    'plus',
    'shopping_bag',
    'sign_in',
    'social_impact',
    'style_guides',
    'womens_blog',
    'women',
  ],
  UK: ['cookie_info', 'social_buttons_uk', 'returns'],
} as const;

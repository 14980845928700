import React from 'react';
import classNames from 'classnames/bind';
import { featureIsAvailable, getRegion } from '../../../../i18n';
import { isOnGiftsPath, isOnSupportPath } from '../../../../helpers/pathHelper';
import useCurrentPath from '../../../../helpers/useCurrentPath';
import PrimaryLink from '../../PrimaryLink';
import styles from './style.module.scss';
import { regionalUrls } from '../../../../i18n/regionalUrls';
import useCurrentHost from '../../../../helpers/useCurrentHost';

const cx = classNames.bind(styles);

type OtherLinksProps = {
  flushRight?: boolean;
};

const OtherLinks = ({ flushRight }: OtherLinksProps) => {
  const currentPath = useCurrentPath();
  const region = getRegion();
  const currentHost = useCurrentHost();
  const isHelpShown = currentHost !== 'support.stitchfix.co.uk';

  return (
    <div
      className={cx(styles.container, {
        flushRight,
      })}
    >
      <ul className={styles.items}>
        {isHelpShown && (
          <li>
            <PrimaryLink
              data-ga="nav-primary-faq"
              to={regionalUrls[region].help}
              active={isOnSupportPath(currentPath)}
            >
              Help
            </PrimaryLink>
          </li>
        )}
        {featureIsAvailable('gift_cards') && (
          <li>
            <PrimaryLink
              data-ga="nav-primary-gifts"
              to="/gifts"
              active={isOnGiftsPath(currentPath)}
            >
              Gift cards
            </PrimaryLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default OtherLinks;

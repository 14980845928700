import React from 'react';
import { getWindow } from './getBrowserGlobals';

const useCurrentHost = () => {
  const [currentHost, setHost] = React.useState('');

  React.useEffect(() => {
    const host = getWindow()?.location.host;

    if (host !== undefined) {
      setHost(host);
    }
  }, []);

  return currentHost;
};

export default useCurrentHost;

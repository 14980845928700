import React from 'react';
import classNames from 'classnames/bind';
import BrandLink from './BrandLink';
import PrimaryNav from './PrimaryNav';
import MyAccount from './MyAccount';
import SecondaryNav from './SecondaryNav';
import HeaderLayout from '../HeaderLayout';
import type {
  LegacySiteWideBanner,
  LegacySlimBanner,
} from '../Banners/BannerTypes';
import { useHeaderLogic } from './helpers/useHeaderLogic';
import getActionableLinks, {
  isClientEligibleForHome,
  isClientEligibleForSaveForLater,
  getActivePageCanonical,
} from './helpers/getActionableLinks';
import { SEARCH_PORTAL_ID } from './MyAccount/Search/SearchDropdown/DropdownOverlay';
import { HeaderShellContext } from './helpers/HeaderShellContext';
import { getRegion } from '../../../i18n';
import type { Client } from '../../NavContextWrapper/types';
import styles from './style.module.scss';
import { isOnGatewayPath } from '../../../helpers/pathHelper';
import useCurrentPath from '../../../helpers/useCurrentPath';

interface LoggedInHeaderProps {
  client: Client;
  activePage?: string;
  banner?: LegacySiteWideBanner;
  slimBanner?: LegacySlimBanner;
  suppressBanners?: boolean;
  disableHomeLink?: boolean;
  stickyId?: string;
  stickyOffset?: number;
  suppressFreestyleFeatureNav?: boolean;
  suppressNavLinks?: boolean;
}

const cx = classNames.bind(styles);

const LoggedInHeader = ({
  client,
  activePage,
  banner,
  slimBanner,
  suppressBanners = false,
  disableHomeLink = false,
  stickyId,
  stickyOffset = 0,
  suppressFreestyleFeatureNav = false,
  suppressNavLinks = false,
}: LoggedInHeaderProps) => {
  const region = getRegion();
  const currentPath = useCurrentPath();
  const { actionablePrimaryLinks, actionableSecondaryLinks } =
    getActionableLinks(client, activePage, region);
  const activePageCanonical = getActivePageCanonical(client, activePage);
  const showSaveForLater = isClientEligibleForSaveForLater(client, region);
  const showSecondaryNav = actionableSecondaryLinks.length > 1;

  const { headerElRef, setStickiness, isPrimaryNavVisible, headerShellRef } =
    useHeaderLogic({
      stickyId,
      stickyOffset,
    });

  const hrefForBrandLink = isClientEligibleForHome(client, region)
    ? '/home'
    : '/';

  const isBrandLinkActive =
    hrefForBrandLink === '/'
      ? isOnGatewayPath(currentPath)
      : activePageCanonical === 'homepage';

  return (
    <HeaderShellContext.Provider value={headerShellRef.current}>
      <HeaderLayout
        ref={headerElRef}
        banner={banner}
        slimBanner={slimBanner}
        suppressBanners={suppressBanners}
      >
        <div
          className={styles['header-shell']}
          ref={headerShellRef}
          data-testid="logged-in-header"
        >
          <nav
            className={cx('primary-nav', {
              closed: !isPrimaryNavVisible,
            })}
            onTransitionEnd={setStickiness}
            data-testid="primary-nav"
            aria-label="primary"
          >
            <div className={styles['nav-section']}>
              <BrandLink
                href={hrefForBrandLink}
                disabled={disableHomeLink}
                active={isBrandLinkActive}
              />
            </div>

            <div className={cx('nav-section', 'nav-section--links')}>
              {!suppressNavLinks && (
                <PrimaryNav links={actionablePrimaryLinks} />
              )}
            </div>

            <div className={cx('nav-section', 'nav-section--links')}>
              <MyAccount
                activePageCanonical={activePageCanonical}
                client={client}
                isPrimaryNavVisible={isPrimaryNavVisible}
                showSaveForLater={
                  !suppressFreestyleFeatureNav && showSaveForLater
                }
                suppressFreestyleFeatureNav={suppressFreestyleFeatureNav}
                suppressKeptItemAndOrderLinks={suppressFreestyleFeatureNav}
                suppressReferralLink={suppressNavLinks}
              />
            </div>
          </nav>

          <div id="account-dropdown-portal" />

          <div id={SEARCH_PORTAL_ID} />
          {!suppressNavLinks && showSecondaryNav && (
            <SecondaryNav links={actionableSecondaryLinks} />
          )}
        </div>
      </HeaderLayout>
    </HeaderShellContext.Provider>
  );
};

export default LoggedInHeader;

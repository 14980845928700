import React from 'react';
import { ResponsiveImageSources } from './types';
import {
  convertAliases,
  sortSourcesKeys,
  generateSourcesProps,
  generateSrcSet,
  validateSources,
} from './ResponsiveImageHelpers';
import { wasServerSideRendered } from '../_internal/ssr';
import styles from './responsiveImage.module.scss';

export interface ResponsiveImageProps {
  /**
   * The alt text for the image
   */
  alt: string;
  /**
   * Allows specifying image sources at one, or multiple screen sizes.
   * Mode-React's default screen sizes include `sm`, `md`, `lg`, `xl`, `xxl`.
   *
   * Recommended: always define an image source for the `sm` screen size.
   */
  sources: ResponsiveImageSources;
  /**
   * Configures [lazy loading](https://web.dev/browser-level-image-lazy-loading/)
   * behavior in supported browsers. If `true`, `loading="lazy"` will be passed
   * to the underlying `<img />`.
   *
   * Lazy image loading is generally a good idea in SPAs. It reduces useless
   * image fetching and could help performance in some cases. But, when static
   * HTML is served to browsers, it is better to turn off lazy loading by default.
   * This is because browsers have special optimizations they perform when parsing
   * HTML. While the main thread is busy with JS parsing, etc, a separate thread
   * can begin fetching resources that will be needed later, like images.
   *
   * The rule of thumb should be:
   *
   * - Is it a SPA?
   *   - Yes: Lazy
   *   - No:
   *     - Is the image likely to be above the fold?
   *       - Yes: Not lazy
   *       - No: Lazy
   *
   * The default value is true in browser-rendered SPAs, but false when the page
   * was server-side rendered.
   */
  lazy?: boolean;
  /**
   * Callback for when the image loads.
   */
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  /**
   * css Property for `object-fit`
   * @default 'contain'
   */
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  /**
   * css property for `object-position`
   */
  objectPosition?: string;
  /**
   * Configures [fetchpriority](https://web.dev/fetch-priority) behavior in supported browsers.
   *
   * The fetchPriority property provides a hint to the browser on how it should prioritize the fetch of the image relative to other images.
   *
   * - `"high"`: fetch the image at a high priority relative to other images.
   * - `"low"`: fetch the image at a low priority relative to other images.
   * - `"auto"`: default mode, which indicates no preference for the fetch priority (the browser decides what is best for the user).
   */
  fetchPriority?: 'high' | 'low' | 'auto';
  /** Optional width in pixels for the image element */
  width?: number;
  /** Optional height in pixels for the image element */
  height?: number;
}

/** This is intended to detect SSR vs SPA */
const lazyDefault = () => {
  return !wasServerSideRendered();
};

/**
 * Use `ResponsiveImage` to output a picture element.
 *
 * Picture elements help optimize images for a client's:
 *
 * - Screen size
 * - Device pixel ratio (DPR)
 * - Browser image format support
 */
const ResponsiveImage = ({
  alt,
  sources,
  lazy = lazyDefault(),
  onLoad,
  objectFit = 'contain',
  objectPosition,
  fetchPriority,
  width,
  height,
}: ResponsiveImageProps) => {
  // TypeScript is already verifying the type of sources object, but for those
  // not using TypeScript, we need to verify the sources prop
  if (process.env.NODE_ENV !== 'production') {
    validateSources(sources);
  }

  const { base: baseSource, ...screenSizeSources } = convertAliases(sources);
  const sortedScreenSizes = sortSourcesKeys(screenSizeSources);
  const largestScreenSize = sortedScreenSizes[0];
  const fallbackSource = largestScreenSize
    ? screenSizeSources[largestScreenSize]
    : baseSource;
  const fallbackSrc = fallbackSource['1x'];
  // Generate source props in descending order
  const sourcesProps = sortedScreenSizes
    .flatMap(screenSize =>
      generateSourcesProps(screenSizeSources[screenSize], screenSize),
    )
    .concat(generateSourcesProps(baseSource));

  return (
    <picture
      className={styles.picture}
      data-testid="responsive-image"
      style={{ objectPosition, objectFit }}
    >
      {sourcesProps.map(({ type, source, media }) => (
        <source
          key={`${media}${type || ''}`}
          media={media}
          srcSet={generateSrcSet(source)}
          type={type || undefined}
        />
      ))}
      <img
        className={styles.image}
        alt={alt}
        src={fallbackSrc}
        loading={lazy ? 'lazy' : undefined}
        onLoad={onLoad}
        width={width}
        height={height}
        // @ts-expect-error fetchpriority hasn't been added to the React types yet
        // see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/63178 for more information
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority={fetchPriority}
      />
    </picture>
  );
};

export default ResponsiveImage;

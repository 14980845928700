import React, { Fragment } from 'react';
import { ActionableLink } from '../helpers/getActionableLinks';
import PrimaryLink from '../../PrimaryLink';
import styles from './style.module.scss';

interface PrimaryNavProps {
  links: ActionableLink[];
}

const PrimaryNav = ({ links }: PrimaryNavProps) => {
  return (
    <ul className={styles['primary-nav-links']} data-testid="primary-nav-links">
      {links.map(link => (
        <Fragment key={link.text}>
          <li
            className={styles['primary-nav-link']}
            data-testid={link.active ? 'primary-active-link' : ''}
          >
            <PrimaryLink
              active={link.active}
              data-ga={`meganav-primary-${link.name}`}
              to={link.location}
            >
              {link.text}
            </PrimaryLink>
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

export default PrimaryNav;

import React from 'react';
import {
  Box,
  IconLikeHeartActive24,
  IconLikeHeartInactive24,
  Text,
} from '@stitch-fix/mode-react';
import PrimaryLink from '../../../PrimaryLink';
import styles from './style.module.scss';

interface GlobalSavedItemsProps {
  activePageCanonical?: string;
}

const GlobalSavedItems = ({ activePageCanonical }: GlobalSavedItemsProps) => {
  const onSavedItemsPage = activePageCanonical === 'saved_items';

  // url param "ic=canonical" is for click tracking ("ic" = "icon click")
  const queryParams = activePageCanonical
    ? `?ic=${activePageCanonical}`
    : '?ic=other';

  return (
    <div className={styles['saved-items']} data-testid="saved-items">
      <PrimaryLink
        to={`/wardrobe/saved-items${queryParams}&showAvailableItems=true&page=1`}
        active={onSavedItemsPage}
        aria-label="Saved Items"
      >
        {onSavedItemsPage ? (
          <IconLikeHeartActive24 purpose="decorative" />
        ) : (
          <IconLikeHeartInactive24 purpose="decorative" />
        )}
        <Box className={styles['saved-label']}>
          <Text as="span">Saved</Text>
        </Box>
      </PrimaryLink>
    </div>
  );
};

export default GlobalSavedItems;

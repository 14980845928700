import { useEffect, useState } from 'react';
import { type DocumentNode } from 'graphql';
import { sha1 } from 'js-sha1';
import { getWindow } from '../../helpers/getBrowserGlobals';

type LocalStorageQuery<T> = {
  data: T;
  querySha: string;
};

const getLocalStorageData = <T>(
  key: string,
): LocalStorageQuery<T> | undefined => {
  const cached = getWindow()?.localStorage.getItem(key);

  return cached && JSON.parse(cached);
};

const hashQuery = (query: DocumentNode): string => sha1(JSON.stringify(query));

/**
 * A hook that caches the result of a query in localStorage, and returns the cached data if it's
 * available (before the fresh data is available). This is used to avoid UI flickering of the header,
 * particularly between logged-out and logged-in state, when a page first renders.
 *
 * @param key The localStorage key to use for caching
 * @param data The fresh data from the query
 * @param document The query document, which is used to invalidate the cache if the query shape changes
 */
const useCachedQuery = <T>(
  key: string,
  data: T | undefined,
  document: DocumentNode,
): T | undefined => {
  // Cached data is not used on first render, to avoid hydration errors with server-side rendering
  const [cachedData, setCachedData] = useState<T | undefined>();

  // Async, fetch the cached data from localStorage, and update state if it's valid
  useEffect(() => {
    const cache = getLocalStorageData<T>(key);

    if (!cache) return;

    const { data: dataFromLocalStorage, querySha } = cache;

    if (dataFromLocalStorage && querySha && hashQuery(document) === querySha) {
      // Only expose the cached data if the query SHA matches the current query. Otherwise, we
      // may have stale data, e.g. from a different version of knit, with a different query shape.
      setCachedData(dataFromLocalStorage);
    }
  }, [key, document]);

  // When we receive fresh `data`, update the localStorage cache
  useEffect(() => {
    if (data) {
      getWindow()?.localStorage.setItem(
        key,
        JSON.stringify({ data, querySha: hashQuery(document) }),
      );
    }
  }, [data, key, document]);

  // Always return the fresh `data` if it's available, otherwise return the cached data
  if (data) return data;

  return cachedData;
};

export default useCachedQuery;

import React from 'react';
import { SocialBrand } from './social-brand-data';

interface SocialItemProps {
  brand: SocialBrand;
  className?: string;
  children: React.ReactNode;
}

export const SocialItem = ({ brand, children, className }: SocialItemProps) => (
  <li data-testid={`social-button-${brand}`} className={className}>
    {children}
  </li>
);

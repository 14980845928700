import React from 'react';
import { getWindow } from './getBrowserGlobals';

const useCurrentPath = () => {
  const [currentPath, setPath] = React.useState('/');

  React.useEffect(() => {
    const path = getWindow()?.location.pathname;

    if (path !== undefined) {
      setPath(path);
    }
  }, []);

  return currentPath;
};

export default useCurrentPath;

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconLikeHeartActive24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M6.99289 2.86829C3.21638 2.86829 0.0767766 5.81914 0.0654551 9.61596C-0.0639 11.9384 1.1672 14.2119 3.04377 15.5455L10.6503 21.0537L10.7168 21.102C10.9844 21.2968 11.2593 21.497 11.5775 21.5785C11.8542 21.6494 12.1443 21.6494 12.421 21.5785C12.7392 21.497 13.0142 21.2968 13.2818 21.102L13.3483 21.0537L20.9548 15.5455C22.8298 14.2131 24.067 12.0569 23.9325 9.60456C23.7934 5.83459 20.6788 2.86829 17.0057 2.86829C14.9864 2.86829 13.3022 3.78699 12.0357 5.04368C10.7871 3.6768 8.9837 2.86829 6.99289 2.86829Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconLikeHeartActive24(props, ref);
});
export default ForwardRef;
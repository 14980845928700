import React, { useContext, useState, useEffect } from 'react';
import { G, S } from '@mobily/ts-belt';
import LoggedInHeader from '../LoggedInHeader';
import SimplifiedHeader from '../LoggedInHeader/SimplifiedHeader';
import InternalLoggedOutHeader, {
  InternalLoggedOutHeaderProps,
} from '../InternalLoggedOutHeader';
import { LegacySiteWideBanner, LegacySlimBanner } from '../Banners/BannerTypes';
import { SkipNavLink } from '../SkipNavLink';
import { NavContext } from '../../NavContextWrapper';
import { isSecondaryLinkPage } from '../LoggedInHeader/helpers/getActionableLinks';
import styles from './style.module.scss';

interface HeaderWrapperProps {
  activePage?: string;
  banner?: LegacySiteWideBanner;
  slimBanner?: LegacySlimBanner;
  loginRedirectUrl?: string;
  stickyId?: string;
  stickyOffset?: number;
  suppressNavLinks?: boolean;
  disableHomeLink?: boolean;
  suppressFreestyleFeatureNav?: boolean;
  suppressBanners?: boolean;
  shouldReloadOnLogoClick?: boolean;
}

const getIEBanner = (name?: string): LegacySiteWideBanner => ({
  id: 'unsupported-ie',
  variant: 'error-dark',
  message: (
    <>
      {G.isString(name) && S.isNotEmpty(name) ? `${name}, ` : ''}Internet
      Explorer is no longer a supported browser.
    </>
  ),
  cta: {
    text: 'Please switch to a supported browser',
    href: 'https://support.stitchfix.com/hc/en-us/articles/360061784433',
  },
});

const HeaderWrapper = ({
  activePage,
  banner,
  slimBanner,
  loginRedirectUrl,
  stickyId,
  stickyOffset = 0,
  suppressNavLinks = false,
  disableHomeLink = false,
  suppressFreestyleFeatureNav = false,
  suppressBanners = false,
  shouldReloadOnLogoClick = false,
}: HeaderWrapperProps) => {
  const { client, dataLoaded } = useContext(NavContext);
  const [isIE, setIsIE] = useState(false);
  const name = client?.firstName || '';
  const siteBanner = isIE ? getIEBanner(name) : banner;

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals -- inside useEffect
    const { userAgent } = window.navigator || {};

    // IE 9: Mozilla/4.0 (compatible; MSIE 9.0; Windows NT 6.1)
    // IE 10: Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2)
    // IE 11: Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko
    setIsIE(userAgent.includes('MSIE ') || userAgent.includes('Trident/'));
  }, []);

  let header = (
    <InternalLoggedOutHeader
      banner={siteBanner}
      slimBanner={slimBanner}
      loginRedirectUrl={loginRedirectUrl}
    />
  );

  if (client) {
    const shouldShowSimplifiedHeader = client.capabilities?.needsOnboarding;

    if (shouldShowSimplifiedHeader) {
      header = (
        <SimplifiedHeader
          client={client}
          banner={siteBanner}
          slimBanner={slimBanner}
          suppressBanners={suppressBanners}
          shouldReloadOnLogoClick={shouldReloadOnLogoClick}
          stickyId={stickyId}
          stickyOffset={stickyOffset}
        />
      );
    } else {
      header = (
        <LoggedInHeader
          activePage={activePage}
          client={client}
          banner={siteBanner}
          slimBanner={slimBanner}
          suppressBanners={suppressBanners}
          stickyId={stickyId}
          stickyOffset={stickyOffset}
          suppressNavLinks={suppressNavLinks}
          disableHomeLink={disableHomeLink}
          suppressFreestyleFeatureNav={suppressFreestyleFeatureNav}
        />
      );
    }
  }

  return (
    <>
      <SkipNavLink />
      <div
        className={
          !dataLoaded && activePage && isSecondaryLinkPage(activePage)
            ? styles['secondary-nav-loading-wrapper']
            : undefined
        }
      >
        {header}
      </div>
    </>
  );
};

const WrappedLoggedOutHeader = ({
  slimBanner,
  banner,
  loginRedirectUrl,
}: InternalLoggedOutHeaderProps) => {
  return (
    <>
      <SkipNavLink />
      <InternalLoggedOutHeader
        banner={banner}
        slimBanner={slimBanner}
        loginRedirectUrl={loginRedirectUrl}
      />
    </>
  );
};

export { WrappedLoggedOutHeader };
export default HeaderWrapper;

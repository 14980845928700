import React, { useEffect, useRef } from 'react';
import { G } from '@mobily/ts-belt';
import { createPortal } from 'react-dom';
import { getDocument } from '../../helpers/getBrowserGlobals';

interface DropdownPortalProps {
  children: React.ReactElement;
}

const DropdownPortal = ({ children }: DropdownPortalProps) => {
  const elRef = useRef<HTMLDivElement | undefined>();
  const doc = getDocument();

  if (!elRef.current) {
    elRef.current = doc?.createElement('div');
  }

  useEffect(() => {
    const portal = doc?.getElementById('account-dropdown-portal');

    if (G.isObject(elRef.current)) {
      portal?.appendChild(elRef?.current);
    }

    return () => {
      if (G.isObject(elRef.current)) {
        portal?.removeChild(elRef.current);
      }
    };
  });

  if (G.isObject(elRef.current)) {
    return createPortal(children, elRef.current);
  }

  return null;
};

export default DropdownPortal;

import React, { useRef } from 'react';
import { useClickOutside } from '@react-hookz/web';
import { MultiBusinessLineSocial } from './social-brand-data';
import { BusinessLineLink } from './BusinessLineLink';
import { MultiBusinessLineBrandLink } from '.';
import styles from '../style.module.scss';

interface SocialButtonProps {
  multiBusinessLineLink: MultiBusinessLineBrandLink;
  multiBusinessLineSocial: MultiBusinessLineSocial;
  onClickAway: () => void;
}

export const BusinessLineLinks = ({
  multiBusinessLineLink,
  multiBusinessLineSocial: { brand, businessLines },
  onClickAway,
}: SocialButtonProps) => {
  const ref = useRef<HTMLUListElement>(null);

  useClickOutside(ref, onClickAway);

  return (
    <ul ref={ref} className={styles['multi-business-line-links']}>
      {businessLines.map(businessLine => (
        <BusinessLineLink
          key={businessLine.name}
          brand={brand}
          businessLine={businessLine}
          multiBusinessLineLink={multiBusinessLineLink}
        />
      ))}
    </ul>
  );
};

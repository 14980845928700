import React, { ReactNode } from 'react';
import { Box } from '@stitch-fix/mode-react';
import { ComboboxPopover, useComboboxContext } from '@reach/combobox';
import ErrorBoundary from '../../../../../shared/ErrorBoundary';
import RecentSearches from './RecentSearches';
import DropdownOverlay from './DropdownOverlay';
import styles from './style.module.scss';

interface SearchDropdownProps {
  isSmallScreen: boolean;
  onClearHistory: () => void;
  onSelect: (value: string) => void;
  shouldLoadRecentSearches: boolean;
}

interface SearchDropdownContainerProps {
  children: ReactNode;
  isSmallScreen: boolean;
}

/**
 * Component used as a wrapper around `ComboboxPopover` and `DropdownOverlay`.
 * It handles rendering a background overlay on the page when the `ComboboxPopover` is expanded.
 */
const ComboboxDropdown = ({
  children,
}: Pick<SearchDropdownContainerProps, 'children'>) => {
  const { isExpanded } = useComboboxContext();

  return (
    <>
      <ComboboxPopover className={styles['reach-combobox-popover']}>
        {children}
      </ComboboxPopover>
      {isExpanded && <DropdownOverlay />}
    </>
  );
};

/**
 * Used for conditionally rendering the `SearchDropdown`'s content.
 *
 * On small screens, the dropdown content should always be displayed.
 * On non-small screens, `SearchDropdownContainer` implements `ComboboxPopover`
 * which handles showing/hiding and positioning the dropdown on the page.
 */
const SearchDropdownContainer = ({
  isSmallScreen,
  children,
}: SearchDropdownContainerProps) => {
  if (isSmallScreen) {
    return <>{children}</>;
  }

  return <ComboboxDropdown>{children}</ComboboxDropdown>;
};

/**
 * The `SearchDropdown` component displays a dropdown menu for the `SearchInput`.
 *
 * On small screens, below 560px, the search dropdown appears while the `SearchModal` is open.
 * On non-small screens, the dropdown content appears once the user focuses the search input field.
 */
const SearchDropdown = ({
  isSmallScreen,
  onSelect,
  onClearHistory,
  shouldLoadRecentSearches,
}: SearchDropdownProps) => {
  return (
    <SearchDropdownContainer isSmallScreen={isSmallScreen}>
      <Box
        className={styles['search-dropdown-content']}
        data-testid="search-dropdown"
      >
        <ErrorBoundary>
          <RecentSearches
            isSmallScreen={isSmallScreen}
            onSelect={onSelect}
            onClearHistory={onClearHistory}
            shouldLoadRecentSearches={shouldLoadRecentSearches}
          />
        </ErrorBoundary>
      </Box>
    </SearchDropdownContainer>
  );
};

export default SearchDropdown;

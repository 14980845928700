import * as GQLTypes from '../types/definitions/vendor/graphql-schema.d';

type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };
type DeepMutable<T> = { -readonly [P in keyof T]: DeepMutable<T[P]> };

type ConfigParamInput = DeepReadonly<GQLTypes.ConfigParamInput>;
type ConfigParamInputs = Readonly<ConfigParamInput[]>;
type ConfigParams = {
  name: string;
  value: string;
}[];

// Extract the `name` property from each `ConfigParamInput` in `CPI`.
type ExtractName<CPI extends ConfigParamInputs> = CPI[number]['name'];

// Extract the `expectedValues` property from `ConfigParamInput` for the given `name` `N`.
type ExtractValue<
  CPI extends ConfigParamInputs,
  Name extends ExtractName<CPI>,
> = {
  [Index in keyof CPI]: CPI[Index] extends ConfigParamInput & { name: Name }
    ? CPI[Index]['expectedValues'][number]
    : never;
}[number];

/**
 * Accepts a list of GraphQL `ConfigParamInput`s, to be passed in a query for `configParams`.
 * See: https://studio.apollographql.com/graph/sfix-kufak-eng/variant/production/schema/reference/inputs/ConfigParamsInput
 *
 * Provides a typed `getAllocation` function, to get the client's allocation value to a specific experiment.
 */
const buildConfigParamInputs = <CPI extends ConfigParamInputs>(
  configParamInputs: CPI,
) => {
  const getAllocation = <Name extends ExtractName<CPI>>(
    configParams: ConfigParams,
    name: Name,
  ): ExtractValue<CPI, Name> | null => {
    const configParam = configParams.find(param => param.name === name);
    const configParamInput = configParamInputs.find(
      param => param.name === name,
    );

    const value = configParam?.value || configParamInput?.fallbackValue;

    if (value && configParamInput?.expectedValues.includes(value)) {
      // While the GraphQL schema can't guarantee that the returned configParams adhere to the names/expectedValues we
      // request, by checking that the value is included in `expectedValues`, we can use this type coercion to ensure
      // we are only writing code against valid experiment values.
      return value as ExtractValue<CPI, Name>;
    }

    return null;
  };

  return {
    /**
     * Function to retrieve the client's allocation value to a specific experiment.
     * @param configParams List of `ConfigParam`s, e.g. from a `client.configParams` (or `visitor.configParams`) GraphQL query
     * @param name Name of the experiment, e.g. `'eng.kufak.shop.categories.v2'`
     */
    getAllocation,
    /**
     * List of GraphQL `ConfigParamInput`s, to be passed as input to a query for `configParams`.
     * See: https://studio.apollographql.com/graph/sfix-kufak-eng/variant/production/schema/reference/inputs/ConfigParamsInput
     */
    // NOTE: We are asserting that `configParamInputs` is mutable here, because this
    // is by default what the graphql-codegen types are expecting. If we were to use
    // the `immutableTypes` config here, this would be unnecessary.
    // https://the-guild.dev/graphql/codegen/plugins/typescript/typescript-operations#immutabletypes
    configParamInputs: configParamInputs as DeepMutable<CPI>,
  };
};

export default buildConfigParamInputs;

import React from 'react';
import { IconButton } from '@stitch-fix/mode-react';
import { SingleBusinessLineSocial } from './social-brand-data';
import { SingleBusinessLineSocialLink } from '.';
import { SocialItem } from './SocialItem';

interface SingleBusinessLineSocialProps {
  social: SingleBusinessLineSocial;
  socialLink: SingleBusinessLineSocialLink;
}

export const SingleBusinessLineLink = ({
  social: { brand, icon },
  socialLink: { linkHref },
}: SingleBusinessLineSocialProps) => {
  return (
    <SocialItem brand={brand}>
      <IconButton aria-label={`visit ${brand}`} href={linkHref} as="a">
        <>{icon}</>
      </IconButton>
    </SocialItem>
  );
};

import React, { forwardRef, useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { G } from '@mobily/ts-belt';
import { useNavContext } from '../../NavContextWrapper';
import { Banners } from '../Banners';
import type {
  LegacySiteWideBanner,
  LegacySlimBanner,
} from '../Banners/BannerTypes';
import styles from './style.module.scss';

interface HeaderLayoutProps {
  banner?: LegacySiteWideBanner;
  slimBanner?: LegacySlimBanner;
  suppressBanners: boolean;
  children: React.ReactElement;
}

/**
 * `HeaderLayout` handles the shared functionality of:
 *
 * - Making the `<header>` fixed position at the top of the screen
 * - Including a "placeholder" element that pushes the content below the header
 * - Displaying an optional `<Banner />` or `<SlimBanner />` below the header
 * - Explanation of this logic here: https://github.com/stitchfix/knit/pull/964#discussion_r1411135442
 */
const HeaderLayout = forwardRef<HTMLElement, HeaderLayoutProps>(
  ({ banner, slimBanner, suppressBanners, children }, ref) => {
    const [placeholderHeight, setPlaceholderHeight] = useState(0);
    const headerRef = useRef(null);
    const { banners } = useNavContext();

    // reuse passed in ref, otherwise use the one created internally
    const headerElRef = ref || headerRef;

    useEffect(() => {
      let observer: ResizeObserver;
      let headerElRefCurrent: HTMLElement;

      if (
        !suppressBanners &&
        G.isObject(headerElRef) &&
        G.isObject(headerElRef.current)
      ) {
        headerElRefCurrent = headerElRef.current;

        observer = new ResizeObserver(([firstEntry]) => {
          setPlaceholderHeight(currentHeaderHeight => {
            /**
             * cheap and easy way to round this to nearest integer
             * having slighty different values (e.g. 49.7833px vs 49.4167px)
             * has been causing issues in Percy snapshots
             */
            const newHeaderHeight = Number(
              firstEntry.contentRect.height.toFixed(),
            );

            // constantly updating the placeholder height will cause
            // the page to jump, so only update it if it increases
            // in order to ensure nothing is getting clipped.
            return newHeaderHeight > currentHeaderHeight
              ? newHeaderHeight
              : currentHeaderHeight;
          });
        });

        observer.observe(headerElRefCurrent);
      }

      return () => {
        if (observer && G.isObject(headerElRefCurrent)) {
          observer.unobserve(headerElRefCurrent);
        }
      };
    }, [headerElRef, suppressBanners]);

    return (
      <>
        <div data-hide-on-native-apps style={{ height: placeholderHeight }} />
        <header
          // Immediately hide header included in build-time rendered pages from mobile app webviews through CSS
          data-hide-on-native-apps
          // Persistent selector hook for external tests
          data-stitchfix-header
          data-testid="sfix-header"
          className={styles.header}
          ref={headerElRef}
          style={{ position: placeholderHeight > 0 ? 'fixed' : 'static' }}
        >
          {children}
        </header>
        {!suppressBanners && (
          <Banners
            legacySiteWideBanner={banner}
            legacySlimBanner={slimBanner}
            graphQLBanners={banners}
          />
        )}
      </>
    );
  },
);

export default HeaderLayout;

import classNames from 'classnames/bind';
import React, { useEffect, useRef } from 'react';
import { KnitLink } from '../../../KnitLink';
import { ActionableLink } from '../helpers/getActionableLinks';
import styles from './style.module.scss';

interface SecondaryNavProps {
  links: ActionableLink[];
}

const cx = classNames.bind(styles);

const SecondaryNav = ({ links }: SecondaryNavProps) => {
  const scrollContainerRef = useRef<HTMLUListElement>(null);
  const navContainerRef = useRef<HTMLElement>(null);
  const activeLinkRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (
      activeLinkRef.current &&
      navContainerRef.current &&
      scrollContainerRef.current
    ) {
      const isActiveLinkOffScreen =
        activeLinkRef.current.offsetLeft + activeLinkRef.current.offsetWidth >
        navContainerRef.current.offsetWidth;

      if (isActiveLinkOffScreen) {
        scrollContainerRef.current.scroll({
          left: activeLinkRef.current.offsetLeft,
        });
      }
    }
  }, [links]);

  return (
    <nav
      className={styles['secondary-nav']}
      data-testid="secondary-nav"
      aria-label="secondary"
      ref={navContainerRef}
    >
      <ul className={styles['secondary-nav-links']} ref={scrollContainerRef}>
        {links.map(link => (
          <li
            className={cx('secondary-nav-link', {
              active: link.active,
            })}
            key={link.text}
            data-testid={link.active ? 'secondary-nav-active-link' : ''}
            ref={link.active ? activeLinkRef : null}
          >
            <KnitLink
              data-ga={`meganav-secondary-${link.name}`}
              to={link.location}
            >
              {link.text}
            </KnitLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SecondaryNav;

import ChangeClientError from './changeClientError';

const changeClient = (endpoint: string, clientId: string) => {
  return fetch(`${endpoint}/client-auth-api/api/session`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify({
      active_client_id: clientId,
      scope: 'default',
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json;version=1',
    },
  }).then(response => {
    if (!response.ok) {
      throw new ChangeClientError('Switch account response was not ok');
    }

    return response.json();
  });
};

export default changeClient;

import React, { useEffect } from 'react';
import { Box, Inline, Link, Text } from '@stitch-fix/mode-react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { A } from '@mobily/ts-belt';
import {
  GET_RECENT_SEARCHES,
  GetRecentSearchesQuery,
} from './getRecentSearches';
import {
  CLEAR_RECENT_SEARCHES,
  ClearRecentSearchesMutation,
} from './clearRecentSearches';
import RecentSearchesList from './RecentSearchesList';

interface RecentSearchesProps {
  isSmallScreen: boolean;
  onClearHistory: () => void;
  onSelect: (value: string) => void;
  shouldLoadRecentSearches: boolean;
}

/**
 * The `RecentSearches` component handles querying for a client's recent searches,
 * and displaying the list if it exists – otherwise renders null.
 */
const RecentSearches = ({
  isSmallScreen,
  onClearHistory,
  onSelect,
  shouldLoadRecentSearches,
}: RecentSearchesProps) => {
  const [loadRecentSearches, { data, called }] =
    useLazyQuery<GetRecentSearchesQuery>(GET_RECENT_SEARCHES);
  const [clearRecentSearches] = useMutation<ClearRecentSearchesMutation>(
    CLEAR_RECENT_SEARCHES,
  );

  useEffect(() => {
    if (shouldLoadRecentSearches && !called) {
      loadRecentSearches();
    }
  }, [called, loadRecentSearches, shouldLoadRecentSearches]);

  const recentSearches = data?.shopContent?.recentSearches || [];

  if (A.isEmpty(recentSearches)) return null;

  return (
    <Box py={1} px={1} data-testid="recent-searches">
      <Inline spacing="auto" vAlign="center" mb={1}>
        <Text as="span" setting="title-xsmall" my={0}>
          Recent searches
        </Text>
        <Text as="span" setting="body-small-fixed" color="blue-45" my={0}>
          <Link
            variant="inherit"
            underline="inverse"
            as="button"
            onClick={() => {
              clearRecentSearches();
              onClearHistory();
            }}
          >
            Clear history
          </Link>
        </Text>
      </Inline>
      <RecentSearchesList
        isSmallScreen={isSmallScreen}
        onSelect={onSelect}
        recentSearches={recentSearches}
      />
    </Box>
  );
};

export default RecentSearches;

import React from 'react';
import classNames from 'classnames/bind';
import { LogoBrand2024Navy, LogoMonogramNavy } from '@stitch-fix/mode-react';
import { useHeaderLogic } from '../helpers/useHeaderLogic';
import PrimaryLink from '../../PrimaryLink';
import HeaderLayout from '../../HeaderLayout';
import type {
  LegacySiteWideBanner,
  LegacySlimBanner,
} from '../../Banners/BannerTypes';
import SimplifiedDropdown from './SimplifiedDropdown';
import { isClientEligibleForHome } from '../helpers/getActionableLinks';
import { HeaderShellContext } from '../helpers/HeaderShellContext';
import { getWindow } from '../../../../helpers/getBrowserGlobals';
import { getRegion } from '../../../../i18n';
import type { Client } from '../../../NavContextWrapper/types';
import styles from './style.module.scss';

interface SimplifiedHeaderProps {
  client: Client;
  banner?: LegacySiteWideBanner;
  slimBanner?: LegacySlimBanner;
  suppressBanners?: boolean;
  shouldReloadOnLogoClick?: boolean;
  stickyId?: string;
  stickyOffset?: number;
  isNameVisible?: boolean;
}

const cx = classNames.bind(styles);

/**
 * This is a simplified version of the logged-in header. It has inverse colors
 * vs the regular logged-in header, and has all links/buttons removed except
 * for the logo link and dropdown menu.
 */
const SimplifiedHeader = ({
  client,
  banner,
  slimBanner,
  suppressBanners = false,
  shouldReloadOnLogoClick,
  stickyId,
  stickyOffset = 0,
  isNameVisible = true,
}: SimplifiedHeaderProps) => {
  const region = getRegion();
  const hrefForBrandLink = isClientEligibleForHome(client, region)
    ? '/home'
    : '/';

  const handleBrandLinkClick = (e: React.MouseEvent) => {
    if (shouldReloadOnLogoClick) {
      /** Prevent the default link behavior and reload the page instead.
       * This is the desired behavior requested by Design for the onboarding flow,
       * so we will use the shouldReloadOnLogoClick prop in the onboarding apps.
       */
      e.preventDefault();
      getWindow()?.location?.reload();
    }
  };

  const { headerElRef, setStickiness, isPrimaryNavVisible, headerShellRef } =
    useHeaderLogic({
      stickyId,
      stickyOffset,
    });

  return (
    <HeaderShellContext.Provider value={headerShellRef.current}>
      <HeaderLayout
        ref={headerElRef}
        banner={banner}
        slimBanner={slimBanner}
        suppressBanners={suppressBanners}
      >
        <div
          className={styles['header-shell']}
          ref={headerShellRef}
          data-testid="simplified-header"
        >
          <nav
            className={cx('primary-nav', {
              closed: !isPrimaryNavVisible,
            })}
            onTransitionEnd={setStickiness}
            data-testid="simplified-primary-nav"
            aria-label="primary"
          >
            <PrimaryLink
              className={styles['home-link']}
              to={hrefForBrandLink}
              data-ga="meganav-primary-brand-link"
              data-testid="brand-link"
              onClick={e => {
                handleBrandLinkClick(e);
              }}
            >
              <span className={styles['logo-circle']}>
                <LogoMonogramNavy title="Stitch Fix" data-testid="logo-mark" />
              </span>
              <span className={styles.wordmark}>
                <LogoBrand2024Navy title="Stitch Fix" data-testid="logo" />
              </span>
            </PrimaryLink>
            <div className={styles['dropdown-menu']}>
              <SimplifiedDropdown
                client={client}
                isPrimaryNavVisible={isPrimaryNavVisible}
                isNameVisible={isNameVisible}
              />
            </div>
          </nav>
          <div id="account-dropdown-portal" />
        </div>
      </HeaderLayout>
    </HeaderShellContext.Provider>
  );
};

export default SimplifiedHeader;

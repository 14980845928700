import React from 'react';
import { Banner, SlimBanner, Button } from '@stitch-fix/mode-react';
import { useLocalStorageValue } from '@react-hookz/web';
import type {
  ModeBannerSitewideVariant,
  ModeBannerSlimVariant,
} from '../../../types/definitions/graphql-schema';
import type {
  LegacySlimBanner,
  LegacySiteWideBanner,
  SlimThemesType,
  SiteWideThemesType,
  BannersProps,
  NormalizedBanner,
  GraphqlBanner,
} from './BannerTypes';
import BannerIcon, { isValidIcon } from './BannerIcon';

export const SlimVariants: Record<ModeBannerSlimVariant, SlimThemesType> = {
  DEFAULT: 'info-light',
  INVERSE: 'dark',
  PROMOTIONAL: 'promo-dark',
  URGENT: 'error-dark',
  CITRUS: 'citrus',
  GRAPEFRUIT: 'grapefruit',
} as const;

const SiteWideVariants: Record<ModeBannerSitewideVariant, SiteWideThemesType> =
  {
    PROMOTIONAL: 'promo-dark',
    ERROR: 'error-dark',
    INFORMATIONAL: 'info-dark',
    CITRUS: 'citrus',
    GRAPEFRUIT: 'grapefruit',
  } as const;

const BannerButton = ({
  href,
  text,
  bannerVariant,
  isSecondary = false,
}: {
  href: string;
  text: string;
  bannerVariant: SiteWideThemesType;
  isSecondary?: boolean;
}) => {
  const variant = (() => {
    const useDarkText =
      bannerVariant === 'promo-dark' ||
      bannerVariant === 'citrus' ||
      bannerVariant === 'grapefruit';

    if (isSecondary) {
      return useDarkText ? 'text' : 'text-inverse';
    }

    return useDarkText ? 'outline' : 'outline-inverse';
  })();

  return (
    <Button as="a" href={href} variant={variant} width="fit">
      {text}
    </Button>
  );
};

const transformLegacySiteWide = (
  source: LegacySiteWideBanner,
): NormalizedBanner => {
  return {
    id: source.id,
    type: 'ModeBannerSitewide',
    banner: {
      children: source.message,
      headline: source.headline,
      variant: source.variant,
      primaryLink: source.cta ? (
        <BannerButton
          href={source.cta.href}
          text={source.cta.text}
          bannerVariant={source.variant}
        />
      ) : undefined,
    },
  };
};

const transformLegacySlim = (source: LegacySlimBanner): NormalizedBanner => {
  return {
    id: source?.id,
    type: 'ModeBannerSlim',
    banner: {
      cta: source.cta,
      icon: source.icon,
      text: source.text,
      variant: source.variant,
    },
  };
};

const transformGraphqlBanner = (source: GraphqlBanner): NormalizedBanner => {
  const icon =
    source.icon && isValidIcon(source.icon) ? (
      <BannerIcon icon={source.icon} />
    ) : null;

  // eslint-disable-next-line no-underscore-dangle
  if (source.__typename === 'ModeBannerSitewide') {
    const variant = SiteWideVariants[source.sitewideVariant];

    return {
      id: source.id,
      // eslint-disable-next-line no-underscore-dangle
      type: source.__typename,
      banner: {
        icon,
        headline: source.headline,
        variant,
        children: source.message,
        primaryLink: source.cta ? (
          <BannerButton
            href={source.cta.url}
            text="primary"
            bannerVariant={variant}
          />
        ) : undefined,
        secondaryLink: source.secondaryCta ? (
          <BannerButton
            href={source.secondaryCta.url}
            text={source.secondaryCta.label.text}
            bannerVariant={variant}
            isSecondary
          />
        ) : undefined,
      },
    };
  }

  // slim
  return {
    id: source.id,
    // eslint-disable-next-line no-underscore-dangle
    type: source.__typename,
    banner: {
      variant: SlimVariants[source.slimVariant],
      text: source.message,
      icon,
      cta: source.cta
        ? {
            url: source.cta.url,
            text: source.cta.label.text,
          }
        : undefined,
    },
  };
};

const computedBanners = ({
  legacySiteWideBanner,
  legacySlimBanner,
  graphQLBanners,
}: BannersProps): NormalizedBanner[] => {
  const banners: NormalizedBanner[] = [];

  if (legacySiteWideBanner) {
    banners.push(transformLegacySiteWide(legacySiteWideBanner));
  }

  if (legacySlimBanner) {
    banners.push(transformLegacySlim(legacySlimBanner));
  }

  return banners.concat((graphQLBanners || []).map(transformGraphqlBanner));
};

interface ClosedBannersCache {
  [key: string]: {
    closed: boolean;
  };
}

/**
 * useBanners hook is responsible for appropriately transforming and preparing
 * banners to be used * by components. This includes dealing with both legacy
 * and new GraphQL banner formats.
 */
export const useBanners = ({
  legacySiteWideBanner,
  legacySlimBanner,
  graphQLBanners,
}: BannersProps) => {
  // Interface to manage the state of closed banners. This state is saved in
  // local storage.

  // Using useLocalStorageValue from @react-hookz/web to create a state variable
  // that is preserved across sessions. This allows for remembering and
  // persisting the state of closed banners.
  const [storedIDs, setStoredIDs] = useLocalStorageValue<ClosedBannersCache>(
    `Knit:Banners`,
    {},
  );

  // Creation of the combinedBanners array. This array will hold all banners.
  const combinedBannersNew = computedBanners({
    legacySiteWideBanner,
    legacySlimBanner,
    graphQLBanners,
  });

  // The filteredBanners array is obtained by filtering combinedBanners.
  // This array will only include banners that have not been closed (not
  // present in storedIDs).
  const filteredBanners = combinedBannersNew.filter(
    banner => storedIDs && !storedIDs[banner.id]?.closed,
  );

  // onBannerClose is a function to handle the event of banner closure.
  // This function updates the state of storedIDs, marking the banner with
  // the given id as closed.
  const onBannerClose = (id: string) => () => {
    setStoredIDs(prevStoredIDs => ({
      ...prevStoredIDs,
      [id]: { closed: true },
    }));
  };

  // Only the first banner in the filteredBanners list is shown at a time.
  // This results in a display queue where banners are displayed one
  // after the other when they are closed.
  const bannerToShow = filteredBanners?.[0] ?? null;

  return {
    onBannerClose,
    bannerToShow,
  };
};

/**
 * Controls all banners in Knit, both legacy prop based and GraphQL
 */
export const Banners = ({
  legacySiteWideBanner,
  legacySlimBanner,
  graphQLBanners,
}: BannersProps) => {
  const { onBannerClose, bannerToShow } = useBanners({
    legacySiteWideBanner,
    legacySlimBanner,
    graphQLBanners,
  });

  const isSiteWideBanner = bannerToShow?.type === 'ModeBannerSitewide';

  // Returns the corresponding banner to render depending on the banner type
  return bannerToShow ? (
    <>
      {isSiteWideBanner ? (
        <Banner
          {...bannerToShow.banner}
          onClose={onBannerClose(bannerToShow.id)}
        />
      ) : (
        <SlimBanner
          {...bannerToShow.banner}
          onClose={onBannerClose(bannerToShow.id)}
        />
      )}
    </>
  ) : null;
};

import React, { useState, useEffect } from 'react';
import { getDocument } from '../../../helpers/getBrowserGlobals';
import styles from './style.module.scss';

export const SkipNavLink = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const a11yContentIdExists =
      !!getDocument()?.getElementById('knit-main-content');

    if (a11yContentIdExists) {
      setShow(true);
    }
  }, []);

  if (show) {
    return (
      <a href="#knit-main-content" className={styles['skip-link']}>
        Skip to page content
      </a>
    );
  }

  return null;
};

import { createGraphQLApiProvider as createProvider } from '@stitch-fix/graphql-api-provider';
import { getWindow } from '../../../helpers/getBrowserGlobals';
import { sendBugsnagEventToKnit } from '../../shared/sendBugsnagEventToKnit';

interface ErrorCallbackOptions {
  metadata?: Record<string, unknown>;
}

// Override GraphQLApiProvider's default `onError` callback in order to
// report GraphQL errors to Knit's Bugsnag project instead of the parent app's.
// Docs: https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#apikey
// `onError` definition: https://github.com/stitchfix/graphql-api-provider/blob/98c8dc3f222b6a23bfa32244dc3ac8c74478e73e/src/createGraphQLApiProvider/index.js#L44
export const onError = (error: Error, { metadata }: ErrorCallbackOptions) => {
  sendBugsnagEventToKnit({ error, metadata });
};

const createGraphQLApiProvider = () =>
  createProvider({
    name: `knit:web`,
    onError,
  }).GraphQLApiProvider;

let cachedProvider: ReturnType<typeof createGraphQLApiProvider>;

export const getGraphQLApiProvider = () => {
  if (!getWindow()) {
    // SSR - always return a new provider to avoid sharing personal data, and potential memory leaks
    return createGraphQLApiProvider();
  }

  // Browser - use a singleton provider to avoid losing the cache on component remounts
  cachedProvider ||= createGraphQLApiProvider();

  return cachedProvider;
};

import React from 'react';
import { formatMoneyForLocale } from '@stitch-fix/i18n';
import type { Client } from '../../../NavContextWrapper/types';
import Dropdown from './Dropdown';
import { useSharedTranslation } from '../../../../i18n';
import GlobalShoppingBag from '../../GlobalShoppingBag';
import GlobalSavedItems from './GlobalSavedItems';
import Search from './Search';
import PrimaryLink from '../../PrimaryLink';
import { useUngatedShoppingBag } from '../../../../helpers/useUngatedShoppingBag';
import useCurrentHost from '../../../../helpers/useCurrentHost';
import useCurrentPath from '../../../../helpers/useCurrentPath';
import { isOnReferralPath } from '../../../../helpers/pathHelper';
import { isInFeatureFlag } from '../../../../helpers/isInFeatureFlag';

import styles from './style.module.scss';

interface MyAccountProps {
  activePageCanonical?: string;
  client: Client;
  isPrimaryNavVisible: boolean;
  showSaveForLater?: boolean;
  suppressFreestyleFeatureNav?: boolean;
  suppressKeptItemAndOrderLinks?: boolean;
  suppressReferralLink?: boolean;
}

const MyAccount = ({
  activePageCanonical,
  client,
  isPrimaryNavVisible,
  showSaveForLater = false,
  suppressFreestyleFeatureNav = false,
  suppressKeptItemAndOrderLinks = false,
  suppressReferralLink = false,
}: MyAccountProps) => {
  const { t, locale } = useSharedTranslation();
  const currentHost = useCurrentHost();
  const ungatedShoppingBag = useUngatedShoppingBag();
  const currentPath = useCurrentPath();

  const hasFreestyleAccess =
    !suppressFreestyleFeatureNav && client?.capabilities.canShop;
  const hasCompletedOnboarding = !client?.capabilities.needsOnboarding;
  const shoppingBagCount = client?.shoppingBag?.count || 0;

  const isSearchButtonShown =
    hasFreestyleAccess &&
    client.region === 'US' &&
    (client.businessLine !== 'KIDS' ||
      isInFeatureFlag('client.global.kids_categories', client?.featureFlags)) &&
    currentHost !== 'support.stitchfix.com' &&
    hasCompletedOnboarding;

  const isReferralShown = hasCompletedOnboarding && !suppressReferralLink;

  const isDropdownReferralShown =
    hasCompletedOnboarding && isSearchButtonShown && !suppressReferralLink;

  const referralBonusAmount = client?.referrals.incentive?.senderCredit;
  const onOnboarding =
    activePageCanonical === 'style_profile' && !hasCompletedOnboarding;
  const hideShoppingBag = !hasFreestyleAccess || onOnboarding;

  return (
    <div className={styles['my-account']}>
      {isSearchButtonShown && (
        <Search
          activePageCanonical={activePageCanonical}
          isPrimaryNavVisible={isPrimaryNavVisible}
        />
      )}

      {isReferralShown && (
        <div className={styles['referral-link-container']}>
          <PrimaryLink
            className={styles['referral-link']}
            data-ga="meganav-primary-client-referrals"
            to="/client/referrals"
            active={isOnReferralPath(currentPath)}
            data-testid="meganav-primary-client-referrals"
          >
            {t('referLink.refer')}{' '}
            {referralBonusAmount && referralBonusAmount.value > 0 && (
              <>& get {formatMoneyForLocale(locale, referralBonusAmount)}</>
            )}
          </PrimaryLink>
        </div>
      )}

      {showSaveForLater && (
        <GlobalSavedItems activePageCanonical={activePageCanonical} />
      )}

      <GlobalShoppingBag
        activePageCanonical={activePageCanonical}
        count={shoppingBagCount + ungatedShoppingBag.totalQuantity}
        gated
        hideIf={() => hideShoppingBag}
      />

      <Dropdown
        client={client}
        isPrimaryNavVisible={isPrimaryNavVisible}
        isReferralShown={isDropdownReferralShown}
        suppressKeptItemAndOrderLinks={suppressKeptItemAndOrderLinks}
      />
    </div>
  );
};

export default MyAccount;

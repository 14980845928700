import { Client, HouseholdMember } from '../../../NavContextWrapper/types';

type UseHouseholdReturn = {
  getCircleColorForUser: (user: Client | HouseholdMember) => number;
  householdMembers: HouseholdMember[];
};
export const useHousehold = (client: Client): UseHouseholdReturn => {
  const householdMembers = client.household?.members || [];
  const householdClientIds = householdMembers.map(member => member.id);

  // Return a consistent color code for a given client id, so the color stays with
  // the user when they switch family accounts.
  const getCircleColorForUser = (user: Client | HouseholdMember) => {
    const index = householdClientIds.indexOf(user.id);

    // If id is not found, it will be negative. Default to 0.
    return index >= 0 ? index : 0;
  };

  return { getCircleColorForUser, householdMembers };
};

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconInformation16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M0.300049 8.00005C0.300049 3.74746 3.74746 0.300049 8.00005 0.300049C12.2526 0.300049 15.7 3.74746 15.7 8.00005C15.7 12.2526 12.2526 15.7 8.00005 15.7C3.74746 15.7 0.300049 12.2526 0.300049 8.00005ZM8.00005 1.70005C4.52065 1.70005 1.70005 4.52065 1.70005 8.00005C1.70005 11.4794 4.52065 14.3 8.00005 14.3C11.4794 14.3 14.3 11.4794 14.3 8.00005C14.3 4.52065 11.4794 1.70005 8.00005 1.70005ZM7.28405 3.30005H7.98405H8.01604H8.71604V4.70005H8.01604H7.98405H7.28405V3.30005ZM8.70005 5.54805V6.24805V12.008V12.708H7.30005V12.008V6.24805V5.54805H8.70005Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconInformation16(props, ref);
});
export default ForwardRef;
import buildConfigParamInputs from './buildConfigParamInputs';

export const { getAllocation, configParamInputs } = buildConfigParamInputs([
  {
    // TODO cleanup after experiment: https://stitchfix.atlassian.net/browse/INC-294
    name: 'eng.kufak.incentives.loyalty_pilot',
    expectedValues: ['control', 'spend_400_get_25', 'spend_250_get_15'],
    fallbackValue: 'control',
  },
] as const);

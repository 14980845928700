import React, { ReactNode } from 'react';
import { useAbsoluteUrl } from './useAbsoluteUrl';

export type KnitLinkProps = {
  to: string;
  children: ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  target?: string;
  title?: string;
  rel?: string;
  'data-ga'?: string;
  'data-testid'?: string;
};

export const KnitLink = ({ to, children, ...otherProps }: KnitLinkProps) => {
  const absoluteUrl = useAbsoluteUrl();

  return (
    <a href={absoluteUrl(to)} {...otherProps}>
      {children}
    </a>
  );
};

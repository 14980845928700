const logout = (rootUrl: string) => {
  return fetch(`${rootUrl}/client-auth-api/api/session`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json;version=1',
    },
    method: 'DELETE',
  }).then(response => {
    if (!response.ok) {
      throw new Error('Logout response was not ok');
    }

    return response;
  });
};

export default logout;

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialTiktok16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M11.1263 0C11.3904 2.27047 12.6578 3.62412 14.8614 3.76812V6.32181C13.5844 6.44661 12.4658 6.029 11.1647 5.24177V10.0179C11.1647 16.0853 4.54921 17.9814 1.88956 13.6324C0.18047 10.834 1.22705 5.92339 6.70958 5.72659V8.41947C6.29191 8.48668 5.84543 8.59228 5.43736 8.73148C4.21796 9.1443 3.52664 9.91712 3.71867 11.2804C4.08834 13.8917 8.87955 14.6645 8.48108 9.56191V0.00480016H11.1263V0Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconSocialTiktok16(props, ref);
});
export default ForwardRef;
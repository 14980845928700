import React, { useState, useEffect } from 'react';
import { Text, LogoMonogramMint } from '@stitch-fix/mode-react';
import { formatMoneyForLocale } from '@stitch-fix/i18n';
import AppStoreSVG from '../icons/AppStore';
import { featureIsAvailable, getRegion, loadTranslations } from '../../../i18n';
import { KnitLink, ModeLink } from '../../KnitLink';
import { SocialUS, SocialUK } from '../Social';
import { translations } from '../FooterContent/translations';
import { getWindow } from '../../../helpers/getBrowserGlobals';
import styles from './style.module.scss';
import { useNavContext } from '../../NavContextWrapper';
import { regionalUrls } from '../../../i18n/regionalUrls';
import { SocialLinks } from '../Social/US';
import { LegalFooterLinks, TrademarkText } from '../LegalLinks/LegalLinks';

const { useTranslation } = loadTranslations(translations, 'FooterContent');

const emitGTMEvent = () => {
  const win = getWindow();

  if (win && !win?.dataLayer) {
    win.dataLayer = [];
  }

  if (win) {
    win.dataLayer?.push({ event: 'navigation', location: 'footer' });
  }
};

const SOCIAL_LINKS: SocialLinks = {
  instagram: {
    women: 'https://www.instagram.com/stitchfix',
    men: 'https://www.instagram.com/stitchfixmen',
  },
  facebook: {
    women: 'https://www.facebook.com/stitchfix',
    men: 'https://www.facebook.com/stitchfixmen',
  },
  pinterest: {
    women: 'https://www.pinterest.com/stitchfix',
    men: 'https://www.pinterest.com/stitchfixmen',
  },
  twitter: {
    women: 'https://www.twitter.com/stitchfix',
    men: 'https://www.twitter.com/stitchfixmen',
  },
  tiktok: {
    linkHref: 'https://www.tiktok.com/@stitchfix',
  },
  youtube: {
    linkHref: 'https://www.youtube.com/stitchfix',
  },
};

/**
 * The CollapsibleContent component conditionally displays its content based on the screen size.
 * On small screens, the content is collapsed by default and shown when the "More info" button is clicked.
 * Above medium screens, the content is always shown and the "More info" button is hidden.
 */
export const CollapsibleContent = () => {
  const { client } = useNavContext();
  const { t, locale } = useTranslation();
  const isLoggedIn = !!client;
  const referralBonusAmount = client?.referrals.incentive?.senderCredit;
  const region = getRegion();
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const win = getWindow();

    // eslint-disable-next-line no-restricted-globals -- inside useEffect
    const { userAgent = '' } = win?.navigator || {};

    setIsAndroid(/Android/i.test(userAgent));
  }, []);

  return (
    <div className={styles['content-grid']}>
      <div className={styles['logo-region']}>
        <span className={styles['footer__logo-svg']}>
          <LogoMonogramMint height={60} width={65} title="Stitch Fix" />
        </span>
      </div>
      <ul className={styles['nav-links']}>
        <li>
          <Text
            as="div"
            className={styles['nav-link-title']}
            color="blue-45"
            setting="title-xxsmall"
          >
            The Service
          </Text>
          <Text
            as="ul"
            setting="body-medium-fixed"
            className={styles['list-group']}
          >
            {!isLoggedIn && (
              <>
                {featureIsAvailable('gift_cards') && (
                  <li>
                    <ModeLink to="/gifts" onClick={emitGTMEvent}>
                      Gift cards
                    </ModeLink>
                  </li>
                )}
                <li>
                  <ModeLink to="/iphone-app" onClick={emitGTMEvent}>
                    iPhone App
                  </ModeLink>
                </li>

                {featureIsAvailable('returns') && (
                  <li className={styles.returns}>
                    <ModeLink
                      to={regionalUrls[region].returns}
                      onClick={emitGTMEvent}
                    >
                      {t('returns')}
                    </ModeLink>
                  </li>
                )}

                {featureIsAvailable('plus') && !isLoggedIn && (
                  <li>
                    <ModeLink to="/women/plus" onClick={emitGTMEvent}>
                      Plus Sizes
                    </ModeLink>
                  </li>
                )}

                {featureIsAvailable('maternity') && !isLoggedIn && (
                  <li>
                    <ModeLink to="/women/maternity" onClick={emitGTMEvent}>
                      Maternity
                    </ModeLink>
                  </li>
                )}

                {featureIsAvailable('petite') && !isLoggedIn && (
                  <li>
                    <ModeLink to="/women/petite" onClick={emitGTMEvent}>
                      Petite
                    </ModeLink>
                  </li>
                )}

                {featureIsAvailable('big_tall') && !isLoggedIn && (
                  <li>
                    <ModeLink to="/men/big-tall" onClick={emitGTMEvent}>
                      Big & Tall
                    </ModeLink>
                  </li>
                )}

                {featureIsAvailable('jeans') && !isLoggedIn && (
                  <li>
                    <ModeLink to="/women/jeans" onClick={emitGTMEvent}>
                      Women’s Jeans
                    </ModeLink>
                  </li>
                )}

                {featureIsAvailable('business_casual') && (
                  <li>
                    <ModeLink
                      to="/women/business-casual"
                      onClick={emitGTMEvent}
                    >
                      Business Casual
                    </ModeLink>
                  </li>
                )}
              </>
            )}
            {isLoggedIn && (
              <>
                <li>
                  <ModeLink to="/women" onClick={emitGTMEvent}>
                    Women
                  </ModeLink>
                </li>
                {featureIsAvailable('kids') && (
                  <li>
                    <ModeLink to="/kids" onClick={emitGTMEvent}>
                      Kids
                    </ModeLink>
                  </li>
                )}
                <li>
                  <ModeLink to="/men" onClick={emitGTMEvent}>
                    Mens
                  </ModeLink>
                </li>
                <li>
                  <ModeLink to="/iphone-app" onClick={emitGTMEvent}>
                    iPhone App
                  </ModeLink>
                </li>
                <li>
                  <ModeLink to="/gifts" onClick={emitGTMEvent}>
                    Gift cards
                  </ModeLink>
                </li>
                <li>
                  <ModeLink
                    to="/client/referrals"
                    data-ga="footer-client-referrals"
                  >
                    {t('referLink.refer', { ns: 'KnitShared' })}
                    {referralBonusAmount && referralBonusAmount.value > 0 && (
                      <>
                        {' '}
                        {t('referLink.getRebrand', {
                          amount: formatMoneyForLocale(
                            locale,
                            referralBonusAmount,
                          ),
                          ns: 'KnitShared',
                        })}
                      </>
                    )}
                  </ModeLink>
                </li>
              </>
            )}
          </Text>
        </li>
        <li>
          <Text
            as="div"
            className={styles['nav-link-title']}
            color="blue-45"
            setting="title-xxsmall"
          >
            The Company
          </Text>
          <Text
            as="ul"
            setting="body-medium-fixed"
            className={styles['list-group']}
          >
            <li>
              <ModeLink to="/about" onClick={emitGTMEvent}>
                About Us
              </ModeLink>
            </li>
            {!isLoggedIn && !featureIsAvailable('returns') && (
              // only show on US logged out pages
              <li className={styles['returns-sm']}>
                <ModeLink
                  to={regionalUrls[region].returns}
                  onClick={emitGTMEvent}
                >
                  {t('returns')}
                </ModeLink>
              </li>
            )}
            <li>
              <ModeLink to="/impact" onClick={emitGTMEvent}>
                Social Impact
              </ModeLink>
            </li>
            {featureIsAvailable('womens_blog') && (
              <li className={styles['blog-desktop']}>
                <ModeLink to="/women/blog/" onClick={emitGTMEvent}>
                  Women&apos;s Blog
                </ModeLink>
              </li>
            )}
            {featureIsAvailable('mens_blog') && (
              <li className={styles['blog-desktop']}>
                <ModeLink to="/men/blog/" onClick={emitGTMEvent}>
                  Men&apos;s Blog
                </ModeLink>
              </li>
            )}
            <li>
              <ModeLink
                to="https://newsroom.stitchfix.com/"
                onClick={emitGTMEvent}
              >
                Press
              </ModeLink>
            </li>
            <li>
              <ModeLink
                to="https://investors.stitchfix.com/"
                onClick={emitGTMEvent}
              >
                Investor Relations
              </ModeLink>
            </li>
            <li>
              <ModeLink to="/careers" onClick={emitGTMEvent}>
                Careers
              </ModeLink>
            </li>
            <li>
              <ModeLink
                to="https://multithreaded.stitchfix.com/blog/"
                onClick={emitGTMEvent}
              >
                Tech Blog
              </ModeLink>
            </li>
            {featureIsAvailable('affiliates') && (
              <li>
                <ModeLink to="/all/affiliates " onClick={emitGTMEvent}>
                  Affiliates
                </ModeLink>
              </li>
            )}
          </Text>
        </li>
        <li className={styles['questions-list']}>
          <Text
            as="div"
            className={styles['nav-link-title']}
            color="blue-45"
            setting="title-xxsmall"
          >
            Questions?
          </Text>
          <Text
            as="ul"
            setting="body-medium-fixed"
            className={styles['list-group']}
          >
            <li>
              <ModeLink to={regionalUrls[region].help} onClick={emitGTMEvent}>
                {t('help_center')}
              </ModeLink>
            </li>
            <li>
              <ModeLink
                to={regionalUrls[region].returns}
                onClick={emitGTMEvent}
              >
                {t('returns')}
              </ModeLink>
            </li>
          </Text>
        </li>
      </ul>
      <div className={styles['social-app-region']}>
        <div className={styles['social-buttons']}>
          {featureIsAvailable('social_buttons_uk') ? (
            <SocialUK />
          ) : (
            <SocialUS socialLinks={SOCIAL_LINKS} />
          )}
        </div>
        {!isAndroid && (
          <div className={styles['app-store']}>
            <KnitLink
              to="https://itunes.apple.com/app/apple-store/id1022579925?pt=117196399&ct=global_footer&mt=8"
              className={styles['app-store-link']}
              title="Download on the App Store"
            >
              {AppStoreSVG}
            </KnitLink>
          </div>
        )}
      </div>
      <LegalFooterLinks />
      <TrademarkText />
    </div>
  );
};

export default CollapsibleContent;

import React from 'react';

const AppStore = (
  <svg
    width="143"
    height="48"
    viewBox="0 0 189 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Apple App store badge</title>
    <path
      d="M182.276 56H6.6206C4.86499 55.9959 3.18253 55.2963 1.94165 54.0544C0.700766 52.8125 0.00258796 51.1294 0 49.3738L0 6.6164C0.00259455 4.86169 0.701087 3.17965 1.94225 1.93928C3.18341 0.698906 4.86589 0.00148104 6.6206 0L182.276 0C185.924 0 189 2.968 189 6.6164V49.3738C189 53.0208 185.924 56 182.276 56Z"
      fill="#25282D"
    />
    <path
      d="M187.645 49.3753C187.645 50.0799 187.506 50.7776 187.236 51.4285C186.966 52.0795 186.571 52.6708 186.072 53.1688C185.574 53.6668 184.982 54.0616 184.331 54.3307C183.68 54.5999 182.982 54.738 182.277 54.7373H6.62057C5.19687 54.7388 3.83086 54.1747 2.82297 53.1692C1.81508 52.1637 1.24783 50.799 1.24597 49.3753V6.61509C1.24783 5.19075 1.81485 3.82535 2.82254 2.81871C3.83024 1.81206 5.19622 1.24646 6.62057 1.24609H182.276C182.981 1.24609 183.679 1.38498 184.33 1.65481C184.982 1.92465 185.573 2.32015 186.072 2.81872C186.57 3.3173 186.966 3.90918 187.235 4.56057C187.505 5.21196 187.644 5.91009 187.643 6.61509L187.645 49.3753Z"
      fill="#25282D"
    />
    <path
      d="M42.1792 27.6977C42.1386 23.1855 45.8738 20.9903 46.0446 20.8881C43.9292 17.8039 40.6504 17.3825 39.4982 17.3489C36.7444 17.0591 34.0732 18.9967 32.6704 18.9967C31.2396 18.9967 29.0794 17.3769 26.7512 17.4245C23.7552 17.4707 20.9524 19.2053 19.4152 21.8989C16.2428 27.3911 18.6088 35.4621 21.6482 39.9015C23.1686 42.0757 24.9452 44.5033 27.2706 44.4179C29.5456 44.3241 30.3954 42.9675 33.1408 42.9675C35.861 42.9675 36.659 44.4179 39.0306 44.3633C41.4722 44.3241 43.0094 42.1793 44.4766 39.9855C46.2336 37.4935 46.9392 35.0393 46.9672 34.9133C46.9098 34.8937 42.2254 33.1059 42.1792 27.6977ZM37.6992 14.4285C38.9228 12.8983 39.76 10.8165 39.5276 8.70386C37.7566 8.78226 35.5418 9.92886 34.2664 11.4255C33.138 12.7443 32.13 14.9059 32.3904 16.9387C34.3798 17.0871 36.4224 15.9349 37.6992 14.4285Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.1888 17.7339C68.1758 16.9009 68.67 15.6605 68.67 14.0127C68.67 12.5455 68.2122 11.4185 67.2994 10.6303C66.4846 9.93307 65.2764 9.58447 63.6734 9.58447C62.7662 9.58447 61.9262 9.64887 61.1464 9.77487V18.7783C61.7442 18.8511 62.4582 18.8875 63.2926 18.8875C64.9754 18.8875 66.2746 18.5025 67.1888 17.7339ZM66.2746 11.5949C66.8402 12.1661 67.123 12.9851 67.123 14.0533C67.123 15.2489 66.815 16.1589 66.2046 16.7833C65.5942 17.4077 64.7276 17.7199 63.6048 17.7199C63.1246 17.7199 62.7942 17.7073 62.6136 17.6793V10.8347C62.9398 10.7703 63.3332 10.7395 63.7952 10.7395C64.883 10.7395 65.709 11.0251 66.2746 11.5949Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.0032 17.9509C76.5828 17.2985 76.8726 16.4669 76.8726 15.4519C76.8726 14.4663 76.5912 13.6599 76.0312 13.0355C75.4418 12.3929 74.6634 12.0723 73.6946 12.0723C72.6894 12.0723 71.8914 12.3985 71.2978 13.0495C70.7042 13.7005 70.4088 14.5391 70.4088 15.5611C70.4088 16.5383 70.6958 17.3489 71.2698 17.9915C71.8452 18.6355 72.6166 18.9561 73.5854 18.9561C74.5906 18.9561 75.3956 18.6215 76.0032 17.9509ZM74.9574 13.9329C75.2192 14.3683 75.3508 14.8905 75.3508 15.4995C75.3508 16.1085 75.215 16.6349 74.9434 17.0787C74.627 17.6149 74.1874 17.8823 73.6274 17.8823C73.0562 17.8823 72.6138 17.6191 72.296 17.0927C72.0342 16.6573 71.9026 16.1351 71.9026 15.5261C71.9026 14.8989 72.0328 14.3683 72.296 13.9329C72.604 13.4065 73.052 13.1433 73.6414 13.1433C74.2112 13.1433 74.6494 13.4065 74.9574 13.9329Z"
      fill="white"
    />
    <path
      d="M85.806 18.8063L87.8706 12.2081H86.4304L85.6296 15.2909C85.4308 16.0609 85.281 16.7847 85.1816 17.4637H85.141C85.0052 16.7665 84.8288 16.0427 84.6118 15.2909L83.7144 12.2081H82.5188L81.5822 15.3581C81.3274 16.2079 81.1468 16.9107 81.0376 17.4623H81.011C80.892 16.7931 80.7436 16.0861 80.563 15.3441L79.8168 12.2067H78.309L80.2508 18.8063H81.6102L82.5188 15.9405C82.7456 15.2431 82.9229 14.5306 83.0494 13.8083H83.076C83.2164 14.5276 83.3935 15.2393 83.6066 15.9405L84.462 18.8063H85.806Z"
      fill="white"
    />
    <path
      d="M94.01 18.8063H95.4772V14.8527C95.4772 13.8839 95.2238 13.1545 94.717 12.6659C94.3096 12.2669 93.7972 12.0681 93.1812 12.0681C92.694 12.0681 92.2488 12.1927 91.8512 12.4391C91.5166 12.6505 91.2618 12.9151 91.091 13.2357H91.0504L90.9818 12.2039H89.6924C89.7274 12.8843 89.7456 13.5129 89.7456 14.0925V18.8049H91.2128V14.8905C91.2067 14.4764 91.3515 14.0743 91.6202 13.7593C91.7487 13.6038 91.9109 13.4798 92.0946 13.3966C92.2782 13.3133 92.4785 13.2732 92.68 13.2791C93.5676 13.2791 94.01 13.8615 94.01 15.0263V18.8063Z"
      fill="white"
    />
    <path d="M98.0574 18.8063H99.5232V9.17847H98.0574V18.8063Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.292 17.9509C107.871 17.2985 108.161 16.4669 108.161 15.4519C108.161 14.4663 107.88 13.6599 107.32 13.0355C106.729 12.3929 105.952 12.0723 104.982 12.0723C103.978 12.0723 103.18 12.3985 102.586 13.0495C101.993 13.7005 101.697 14.5391 101.697 15.5611C101.697 16.5383 101.984 17.3489 102.558 17.9915C103.132 18.6355 103.904 18.9561 104.874 18.9561C105.878 18.9561 106.684 18.6215 107.292 17.9509ZM106.245 13.9329C106.506 14.3683 106.638 14.8905 106.638 15.4995C106.638 16.1085 106.504 16.6349 106.231 17.0787C105.914 17.6149 105.476 17.8823 104.916 17.8823C104.345 17.8823 103.902 17.6191 103.585 17.0927C103.323 16.6573 103.191 16.1351 103.191 15.5261C103.191 14.8989 103.321 14.3683 103.585 13.9329C103.891 13.4065 104.339 13.1433 104.93 13.1433C105.498 13.1433 105.938 13.4065 106.245 13.9329Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.945 18.8063H115.262C115.199 18.4171 115.167 17.8921 115.167 17.2327V14.8023C115.167 12.9823 114.302 12.0723 112.577 12.0723C111.684 12.0723 110.926 12.2627 110.313 12.6421L110.611 13.6053C111.107 13.2805 111.681 13.1167 112.332 13.1167C113.246 13.1167 113.702 13.5507 113.702 14.4201V14.5433C112.48 14.5335 111.531 14.7421 110.856 15.1677C110.18 15.5933 109.844 16.2079 109.844 17.0143C109.837 17.2613 109.879 17.5072 109.968 17.7378C110.057 17.9685 110.19 18.1793 110.361 18.3583C110.74 18.7559 111.244 18.9561 111.867 18.9561C112.701 18.9561 113.344 18.6523 113.795 18.0461H113.835L113.945 18.8063ZM113.739 15.5205V16.5383C113.741 16.7416 113.694 16.9425 113.601 17.1236C113.509 17.3046 113.373 17.4602 113.207 17.5771C112.932 17.7801 112.631 17.8823 112.309 17.8823C112.046 17.8915 111.79 17.7993 111.593 17.6247C111.404 17.4525 111.311 17.1949 111.311 16.8505C111.311 15.9363 112.12 15.4925 113.739 15.5205Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.297 18.8063H123.599C123.564 18.2631 123.547 17.6709 123.547 17.0283V9.17847H122.079V12.9669H122.051C121.698 12.3691 121.078 12.0709 120.191 12.0709C119.385 12.0709 118.707 12.3789 118.154 12.9949C117.583 13.6557 117.299 14.5209 117.299 15.5891C117.299 16.5845 117.561 17.3951 118.086 18.0195C118.611 18.6439 119.273 18.9561 120.068 18.9561C121.064 18.9561 121.772 18.5529 122.188 17.7465H122.228L122.297 18.8063ZM122.079 14.9227V16.0231C122.079 16.4935 121.94 16.8967 121.657 17.2313C121.365 17.5939 120.98 17.7745 120.498 17.7745C119.962 17.7745 119.533 17.5575 119.216 17.1235C118.915 16.7161 118.765 16.1869 118.765 15.5345C118.765 14.8555 118.923 14.2997 119.235 13.8643C119.55 13.4289 119.979 13.2119 120.526 13.2119C120.887 13.2048 121.237 13.3332 121.507 13.5717C121.699 13.7386 121.85 13.9471 121.949 14.1812C122.048 14.4153 122.092 14.6689 122.079 14.9227Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.278 17.9509C135.857 17.2985 136.147 16.4669 136.147 15.4519C136.147 14.4663 135.866 13.6599 135.306 13.0355C134.718 12.3929 133.938 12.0723 132.971 12.0723C131.964 12.0723 131.166 12.3985 130.572 13.0495C129.979 13.7005 129.683 14.5391 129.683 15.5611C129.683 16.5383 129.97 17.3489 130.544 17.9915C131.121 18.6355 131.893 18.9561 132.86 18.9561C133.867 18.9561 134.67 18.6215 135.278 17.9509ZM134.233 13.9329C134.495 14.3683 134.627 14.8905 134.627 15.4995C134.627 16.1085 134.49 16.6349 134.219 17.0787C133.903 17.6149 133.462 17.8823 132.902 17.8823C132.331 17.8823 131.888 17.6191 131.571 17.0927C131.309 16.6573 131.177 16.1351 131.177 15.5261C131.177 14.8989 131.307 14.3683 131.571 13.9329C131.88 13.4065 132.328 13.1433 132.916 13.1433C133.487 13.1433 133.924 13.4065 134.233 13.9329Z"
      fill="white"
    />
    <path
      d="M142.57 18.8063H144.036V14.8527C144.036 13.8839 143.784 13.1545 143.276 12.6659C142.869 12.2669 142.356 12.0681 141.742 12.0681C141.253 12.0681 140.809 12.1927 140.41 12.4391C140.092 12.6338 139.83 12.9084 139.65 13.2357H139.609L139.541 12.2039H138.253C138.288 12.8843 138.305 13.5129 138.305 14.0925V18.8049H139.773V14.8905C139.773 14.4579 139.909 14.0799 140.181 13.7593C140.452 13.4387 140.804 13.2791 141.239 13.2791C142.128 13.2791 142.57 13.8615 142.57 15.0263V18.8063Z"
      fill="white"
    />
    <path
      d="M152.295 13.3057H153.909V12.2053H152.293V10.5631L150.856 10.9971V12.2067H149.891V13.3057H150.856V16.6335C150.856 17.4665 151.018 18.0601 151.346 18.4129C151.672 18.7657 152.123 18.9421 152.702 18.9421C153.171 18.9421 153.552 18.8875 153.842 18.7783L153.804 17.6653C153.632 17.7115 153.413 17.7339 153.15 17.7339C152.582 17.7339 152.295 17.3265 152.295 16.5117V13.3057Z"
      fill="white"
    />
    <path
      d="M160.209 18.8063H161.678V14.8821C161.678 13.9049 161.428 13.1657 160.931 12.6687C160.532 12.2697 160.03 12.0709 159.422 12.0709C158.564 12.0709 157.902 12.4321 157.44 13.1559H157.412V9.17847H155.946V18.8049H157.412V14.8359C157.409 14.6575 157.432 14.4798 157.48 14.3081C157.735 13.6221 158.2 13.2791 158.88 13.2791C159.767 13.2791 160.209 13.8713 160.209 15.0543V18.8063Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.635 15.8593C169.67 15.6787 169.69 15.4575 169.69 15.1943C169.69 14.3711 169.495 13.6781 169.102 13.1181C168.615 12.4125 167.894 12.0597 166.944 12.0597C165.974 12.0597 165.206 12.4125 164.637 13.1181C164.093 13.7789 163.822 14.6077 163.822 15.6031C163.822 16.6167 164.112 17.4245 164.697 18.0265C165.28 18.6285 166.085 18.9295 167.107 18.9295C167.959 18.9295 168.696 18.7937 169.322 18.5221L169.092 17.5029C168.557 17.7115 167.964 17.8151 167.313 17.8151C166.725 17.8151 166.244 17.6611 165.872 17.3531C165.465 17.0087 165.255 16.5117 165.235 15.8593H169.635ZM168.006 13.6879C168.211 14.0265 168.31 14.4192 168.29 14.8149H165.235C165.271 14.4013 165.422 14.006 165.67 13.6739C165.801 13.486 165.976 13.3332 166.179 13.2289C166.383 13.1247 166.609 13.0722 166.838 13.0761C167.363 13.0761 167.751 13.2805 168.006 13.6879Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.9236 44.1056H75.103L69.076 25.4744H65.3716L59.374 44.1056H62.4694L64.1284 38.633H70.182L71.9236 44.1056ZM68.082 31.472L69.657 36.337H64.6548L66.2018 31.472C66.5123 30.306 66.8073 29.136 67.0866 27.9622H67.1426C67.6032 29.8046 67.9154 30.975 68.082 31.472Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.6746 42.6398C89.9094 41.314 90.5268 39.508 90.5268 37.2232C90.5268 35.1778 89.9976 33.5286 88.9364 32.2742C87.878 31.0212 86.555 30.3954 84.9702 30.3954C82.943 30.3954 81.4324 31.1962 80.437 32.8006H80.381L80.2144 30.6712H77.5894C77.6622 32.1832 77.7 33.6574 77.7 35.0938V49.5796H80.6848V42.5026H80.7408C81.515 43.7738 82.7862 44.4094 84.5558 44.4094C86.1952 44.4094 87.5686 43.82 88.6746 42.6398ZM86.6012 34.0984C87.192 34.9468 87.486 36.0248 87.486 37.3324C87.486 38.752 87.1724 39.8944 86.5452 40.761C85.9194 41.6262 85.0444 42.0602 83.9202 42.0602C82.9626 42.0602 82.1842 41.7242 81.585 41.0508C80.9858 40.3774 80.6862 39.5528 80.6862 38.5756V36.3356C80.6862 36.0976 80.7324 35.7952 80.8248 35.4256C81.0082 34.6332 81.4002 33.9934 81.9994 33.5034C82.5972 33.0176 83.2664 32.7712 84.0028 32.7712C85.0892 32.7712 85.9558 33.2136 86.6012 34.0984Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.125 42.6398C105.361 41.314 105.979 39.508 105.979 37.2232C105.979 35.1778 105.448 33.5286 104.391 32.2742C103.33 31.0212 102.007 30.3954 100.423 30.3954C98.3948 30.3954 96.8842 31.1962 95.8902 32.8006H95.8342L95.6676 30.6712H93.0426C93.1154 32.1832 93.1532 33.6574 93.1532 35.0938V49.5796H96.138V42.5026H96.194C96.9668 43.7738 98.238 44.4094 100.008 44.4094C101.647 44.4094 103.02 43.82 104.125 42.6398ZM102.052 34.0984C102.642 34.9468 102.938 36.0248 102.938 37.3324C102.938 38.752 102.624 39.8944 101.997 40.761C101.371 41.6262 100.496 42.0602 99.372 42.0602C98.4116 42.0602 97.6332 41.7228 97.034 41.0508C96.4376 40.3774 96.138 39.5528 96.138 38.5756V36.3356C96.138 36.0976 96.1856 35.7952 96.2766 35.4256C96.46 34.6332 96.8506 33.9934 97.4498 33.5034C98.0106 33.0303 98.7208 32.771 99.4546 32.7712C100.542 32.7712 101.406 33.2136 102.052 34.0984Z"
      fill="white"
    />
    <path
      d="M121.6 42.7504C122.704 41.755 123.255 40.4656 123.255 38.8808C123.255 37.5928 122.878 36.5134 122.123 35.651C121.366 34.7858 120.189 34.0312 118.586 33.3858C117.275 32.8692 116.372 32.3904 115.875 31.948C115.304 31.4692 115.02 30.8602 115.02 30.1238C115.015 29.8055 115.08 29.4899 115.209 29.1988C115.337 28.9076 115.528 28.6477 115.766 28.4368C116.354 27.9216 117.174 27.6626 118.224 27.6626C119.553 27.6626 120.74 27.9482 121.79 28.5194L122.536 26.0862C121.412 25.4968 120.004 25.2014 118.308 25.2014C116.407 25.2014 114.887 25.69 113.747 26.6686C112.603 27.6458 112.032 28.9016 112.032 30.4332C112.032 32.7768 113.662 34.559 116.924 35.7756C118.122 36.2194 118.98 36.6996 119.494 37.2162C120.011 37.7328 120.268 38.3698 120.268 39.1258C120.268 39.9742 119.956 40.6546 119.33 41.1614C118.702 41.6682 117.827 41.923 116.703 41.923C115.136 41.923 113.698 41.5254 112.388 40.733L111.696 43.2208C112.913 43.995 114.52 44.3814 116.525 44.3814C118.696 44.3814 120.386 43.8382 121.6 42.7504Z"
      fill="white"
    />
    <path
      d="M129.833 32.9112H133.123V30.6684H129.833V27.3238L126.902 28.2086V30.6684H124.942V32.9084H126.902V39.6802C126.902 41.3756 127.235 42.5824 127.897 43.302C128.562 44.0202 129.483 44.38 130.662 44.38C131.621 44.38 132.395 44.2694 132.983 44.0496L132.901 41.783C132.551 41.8754 132.108 41.9216 131.575 41.9216C130.413 41.9216 129.833 41.0928 129.833 39.4338V32.9112Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.198 42.364C147.377 41.0382 147.967 39.3428 147.967 37.2778C147.967 35.2688 147.396 33.6294 146.251 32.3596C145.054 31.0506 143.468 30.3968 141.497 30.3968C139.451 30.3968 137.824 31.0604 136.619 32.3876C135.411 33.7134 134.809 35.4186 134.809 37.5004C134.809 39.4898 135.394 41.1404 136.564 42.448C137.735 43.7556 139.304 44.4094 141.275 44.4094C143.321 44.4094 144.962 43.729 146.198 42.364ZM144.07 34.1866C144.606 35.0728 144.871 36.1354 144.871 37.3744C144.871 38.612 144.593 39.6858 144.043 40.5888C143.398 41.678 142.503 42.224 141.364 42.224C140.2 42.224 139.299 41.6892 138.653 40.6168C138.117 39.732 137.852 38.6694 137.852 37.4304C137.852 36.1536 138.117 35.0742 138.653 34.1866C139.279 33.1156 140.19 32.5794 141.39 32.5794C142.549 32.5794 143.444 33.1142 144.07 34.1866Z"
      fill="white"
    />
    <path
      d="M156.729 33.2136C157.044 33.2124 157.359 33.2401 157.669 33.2962V30.45C157.448 30.415 157.199 30.3954 156.923 30.3954C156.145 30.3965 155.389 30.6489 154.766 31.115C154.085 31.6484 153.588 32.3596 153.273 33.243H153.191L153.082 30.6726H150.482C150.556 31.9438 150.594 33.3536 150.594 34.902L150.566 44.1056H153.549V37.0566C153.549 35.9884 153.798 35.1036 154.295 34.4036C154.867 33.6098 155.679 33.2136 156.729 33.2136Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.909 38.1066C170.989 37.6599 171.026 37.2065 171.018 36.7528C171.018 35.077 170.622 33.6658 169.834 32.5248C168.839 31.0884 167.373 30.3702 165.437 30.3702C163.465 30.3702 161.899 31.0884 160.74 32.5248C159.632 33.8702 159.079 35.5572 159.079 37.583C159.079 39.6466 159.674 41.2916 160.861 42.5166C162.051 43.7416 163.687 44.3548 165.77 44.3548C167.502 44.3548 169.002 44.079 170.274 43.5246L169.806 41.4526C168.718 41.8768 167.509 42.0882 166.183 42.0882C164.984 42.0882 164.009 41.7746 163.254 41.1488C162.422 40.4488 161.99 39.4338 161.955 38.1066H170.909ZM167.593 33.684C167.997 34.3294 168.192 35.0938 168.172 35.9786L161.955 35.9772C162.046 35.0756 162.341 34.3014 162.838 33.656C163.444 32.844 164.238 32.4394 165.214 32.4394C166.282 32.4394 167.076 32.8538 167.593 33.684Z"
      fill="white"
    />
  </svg>
);

export default AppStore;

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { getDocument, getWindow } from '@stitch-fix/mode-react';
import { G } from '@mobily/ts-belt';
// https://github.com/streamich/react-use/issues/2074#issuecomment-982044510
import { useScrollbarWidth } from 'react-use/lib/useScrollbarWidth';
import { createPortal } from 'react-dom';
import { useHeaderShellContext } from '../../../helpers/HeaderShellContext';
import styles from './style.module.scss';

export const SEARCH_PORTAL_ID = 'search-dropdown-overlay-portal' as const;

let originalBodyOverflow: string;
let originalBodyPaddingRight: string;

// Custom hook to disable scrolling when component mounts
// Source: https://usehooks.com/useLockBodyScroll
const useLockBodyScroll = () => {
  const scrollBarWidth = useScrollbarWidth();
  const headerShellElement = useHeaderShellContext();

  useLayoutEffect(() => {
    const doc = getDocument();
    const win = getWindow();

    if (G.isObject(doc) && G.isObject(win) && G.isObject(headerShellElement)) {
      originalBodyOverflow = win.getComputedStyle(doc.body).overflow;
      originalBodyPaddingRight = win.getComputedStyle(doc.body).paddingRight;
      const newBodyPaddingRight = `${
        parseInt(originalBodyPaddingRight, 10) + (scrollBarWidth || 0)
      }px`;

      doc.body.style.overflow = 'hidden';
      // When there's an overlay add padding right to the body content
      doc.body.style.paddingRight = newBodyPaddingRight;

      if (G.isObject(headerShellElement.style)) {
        // When there's an overlay add padding right to the nav bar
        headerShellElement.style.paddingRight = `${scrollBarWidth}px`;
      }
    }

    return () => {
      if (G.isObject(doc) && G.isObject(headerShellElement)) {
        // Resetting the padding right when there is no overlay
        doc.body.style.overflow = originalBodyOverflow;
        doc.body.style.paddingRight = originalBodyPaddingRight;

        if (G.isObject(headerShellElement.style)) {
          headerShellElement.style.paddingRight = '';
        }
      }
    };
  }, [headerShellElement, scrollBarWidth]);
};

/**
 * When rendered, `DropdownOverlay` displays a background overlay and disables body scrolling on the page.
 *
 * Renders the overlay in a portal, 'search-dropdown-overlay-portal',
 * which can be found in the `LoggedInHeader` component.
 */
const DropdownOverlay = () => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null);

  useLockBodyScroll();

  useEffect(() => {
    const target = getDocument()?.getElementById(SEARCH_PORTAL_ID);

    if (target) {
      setPortalTarget(target);
    }
  }, []);

  return (
    portalTarget &&
    createPortal(
      <div className={styles['search-dropdown-overlay']} />,
      portalTarget,
    )
  );
};

export default DropdownOverlay;

import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconLikeHeartInactive24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={26} height={24} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M0.966196 9.61303C0.979123 5.75928 4.16603 2.76829 7.99362 2.76829C9.97497 2.76829 11.7752 3.55846 13.0398 4.90031C14.3121 3.66672 15.9937 2.76829 18.0065 2.76829C21.7333 2.76829 24.8916 5.77691 25.0331 9.59998C25.1694 12.0906 23.9126 14.2773 22.0139 15.6267L14.4076 21.1347C14.3849 21.1512 14.362 21.1679 14.3388 21.1847C14.0752 21.3769 13.7844 21.5889 13.4466 21.6754C13.1536 21.7504 12.8464 21.7504 12.5535 21.6754C12.2156 21.5889 11.9248 21.3769 11.6612 21.1847C11.638 21.1679 11.6151 21.1512 11.5924 21.1347L3.98615 15.6267C2.08573 14.276 0.835803 11.9724 0.966196 9.61303ZM7.99362 4.36829C4.99097 4.36829 2.56616 6.7052 2.56616 9.63657V9.66022L2.56476 9.68383C2.46075 11.4413 3.40515 13.252 4.91558 14.3244L4.9217 14.3287L4.92168 14.3287L12.5308 19.8388C12.7083 19.9673 12.809 20.0397 12.8875 20.0887C12.9271 20.1134 12.9475 20.1233 12.9547 20.1264C12.9845 20.1334 13.0155 20.1334 13.0453 20.1264C13.0525 20.1233 13.073 20.1134 13.1125 20.0887C13.191 20.0397 13.2917 19.9673 13.4692 19.8388L21.0783 14.3287L21.0844 14.3243L21.0844 14.3244C22.6009 13.2477 23.5399 11.5551 23.4352 9.68122L23.4347 9.67326L23.4345 9.6653C23.3277 6.69562 20.8709 4.36829 18.0065 4.36829C16.2335 4.36829 14.7306 5.29802 13.613 6.63092L12.9412 7.43222L12.3436 6.57418C11.3982 5.21692 9.81483 4.36829 7.99362 4.36829ZM12.9577 20.1277C12.9578 20.1277 12.9573 20.1276 12.9564 20.1272Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconLikeHeartInactive24(props, ref);
});
export default ForwardRef;
import { useCallback, useEffect, useRef, useState } from 'react';
import { G } from '@mobily/ts-belt';
import { getDocument } from '../../../../helpers/getBrowserGlobals';

export const useDropdownOpen = (isPrimaryNavVisible: boolean) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownControlRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  /**
   * close dropdown only if click is outside of dropdown
   */
  const handleClick = (e: MouseEvent) => {
    if (
      e.target instanceof Node &&
      !(
        G.isObject(dropdownControlRef.current) &&
        dropdownControlRef.current.contains(e.target)
      ) &&
      !(
        G.isObject(dropdownMenuRef.current) &&
        dropdownMenuRef.current.contains(e.target)
      )
    ) {
      setIsOpen(false);
    }
  };

  // Move focus to the account menu when it opens so that tabbing works correctly
  useEffect(() => {
    if (isOpen && G.isObject(dropdownMenuRef.current)) {
      dropdownMenuRef.current.focus();
    }
  }, [isOpen]);

  // Track click events so we can close dropdown when client clicks outside of it
  useEffect(() => {
    const doc = getDocument();

    doc?.addEventListener('mousedown', handleClick);

    return () => {
      doc?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const toggleIsOpen = useCallback((open?: boolean) => {
    setIsOpen(prevIsOpen => (open !== undefined ? open : !prevIsOpen));
  }, []);

  // Close dropdown when the signout modal is opened
  const closeDropdown = () => setIsOpen(false);

  // Close menu when the primary nav slides away.
  useEffect(() => {
    if (!isPrimaryNavVisible) {
      setIsOpen(false);
    }
  }, [isPrimaryNavVisible]);

  return {
    isOpen,
    dropdownControlRef,
    dropdownMenuRef,
    toggleIsOpen,
    closeDropdown,
  };
};

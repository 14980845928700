import React from 'react';
import { Box, IconArrowRight32 } from '@stitch-fix/mode-react';
import {
  isOnGiftsPath,
  isOnKidsPath,
  isOnMensPath,
  isOnWomensPath,
} from '../../../../helpers/pathHelper';
import { featureIsAvailable, getRegion } from '../../../../i18n';
import { ModeLink } from '../../../KnitLink';
import useCurrentPath from '../../../../helpers/useCurrentPath';
import { regionalUrls } from '../../../../i18n/regionalUrls';
import useCurrentHost from '../../../../helpers/useCurrentHost';

import styles from './style.module.scss';

const MobileDropDownMenu = () => {
  const currentPath = useCurrentPath();

  const region = getRegion();
  const currentHost = useCurrentHost();
  const isHelpShown = currentHost !== 'support.stitchfix.co.uk';

  const helpLink = isHelpShown ? (
    <ModeLink data-ga="nav-primary-faq" to={regionalUrls[region].help}>
      Help
    </ModeLink>
  ) : null;

  const giftCardsLink = featureIsAvailable('gift_cards') ? (
    <ModeLink
      data-ga="nav-primary-gifts"
      to="/gifts"
      underline={isOnGiftsPath(currentPath) ? 'initial' : 'inverse'}
    >
      Gift cards
    </ModeLink>
  ) : null;

  const businessLineLinks = [
    featureIsAvailable('women') ? (
      <div className={styles['business-line-link']} key="women">
        <ModeLink
          data-ga="nav-primary-businessline-women"
          to="/women"
          underline={isOnWomensPath(currentPath) ? 'initial' : 'inverse'}
        >
          Women
        </ModeLink>
      </div>
    ) : null,
    featureIsAvailable('men') ? (
      <div className={styles['business-line-link']} key="men">
        <ModeLink
          data-ga="nav-primary-businessline-men"
          to="/men"
          underline={isOnMensPath(currentPath) ? 'initial' : 'inverse'}
        >
          Men
        </ModeLink>
      </div>
    ) : null,
    featureIsAvailable('kids') ? (
      <div className={styles['business-line-link']} key="kids">
        <ModeLink
          data-ga="nav-primary-businessline-kids"
          to="/kids"
          underline={isOnKidsPath(currentPath) ? 'initial' : 'inverse'}
        >
          Kids
        </ModeLink>
      </div>
    ) : null,
  ];

  const signupLink = (
    <div className={styles['signup-link']}>
      <ModeLink
        data-ga="nav-signup"
        to="/signup"
        endIcon={<IconArrowRight32 />}
      >
        Take your style quiz
      </ModeLink>
    </div>
  );

  return (
    <Box className={styles.container}>
      {businessLineLinks}
      {helpLink}
      {giftCardsLink}
      {signupLink}
    </Box>
  );
};

export default MobileDropDownMenu;

import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import {
  Box,
  Button,
  CloudinaryImage,
  Modal,
  Text,
  useScreenSize,
} from '@stitch-fix/mode-react';
import styles from './style.module.scss';
import type { Client } from '../../../../../NavContextWrapper/types';

const cx = classNames.bind(styles);

interface SignOutModalProps {
  client: Client;
  isOpen: boolean;
  onClose: () => void;
  onConfirmLogout: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type ModalImageVariantKey = 'men' | 'women' | 'kids';

type ModalImage = {
  mediaPath: string;
  alt: string;
};

type ModalImageVariants = {
  [key in ModalImageVariantKey]: ModalImage;
};

const WOMENS_IMAGE = {
  mediaPath: 'v1701378813/onboarding/womens-onboarding-modal.jpg',
  alt: 'A woman wearing Stitch Fix clothing, including a yellow sweater, olive pants, and a striped coat.',
};

const MENS_IMAGE = {
  mediaPath: 'v1701380172/onboarding/mens-onboarding-modal.jpg',
  alt: 'Men’s fall clothing from Stitch Fix, including pants, outerwear, shirts, sweaters, and shoes.',
};

const KIDS_IMAGE = {
  mediaPath: 'v1701379993/onboarding/kids-onboarding-modal.jpg',
  alt: 'Two children smiling at the camera and wearing Stitch Fix clothing in shades of blue, khaki, and peach.',
};

const modalImages: ModalImageVariants = {
  men: MENS_IMAGE,
  women: WOMENS_IMAGE,
  kids: KIDS_IMAGE,
};

const SignOutModal = ({
  client,
  isOpen,
  onClose,
  onConfirmLogout,
}: SignOutModalProps) => {
  const clientBusinessLine = client ? client.businessLine : 'WOMENS';
  const isMobileScreenSize = useScreenSize() === 'sm';

  const modalVariant = useMemo(() => {
    switch (clientBusinessLine) {
      case 'WOMENS':
        return 'women';
      case 'MENS':
        return 'men';
      case 'KIDS':
      case 'SHELL':
        return 'kids';
      default:
        return 'women';
    }
  }, [clientBusinessLine]);

  const bodyText =
    clientBusinessLine === 'KIDS' || clientBusinessLine === 'SHELL'
      ? 'You’re so close to super-easy shopping for the whole family! Just a few more questions, and we’ll take care of the rest.'
      : 'You’re so close to getting personalized style straight to your door! Just a few more questions, and we’ll start styling.';

  const image = modalImages[modalVariant];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hero={
        <div className={cx('image')}>
          <CloudinaryImage
            baseUrl="https://d3n78nkjl8tizo.cloudfront.net/stitch-fix/image/upload/"
            mediaPath={image.mediaPath}
            sources={{
              sm: {
                w: 560,
                h: 560,
              },
            }}
            objectFit="cover"
            alt={image.alt}
          />
        </div>
      }
      variant="slide-up-small"
      hAlign="center"
      bodyLock="never"
      data-testid="sign-out-modal"
    >
      <Box>
        <Text color="blue-45" setting="display-medium" mb={0.5} mt={0.75}>
          Keep it going
        </Text>
        <Text setting="body-large">{bodyText}</Text>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box
            style={{
              width: isMobileScreenSize ? '100%' : '18.5rem',
              maxWidth: '100%',
            }}
          >
            <Button
              variant="filled-statement"
              mt={2}
              data-testid="modal-cta"
              onClick={onClose}
              width="fill"
            >
              Continue quiz
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
          mb={{ sm: 0.25, md: 0.75 }}
        >
          <Box
            style={{
              width: isMobileScreenSize ? '100%' : '18.5rem',
              maxWidth: '100%',
            }}
          >
            <Button
              variant="text"
              mt={1}
              data-testid="signout-modal-link"
              onClick={onConfirmLogout}
              width="fill"
            >
              Save progress and exit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SignOutModal;

import React from 'react';
import classNames from 'classnames/bind';
import { Box, Text } from '@stitch-fix/mode-react';
import styles from './style.module.scss';

interface AccountBadgeProps {
  circleColor: number;
  text: string;
}

const cx = classNames.bind(styles);

const AccountBadge = ({ circleColor, text }: AccountBadgeProps) => {
  return (
    <Box className={cx('account-badge', `circle-color-${circleColor}`)}>
      <Text family="medium" scale="3">
        {text}
      </Text>
    </Box>
  );
};

export default AccountBadge;

import { type MutableRefObject, useCallback, useRef } from 'react';
import { observe } from 'react-intersection-observer';

type Unobservers = MutableRefObject<(() => void)[]>;
type Callback = () => void;
type IntersectionObserverOpts = Parameters<typeof observe>[2];

const DEFAULT_THRESHOLD = 0.6;

const intersectionObserversOpts: IntersectionObserverOpts[] = [
  // The default trigger for when an element is 60% visible
  { threshold: DEFAULT_THRESHOLD },
  // Trigger to handle especially tall elements. The -30% margins ensure that a view is not
  // triggered until the element is scrolled up/down least 30% of the viewport.
  { threshold: 0, rootMargin: '-30% 0%' },
];

/**
 * Fire a callback a single time when an object comes into view. Adapted from
 * https://github.com/thebuilder/react-intersection-observer/blob/master/src/useInView.tsx
 *
 * An element is considered "in view" if 60% of the element is visible, OR if a tall element
 * (taller than half the viewport height) intersects the center 40% of the viewport.
 */
const useInView = (callback: Callback) => {
  const unobservers: Unobservers = useRef([]);

  const unobserveAll = useCallback(() => {
    for (const unobserve of unobservers.current) {
      unobserve();
    }
    unobservers.current = [];
  }, []);

  const setRef = useCallback(
    (node: Element | null) => {
      // Detach any existing intersection observers
      unobserveAll();

      // This guards against the race condition where both observers are
      // triggered before one observer has a chance detach the other, and
      // ensures we only call the `callback()` once
      let callbackCalled = false;

      if (node) {
        // Attach new intersection observers, one for each configuration defined above
        for (const options of intersectionObserversOpts) {
          const unobserve = observe(
            node,
            // eslint-disable-next-line no-loop-func
            (
              inView,
              {
                intersectionRatio,
                intersectionRect,
                boundingClientRect,
                rootBounds,
              },
            ) => {
              // Only continue if the element is in-view and we haven't already called our callback
              if (!inView || callbackCalled) {
                return;
              }

              const elementIsMostlyInView =
                intersectionRatio >= DEFAULT_THRESHOLD;

              const elementIsVeryTall =
                rootBounds &&
                boundingClientRect.height >= 0.5 * rootBounds.height;

              // Tall elements that are not at least 60% in view horizontally (such as when just a
              // sliver of an element is visible within a carousel), do not trigger the special tall
              // element rule. These must trigger the default 60% intersection rule.
              const elementIsHorizontallyInView =
                intersectionRect.width / boundingClientRect.width >
                DEFAULT_THRESHOLD;

              if (
                elementIsMostlyInView ||
                (elementIsVeryTall && elementIsHorizontallyInView)
              ) {
                unobserveAll();
                callback();
                callbackCalled = true;
              }
            },
            options,
          );

          unobservers.current.push(unobserve);
        }
      }
    },
    [callback, unobserveAll],
  );

  return { ref: setRef };
};

export default useInView;
